import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getAttendanceSummaryReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
import useInViewport from '../useInViewport';
const moment = require('moment');

const AttendanceSummaryReport = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);

  useEffect(() => {
    let payload = {
      "start_date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": endDate ? endDate.format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
    }
    if (viewport && data.length === 0) {
      getData(payload);
    }
  }
    , [startDate, endDate, viewport]);

const getData=(payload)=>{
  makeRequest(setLoader, getAttendanceSummaryReq, payload, onSuccess, onError)
}
  const onSuccess = (data) => {
    setData(data.attendance_report);
  }

  const onError = (error) => {
  }

  const onChangeStartDate=(date)=>{
    setStartDate(date);
    let payload = {
      "start_date": date ? date.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": endDate ? endDate.format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
    }
    getData(payload);
   
  }
  const onChangeEndDate=(date)=>{
    setEndDate(date);
    let payload = {
      "start_date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": date ? date.format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
    }
    getData(payload);
   
  }
  const chartOptions = {
    // Define your chart options here
    series: [{
      name: 'Percent',
      data: data.values
    }],
    labels: data.labels,
    colors: ['#EB7900'],
    options: {
      chart: {
        height: 400,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },

    },
  };

  return (
    <div className='widgetbox' ref={ viewRef }>
      <Row gutter={ 10 }>
        <Col span={ 12 }>
          <h3>Attendance Summary</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
            <Row gutter={ 10 }>
              <Col span={ 12 }>
                <InputDate onChange={ onChangeStartDate } defaultValue={ dayjs(startDate) } placeholder="Start Date" />
              </Col>
              <Col span={ 12 }>
                <InputDate onChange={ onChangeEndDate } defaultValue={ dayjs(endDate) } placeholder="End Date" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='widgetcontent'>
        <Spin spinning={ loader } tip="Loading...">
          <Row gutter={ 0 }>
            <Col span={ 3 }>
              <div className='attendancSbox'>
                <h4>Absents</h4>
                <h3>{ data.absents }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: '#4079FC'
              } }>
                <h4>Presents</h4>
                <h3>{ data.presents }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: '#EB7900'
              } }>
                <h4>Missing Punches</h4>
                <h3>{ data.missings }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: 'rgba(64, 121, 252, 0.10)'
              } }>
                <h4 style={ {
                  color: '#4079FC'
                } }>Late Arrivals</h4>
                <h3 style={ {
                  color: '#1E2022'
                } }>{ data.missings }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: 'rgba(64, 121, 252, 0.10)'
              } }>
                <h4 style={ {
                  color: '#4079FC'
                } }>Short Duration</h4>
                <h3 style={ {
                  color: '#1E2022'
                } }>{ data.short_durations }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: 'rgba(64, 121, 252, 0.10)'
              } }>
                <h4 style={ {
                  color: '#4079FC'
                } }>Early Left</h4>
                <h3 style={ {
                  color: '#1E2022'
                } }>{ data.early_lefts }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: 'rgba(64, 121, 252, 0.10)'
              } }>
                <h4 style={ {
                  color: '#4079FC'
                } }>Relaxations</h4>
                <h3 style={ {
                  color: '#1E2022'
                } }>{ data.relaxations }</h3>
              </div>
            </Col>
            <Col span={ 3 }>
              <div className='attendancSbox' style={ {
                background: 'rgba(64, 121, 252, 0.10)'
              } }>
                <h4 style={ {
                  color: '#4079FC'
                } }>Leaves</h4>
                <h3 style={ {
                  color: '#1E2022'
                } }>{ data.leaves }</h3>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
}

export default AttendanceSummaryReport;