import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputTextArea, InputCheck, CancelButton, ModalComponent, trans,SelectResourceList,InputDate,InputText } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { convertTimeTotz } from '@comps/commonFunctions';


const langKey = Const["payitem"].lngKey
const formName = "createPayitem";

const UpdateCustomPayitem = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [form] = Form.useForm()
  const [processed, setProcessed] = useState(false)
 

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData=()=>{
    props.record.date=props.record.date?convertTimeTotz(props.record.date):null;
    form.setFieldsValue({...props.record,employee_name:props.record.employee_row?props.record.employee_row.full_name+'-'+props.record.employee_row.emp_code:''});
  }
  const onSubmit = (data) => {
    const payload = {
      custom_payitem: {
        ...props.record,
        processed: processed,
        date: data.date.format('YYYY-MM-DD'),
        description:data.description,
        amount:data.amount
      }
      ,
      id:props.record.id,
      payitemId:props.record.payitem_id
    };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    
setsaving(false)
    message.success(res.message);
    props.onCompleted(data.custom_payitems.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [

    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={props.disabled}>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
          <Form.Item name="employee_name" rules={ rules.employment_id } label={ trans(`${langKey}.employee`) }
                className="da-mb-16"
              >
                <InputText disabled/>

              </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"
            >
              <InputDate/>
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="amount" rules={ rules.amount } label={ trans(`${langKey}.amount`) }
              className="da-mb-16"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={trans(`${langKey}.amount`)}/>
            </Form.Item>
          </Col>


             <Col lg={ 12 } sm={ 24 } xs={ 24 }>
               <Form.Item name="processed" rules={ rules.processed } label={ trans(`${langKey}.processed`) }
                className="da-mb-16"
                valuePropName="checked"
              >
                <InputCheck onChange={ (e) => setProcessed(e.target.checked) } />
               </Form.Item>
             </Col>
         
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
              
            >
              <InputTextArea />
            </Form.Item>
          </Col>


        </Row>
      </Form>
    </ModalComponent>
  )
}

export default UpdateCustomPayitem

const rules = {
  employment_id: [
    { required: true, message: 'Employee' },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}