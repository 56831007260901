import { makeRequestStateless } from "@utils/helpers";
import { Button, Col, Form, Row, Drawer, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { createSelectList, encryptLocalData, decryptLocalData } from '@comps/commonFunctions';
import { SelectWithSearch, InputText, InputDate, SelectResourceList } from '@comps/components';
import { SyncOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import BaseSelectTrashedResource from "../layout/BaseSelectTrashedResource";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(utc);

const BaseFilter = (props) => {
    const { filters, onFilter, api, moduleName, identity,modkey } = props;
    const [loading, setLoading] = useState(true)
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [selectedString, setSelectedString] = useState(props.selected)
    const [form] = Form.useForm()
    var selected = {}
    useEffect(() => {
        getInitialData();
        setData()
        // eslint-disable-next-line
    }, []);

    //api for getting options data
    const getInitialData = async () => {
        await makeRequestStateless(api, moduleName, onSuccess, null);
    
    }

    const onSuccess = (data, res) => {
        setOptions(data)
        setLoading(false)
    }
    //set pre data
    const setData = () => {
        if (selectedString && selectedString !== undefined && selectedString !== '' && selectedString.eventPhase === undefined && typeof dateString !== 'object') {
            const keyValuePairs = selectedString.split(';');
            const resultObject = {};
            for (const pair of keyValuePairs) {
                if (pair !== "" || pair !== '') {
                    const keyValuObj = convertToKeyValuePair(pair.replace('_|', ':'))
                    // Get keys
                    var key = Object.keys(keyValuObj)[0];
                    // Get values
                    var value = Object.values(keyValuObj)[0];

                    if (/^\d+$/.test(value)) {
                        value = Number(value);
                    }
                    if (value === 'true') {
                        value = 'true'
                    }
                    if (value === 'false') {
                        value = 'false'
                    }
                    if (value === 'undefined' || value === 'null' || !value) {
                        value = ''
                    }
                    if (key === 'from' || key === 'to' || key === 'month' || key === 'year' || key === 'completion_to' || key === 'completion_from') {
                        value = value ? dayjs(value) : ''
                    }

                    resultObject[key] = value;
                }
            }

            setSelectedValues(resultObject)
            form.setFieldsValue(resultObject)
        }
    }

    function convertToArray(string) {
        // Use the split method to convert the string into an array
        if (string === '') {
            return []
        }
        const resultArray = string.split(',');
        // Display the result
        return resultArray
    }
    function convertToKeyValuePair(str) {
        var parts = str.split(':');
        var key = parts[0].trim();
        var value = parts[1].trim();
        var result = {};
        result[key] = value;
        return result;
    }

    //is object
    function isDateObj(dateString) {
        return typeof dateString === 'object' && dateString !== null && !
            Array.isArray(dateString) && !(dateString instanceof Date) && !(dateString instanceof RegExp) && !(dateString instanceof Error);
    }

    const onChange = (key, value, each) => {
        
        form.setFieldValue(key, value)
        const formFieldValues=form.getFieldsValue();
        if (key === 'month' && value) {
            value = value.date(1);
            
                value = value.hour(0)
                .minute(0)
                .second(0)
                .millisecond(0).format('YYYY-MM-DD');
          
           // value=value.utc();
        }
        if (each && value && (each.type === "text" || each.type === "number")) {
            selected = { ...selected, [key]: value.target.value };
        } else {
            selected = { ...selected,...formFieldValues, [key]: value }
        }
    }
    //apply filter
    const onSubmit = (data) => {
        
        setLoader(true)
        var payload = ''
        Object.keys(selected).forEach(key => {
            if (key === 'from' || key === 'to' || key === 'year' || key === 'completion_to' || key === 'completion_from') {
                selected[key] = isDateObj(selected[key]) ? selected[key].format('YYYY-MM-DD') : selected[key];
            }
            if (key === 'month' && isDateObj(selected[key])) {
                selected[key] = selected[key].date(1);
                    selected[key] = selected[key].hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0).format('YYYY-MM-DD');
            }
            // if(selected[key]===undefined){
            //     selected[key]=''
            // }
            if(selected[key]!==undefined){
 payload += `${key}_|${selected[key]};`
            }
           
        });
        if (Object.keys(selected).length < 1) {
            payload = selectedString
        }
        if(payload){
            payload = payload+';';
        }
        onFilter(payload);
        if (typeof props.setCurrentPage === 'function') {
            props.setCurrentPage(1);
          } 
       
        props.onCloseFilter(false)
    }

    const onReset = () => {
        setSelectedValues({})
        selected = {};
        onFilter('');
        if (typeof props.setCurrentPage === 'function') {
            props.setCurrentPage(1);
          } 
        setSelectedString('')
    }
    //validation
    function isMultiple(each) {
        if(each.multiple){
            return true;
        }
        if(each.multiple===false){
            return false;
        }
        // Trim any leading or trailing white spaces
        each.key = each.key.trim();
        // Check if the last character is an "s"
        if(each.key.endsWith("us")){
return false;
        }
        return each.key.endsWith('s');
    }

    const getPlaceholder = (each) => {
        const ph = each.placeholder;
        if (typeof ph === 'object' && ph.props !== undefined && ph.props.id !== undefined) {

            const lastString = ph.props.id.substring(ph.props.id.lastIndexOf('.') + 1);
            // Use a regular expression to insert space before capital letters
            const stringWithSpaces = lastString.replace(/([a-z])([A-Z])/g, '$1 $2');

            // Capitalize the first letter of the resulting string
            const capitilizeStr = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
            const res = capitilizeStr.replace('_id', ' ').replace('_', ' ')
            return res;
        }
        else {
            return ph
        }
    }
    //fields
    const InputField = ({ each }) => (
        <InputText placeholder={ each.placeholder } onChange={ val => onChange(each.key, val, each) } defaultValue={ selectedValues[each.key] === 'Invalid Date' ? '' : selectedValues[each.key] } />
    )
    const DateTimeField = ({ each }) => (
        <InputDate picker={ each.key === 'month' ? 'month' : 'date' } format={ each.key === 'month' ? "YYYY MMM" : 'YYYY-MM-DD' } onChange={ val => onChange(each.key, val,each) } placeholder={ getPlaceholder(each) } defaultValue={ selectedValues[each.key] } />
    )

    const SelectField = ({ each }) => (
        <>
            {
                each.data_key.split(".").reduce((obj, k) => obj && obj.hasOwnProperty(k) ? obj[k] : null, options) ?
                    <>
                        {
                        each.key === 'employees' && each.onlyTrashed  ? <BaseSelectTrashedResource modkey={modkey} each={each} disabled={ each.disabled } mode={ isMultiple(each) ? 'multiple' : '' } type={ each.resource_type } onChange={ val => onChange(each.key, val,each) } defaultValue={ selectedValues[each.key]  ? convertToArray(selectedValues[each.key]) : [] } />:
                        each.key === 'employments' || each.key === 'employees' || each.key === 'employee'  ? <SelectResourceList modkey={modkey} disabled={ each.disabled } mode={ isMultiple(each) ? 'multiple' : '' } type={ each.resource_type } onChange={ val => onChange(each.key, val,each) } defaultValue={ selectedValues[each.key] !== undefined ? convertToArray(selectedValues[each.key]) : [] } /> :
                            <SelectWithSearch modkey={modkey} mode={ isMultiple(each) ? 'multiple' : '' } placeholder={ getPlaceholder(each) } key={ each.key } defaultValue={ selectedValues[each.key] && !each.filterKey ? selectedValues[each.key] : each.filterKey ? selectedValues[each.key] : null } options={
                                createSelectList(each.data_key.split(".").reduce((obj, k) => obj && obj.hasOwnProperty(k) ? obj[k] : null, options), { key: each.key, filterKey: each.filterKey }) } onChange={ val => onChange(each.key, val,each) } loading={ false } />
                        }

                    </>
                    : null }
        </>
    ) 

    const FilterField = (each, i) => {
        let item = null;
        switch (each.type) {
            case 'text':
                item = <InputField className="da-w-100" each={ each } />;
                break;
            case 'date':
                item = <DateTimeField className="da-w-100" each={ each } />;
                break;
            case 'select':
                item = <SelectField className="da-w-100" each={ each } />;
                break;
            default:
                return null;
        }

        return (
            <>
                <Col key={ i } className="gutter-row" sm={ 24 } xs={ 24 } md={ 24 }>
                    <Form.Item label={ each.placeholder } name={ each.key } rules={ [
                        { required: each.required, message: 'This Field is Required' },
                    ] } >{ item }</Form.Item>
                </Col>
            </>
        );
    }

    return (
        <>
            <Drawer
                title="Filters"
                placement={ 'right' }
                width={ 410 }
                onClose={ () => props.onCloseFilter(false) }
                maskClosable={ true }
                open={ true }
                footer={ [
                    <Row justify={ 'center' } >
                        <Col className="gutter-row filterBtn da-mb-16" span={ 2.5 } offset={ 8 }>
                            <Space>
                                <Button
                                    style={ {
                                        float: "right",
                                        margin: "5px",
                                        width: 175,
                                        height: 43,
                                        borderRadius: 3,
                                        background: "#FFFFFF",
                                        marginTop: 20,
                                        marginLeft: -100,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        border: "1px solid #EA4335"
                                    } }
                                    onClick={ onReset } type="primary" danger>
                                    <span style={ { color: "#EA4335" } }><SyncOutlined /> Reset</span>
                                </Button>
                                <Button
                                    style={ {
                                        float: "right",
                                        margin: "5px",
                                        width: 175,
                                        height: 43,
                                        borderRadius: 3,
                                        background: "#4079FC",
                                        marginTop: 20,
                                        fontSize: 16,
                                        fontWeight: 600,
                                    } }
                                    type="primary" form="filterForm" htmlType="submit" loading={ loader } >
                                    <span><SearchOutlined /> Search</span>
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                ] }
            >
                <Row style={ { overflowX: 'auto' } }>
                    <Col sm={ 24 } xs={ 24 } md={ 24 }>
                        <Spin spinning={ loading } tip="Loading...">
                            <Form { ...formLayout } form={ form } name='filterForm' onFinish={ onSubmit }>
                                { filters.map((value, index) => FilterField(value, index)) }
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};

export default BaseFilter;
//---------------------form layout------------
//------------------------------------
const rules = {
    from: [
        { required: true, message: '' },
    ],
    to: [
        { required: true, message: '' },
    ],
};
const formLayout = {
    layout: "vertical",
    labelCol: { span: 6 },
    wrapperCol: { span: 24, },
    labelAlign: "left"
}
