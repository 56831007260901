import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, Row, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputTime } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const langKey = Const["timeslot"].lngKey
const formName = "createTimeslot";

const CreateTimeSlot = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isFlexible, SetIsFlexible] = useState(false);
  const [fontcolorHex, setFontColorHex] = useState('#1B24C2');
  const [bgcolorHex, setBgColorHex] = useState('#5BC62A');
  const [formatHex, setFormatHex] = useState('hex');
  const [deletedRows, setDeletedRows] = useState([])
  const [breaks, setBreaks] = useState([])
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    data.font_colour = fontColorHexString;
    data.bg_colour = bgColorHexString;


    if (isFlexible) {
      data.flexible_minutes = data.flexible_minutes ? Math.floor(data.flexible_minutes * 60) : null;
    }
    const payload = { time_slot: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCreated(data.time_slots.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //---------color to hex string--------------
  const fontColorHexString = useMemo(
    () => (
      typeof fontcolorHex === 'string' ? fontcolorHex : fontcolorHex.toHexString()),
    [fontcolorHex],

  );
  const bgColorHexString = useMemo(
    () => (
      typeof bgcolorHex === 'string' ? bgcolorHex : bgcolorHex.toHexString()),
    [bgcolorHex],

  );
  // ------------------------------------
  //set deleted breaks
  const setRows = (key, state) => {
    if (breaks[key] !== undefined && breaks[key].id !== undefined && state === 'Deleted') {
      breaks[key].row_state = 'Deleted'
      deletedRows.push(breaks[key])
    }
    setDeletedRows(deletedRows)
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------


  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
              className="da-mb-16"
              { ...getErrorProps(errors['start']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.start`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
              className="da-mb-16"
              { ...getErrorProps(errors['end']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.end`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_night" rules={ rules.is_night } label={ trans(`${langKey}.is_night`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_night']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_flexible" rules={ rules.is_flexible } label={ trans(`${langKey}.is_flexible`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_flexible']) } size="small"
              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => SetIsFlexible(e.target.checked) } />
            </Form.Item>
          </Col>

          {
            !isFlexible ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="start_buffer_minutes" rules={ rules.start_buffer_minutes } label={ trans(`${langKey}.start_buffer_minutes`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['start_buffer_minutes']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.start_buffer_minutes`) } type="number" onWheel={(e) => e.target.blur()} />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="end_buffer_minutes" rules={ rules.end_buffer_minutes } label={ trans(`${langKey}.end_buffer_minutes`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['end_buffer_minutes']) } size="small"

                  >
                    <InputText placeholder={ trans(`${langKey}.end_buffer_minutes`) } type="number" onWheel={(e) => e.target.blur()} />
                  </Form.Item>
                </Col>
              </>
              : '' }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="punch_start_buffer_minutes" rules={ rules.punch_start_buffer_minutes } label={ trans(`${langKey}.punch_start_buffer_minutes`) }
              className="da-mb-16"
              { ...getErrorProps(errors['punch_start_buffer_minutes']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.punch_start_buffer_minutes`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="punch_end_buffer_minutes" rules={ rules.punch_end_buffer_minutes } label={ trans(`${langKey}.punch_end_buffer_minutes`) }
              className="da-mb-16"
              { ...getErrorProps(errors['punch_end_buffer_minutes']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.punch_end_buffer_minutes`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_full_overtime_slot" rules={ rules.is_full_overtime_slot } label={ trans(`${langKey}.is_full_overtime_slot`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_full_overtime_slot']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_full_overtime_slot" rules={ rules.is_full_overtime_slot } label={ trans(`${langKey}.is_full_overtime_slot`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_full_overtime_slot']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_overtime_applicable" rules={ rules.is_overtime_applicable } label={ trans(`${langKey}.is_overtime_applicable`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_overtime_applicable']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>

          {
            isFlexible ?

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="flexible_minutes" rules={ rules.flexible_minutes } label={ trans(`${langKey}.flexible_minutes`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['flexible_minutes']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.flexible_minutes`) } type="number" onWheel={(e) => e.target.blur()} />
                </Form.Item>
              </Col>
              : ''
          }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="font_colour" rules={ rules.font_colour } label={ trans(`${langKey}.font_colour`) }
              className="da-mb-16"
              { ...getErrorProps(errors['font_colour']) } size="small"
            >
              <ColorPicker
                format={ formatHex }
                value={ fontcolorHex }
                onChange={ setFontColorHex }
                onFormatChange={ setFormatHex } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="bg_colour" rules={ rules.bg_colour } label={ trans(`${langKey}.bg_colour`) }
              className="da-mb-16"
              { ...getErrorProps(errors['bg_colour']) } size="small"
            >
              <ColorPicker
                format={ formatHex }
                value={ bgcolorHex }
                onChange={ setBgColorHex }
                onFormatChange={ setFormatHex }
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="formlist-container">
          <h5 className="formlist-title">Breaks</h5>

          <Form.List name="breaks" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()
                  //setRows(fields.length, 'Added')
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.name`) }</th>
                          <th>{ trans(`${langKey}.start`) }</th>
                          <th>{ trans(`${langKey}.end`) }</th>
                          <th>{ trans(`${langKey}.is_inclusive`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'name'] }
                                rules={ rules.name }
                              >
                                <InputText placeholder={ trans(`${langKey}.name`) } />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'start'] }
                                rules={ rules.start }
                              >
                                <InputTime placeholder={ trans(`${langKey}.break.start`) } />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'end'] }
                                rules={ rules.end }
                              >
                                <InputTime placeholder={ trans(`${langKey}.break.end`) } />
                              </Form.Item>
                            </td>
                            <td style={ { paddingTop: 10 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'is_inclusive'] }
                                rules={ []}
                                label={ 'Inclusive' }
                                valuePropName="checked"
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>
      </Form>
    </ModalComponent>
  )
}

export default CreateTimeSlot

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start.req`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end.req`) },
  ],
  start_buffer_minutes: [
    { required: true, message: trans(`${langKey}.start_buffer_minutes.req`) },
  ],
  end_buffer_minutes: [
    { required: true, message: trans(`${langKey}.end_buffer_minutes.req`) },
  ],
  punch_end_buffer_minutes: [
    { required: true, message: trans(`${langKey}.punch_end_buffer_minutes.req`) },
  ],
  punch_start_buffer_minutes: [
    { required: true, message: trans(`${langKey}.punch_start_buffer_minutes.req`) },
  ],
  flexible_hours: [
    { required: true, message: trans(`${langKey}.flexible_hours.req`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}