import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests"
const langKey = Const["taxAdjustments"].lngKey
const formName = "CreateTaxAdjustment";
const CreateTaxAdjustment = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [type, setType] = useState(false)
  const [form] = Form.useForm()
  const [payitems, setPayitems] = useState([])
  useEffect(() => {
    setRelatedData()
    getEmployments()
    // eslint-disable-next-line
  }, []);

  const getEmployments = () => {
    const payload = {
      filters: null
    }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
  }
  const setRelatedData = () => {
    setPayitems(createSelectList(props.allData.pay_items))
  }

  const onSubmit = (data) => {
    const payload = {
      tax_adjustment: {
        employment_id: data.employment_id,
        month: data.month.format('YYYY-MM-DD'),
        amount: data.amount,
        payitem_id: data.payitem_id
      }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.tax_adjustments.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment'
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="month" rules={ rules.month } label={ trans(`${langKey}.month`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month']) } size="small"
              >
                <InputDate picker="month" format="YYYY-MM" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['payitem_id']) } size="small"
              >
                <SelectWithSearch
                  options={ payitems }
                  placeholder={ trans(`${langKey}.payitem_id`) }
                  onChange={ (value) => setType(value) }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="amount" rules={ rules.amount } label={ trans(`${langKey}.amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['amount']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateTaxAdjustment

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  month: [
    { required: true, message: trans(`${langKey}.month`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

