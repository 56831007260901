import React, { useEffect, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, ButtonComponent, InputCheck, SelectMultiWithSearch ,SelectWithSearch} from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { getCompanyRelatedDataReq, updateGradeReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["grade"].lngKey
const formName = "createGrade";

const UpdateGrade = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  const [hasLevels, setHasLevels] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [deletedLevels, setDeletedLevels] = useState([]);
  const [deletedBenefits, setDeletedBenefits] = useState([]);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()

  useEffect(() => {
    getCompanyRelated()
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {

    setHasLevels(props.record.has_levels)
    form.setFieldsValue(
      {
        name: props.record.name,
        has_levels: props.record.has_levels,
        grade_benefits: props.record.grade_benefits.data,
        grade_levels: props.record.grade_levels.data
      }
    )
  }
  const getCompanyRelated = () => {
    makeRequestStateless(getCompanyRelatedDataReq, null, onSuccessRelated, onSuccessRelated);
  }
  const onSuccessRelated = (data) => {
    setloading(false)
    if (data.job_titles !== undefined) {
      setJobTitles(createSelectList(data.job_titles))
      setDesignations(createSelectList(data.designations))
      const newBenefits = []
      Object.keys(data.benefits).forEach(key => {
        const each = data.benefits[key]

        newBenefits.push({
          label: each.name,
          value: each.id
        })
      })

      setBenefits(newBenefits)
    }

  }
  const onSubmit = (data) => {

    //setting data
    const updatedBenefits = []
    const updatedLevels = []
    if (data.grade_benefits !== undefined && data.grade_benefits.length > 0) {
      data.grade_benefits.forEach(each => {
        if (each.id === undefined) {
          each.row_state = 'Added'
        }
        if (each.id !== undefined) {
          each.row_state = 'Updated'
        }
        updatedBenefits.push(each)
      })
    }

    if (data.grade_levels !== undefined && data.grade_levels.length > 0) {
      data.grade_levels.forEach(each => {
        if (each.id === undefined) {
          each.row_state = 'Added'
        }
        if (each.id !== undefined) {
          each.row_state = 'Updated'
        }
        updatedLevels.push(each)
      })
    }

    if (!hasLevels && props.record.grade_levels.data.length > 0) {
      props.record.grade_levels.data.forEach(each => {
        each.row_state = 'Deleted'
        updatedLevels.push(each)
      })
    }

    data.grade_benefits = deletedBenefits.concat(updatedBenefits)
    data.grade_levels = deletedLevels.concat(updatedLevels)
    //


    const payload = { id: props.record.id, name: data.name, has_levels: hasLevels, grade_benefits: data.grade_benefits, grade_levels: data.grade_levels }
    makeRequest(setLoader, updateGradeReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Grade Saved successfully");
    props.onCompleted(data.grades.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  //set deleted rows

  //set deleted breaks
  const onDeleteBenefits = (key, state) => {
    if (props.record.grade_benefits.data[key] !== undefined && props.record.grade_benefits.data[key].id !== undefined && state === 'Deleted') {
      props.record.grade_benefits.data[key].row_state = 'Deleted'
      deletedBenefits.push(props.record.grade_benefits.data[key])
    }
  }
  const onDeleteGradeLevels = (key, state) => {
    if (props.record.grade_levels.data[key] !== undefined && props.record.grade_levels.data[key].id !== undefined && state === 'Deleted') {
      props.record.grade_levels.data[key].row_state = 'Deleted'
      deletedLevels.push(props.record.grade_levels[key])
    }
  }
  //
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton disabled={ loader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
          <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputText placeholder={ trans(`${langKey}.name`) } />
          </Form.Item>

          <Form.Item name="has_levels" label={ trans(`${langKey}.levels`) } className="da-mb-16" valuePropName="checked">
            <InputCheck onChange={ (e) => setHasLevels(e.target.checked) } />
          </Form.Item>

          {
            hasLevels &&
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">Levels</h5>
                <Form.List name="grade_levels" >
                  { (fields, { add, remove }) => (
                    <>
                      <ButtonComponent onClick={ () => {
                        add()
                        //setRows(fields.length, 'Added')
                      } } icon={ <PlusOutlined /> } type="primary" className="add-node-button" text='Add' />
                      <div className="table-container">
                        <table className="table">
                          {
                            fields.length !== 0 && <thead>
                              <tr>
                                <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.name`) }</th>
                                <th>{ trans(`${langKey}.job_titles`) }</th>
                                <th>{ trans(`${langKey}.designations`) }</th>
                                <th>{ trans(`${langKey}.min_salary`) }</th>
                                <th>{ trans(`${langKey}.max_salary`) }</th>
                                <th>{ trans(Commons.actions) }</th>
                              </tr>
                            </thead>
                          }
                          <tbody>
                            { fields.map(({ key, name, ...restField }) => (
                              <>
                                <tr>
                                  <td style={ { paddingLeft: 20 } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'name'] }
                                      rules={ rules.name }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.name`) } />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'job_titles'] }
                                      rules={ rules.job_titles }
                                    >
                                      <SelectMultiWithSearch
                                        options={ jobTitles }
                                        placeholder={ trans(`${langKey}.job_titles`) } />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'designations'] }
                                      rules={ rules.designations }
                                    >
                                      <SelectMultiWithSearch
                                        options={ designations }
                                        placeholder={ trans(`${langKey}.designations`) } />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'min_salary'] }
                                      rules={ rules.min_salary }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.min_salary`) } type="number" onWheel={(e) => e.target.blur()} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'max_salary'] }
                                      rules={ rules.max_salary }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.max_salary`) } type="number" onWheel={(e) => e.target.blur()} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <DeleteOutlined className="formlist-remove" onClick={ () => {
                                      remove(name)
                                      onDeleteGradeLevels(key, 'Deleted')
                                    } } />
                                  </td>
                                </tr>
                              </>
                            )) }
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) }
                </Form.List>
              </div>
            </>
          }
          <div className="formlist-container">
            <h5 className="formlist-title">Benefits</h5>
            <Form.List name="grade_benefits" >
              { (fields, { add, remove }) => (
                <>
                  <ButtonComponent onClick={ () => {
                    add()
                    //setRows(fields.length, 'Added')
                  } } icon={ <PlusOutlined /> } type="primary" className="add-node-button" text='Add' />
                  <div className="table-container">
                    <table className="table">
                      {
                        fields.length !== 0 && <thead>
                          <tr>
                            <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.benefit_id`) }</th>
                            <th>{ trans(Commons.actions) }</th>
                          </tr>
                        </thead>
                      }
                      <tbody>
                        { fields.map(({ key, name, ...restField }) => (
                          <>
                            <tr>
                              <td style={ { paddingLeft: 20 } }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'benefit_id'] }
                                  rules={ rules.name }
                                >
                                  <SelectWithSearch
                                    options={ benefits }
                                    placeholder={ trans(`${langKey}.benefit_id`) } />
                                </Form.Item>
                              </td>


                              <td>
                                <DeleteOutlined className="formlist-remove" onClick={ () => {
                                  remove(name)
                                  onDeleteBenefits(key, 'Deleted')
                                } } />
                              </td>
                            </tr>
                          </>
                        )) }
                      </tbody>
                    </table>
                  </div>
                </>
              ) }
            </Form.List>
          </div>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateGrade

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  levels: [
    { required: true, message: trans(`${langKey}.levels.required`) },
  ],
  job_titles: [
    { required: true, message: trans(`${langKey}.job_titles`) },
  ],
  designations: [
    { required: true, message: trans(`${langKey}.designations`) },
  ],
  min_salary: [
    { required: true, message: trans(`${langKey}.min_salary`) },
  ],
  max_salary: [
    { required: true, message: trans(`${langKey}.max_salary`) },
  ]
};

const formLayout = {
  layout: "horizontal",
  labelCol: { span: 6 },
  // wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}