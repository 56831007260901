import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmployeeRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import { InputCheck } from '../../../../wrappers/components';
const langKey = Const["salaryRevisions"].lngKey
const formName = "createAttendanceRequest";
const CreateSaleSalaryRevisions = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [employments, setEmployments] = useState([])
  const [employeeInfo, setEmoloyeeInfo] = useState({});
  const [type, setType] = useState(false)
  const [form] = Form.useForm()
  const [split,setSplit]=useState(false);
  useEffect(() => {
    getEmployments()
    setRelatedData()
    // eslint-disable-next-line
  }, []);

  const setRelatedData = () => {

  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }
  const onChangeEmployments = (id) => {
    if (id) {

      makeRequest(setLoader, getEmployeeRelatedDataReq, id, onGetEmployeeData, onGetEmployeeDataError);
    }
  }
  const onGetEmployeeData = (response) => {
    setEmoloyeeInfo(response)
    form.setFieldsValue(
      {
        last_salary: response.gross_salary
      }
    )
  }
  const onGetEmployeeDataError = (response, data) => {
    message.error(data.response.data.message)
  }
  const onChangePercentage = (percentage) => {
    const old = form.getFieldValue('last_salary')
    const result = old + (percentage / 100) * old
    form.setFieldsValue(
      {
        new_salary: result
      }
    )
  }
  const onSubmit = (data) => {
    let splitSum=Number(data.bank_salary)+Number(data.cash_salary);
    if(splitSum && !employeeInfo.bank){
      message.error('Employee has no bank');
      return;
    }
    if(split && splitSum!=data.new_salary){
      message.error('Bank Salary and Cash Salary must be equal to New Salary');
      return;
    }

    const payload = {
      salary_revision: {
        ...data,
        behalf_employment_id: props.authUser.employment_id,
        is_on_behalf: true,
        date: data.date.format('YYYY-MM-DD'),
        type: type,
        split_salary:{
          bank_salary:Number(data.bank_salary),
          cash_salary:Number(data.cash_salary),
          employment_salary_id:employeeInfo.employment_salary_id,
          employment_id:employeeInfo.employment_id
        }
      }
    }
    
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.salary_revisions.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment'
                  onChange={ onChangeEmployments }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="last_salary" rules={ rules.last_salary } label={ trans(`${langKey}.last_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_salary']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.last_salary`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
                className="da-mb-16"
                { ...getErrorProps(errors['type']) } size="small"
                initialValue={false}
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Percentage",
                      value: true
                    },
                    {
                      label: "Flat",
                      value: false
                    }
                  ] }
                  placeholder={ trans(`${langKey}.type`) }
                  onChange={ (value) => setType(value) }
                />
              </Form.Item>
            </Col>
            { type ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="percentage_salary" rules={ rules.percentage_salary } label={ trans(`${langKey}.percentage_salary`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['percentage_salary']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.percentage_salary`) } type="number" onWheel={(e) => e.target.blur()} onChange={ (e) => onChangePercentage(e.target.value) } />
                </Form.Item>
              </Col>
              : '' }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="new_salary" rules={ rules.new_salary } label={ trans(`${langKey}.new_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['new_salary']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.new_salary`) } type="number" onWheel={(e) => e.target.blur()} disabled={ type } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="split" rules={ rules.split } label={ trans(`${langKey}.split`) }
                className="da-mb-16"
                { ...getErrorProps(errors['split']) } size="small"
                valuePropName="checked"
              >
                <InputCheck onChange={(e)=>setSplit(e.target.checked)} />
              </Form.Item>
            </Col>

            {
            split && <>
             <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="cash_salary" rules={ rules.cash_salary } label={ trans(`${langKey}.cash_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['cash_salary']) } size="small"
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.cash_salary`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_salary" rules={ rules.bank_salary } label={ trans(`${langKey}.bank_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_salary']) } size="small"
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.bank_salary`) } />
              </Form.Item>
            </Col>
            </>
           }
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateSaleSalaryRevisions

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  last_salary: [
    { required: true, message: trans(`${langKey}.last_salary`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  new_salary: [
    { required: true, message: trans(`${langKey}.new_salary`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
  percentage_salary: [
    { required: true, message: trans(`${langKey}.percentage_salary`) },
  ],
  cash_salary: [
    { required: true, message: trans(`${langKey}.cash_salary`) },
  ],
  bank_salary: [
    { required: true, message: trans(`${langKey}.bank_salary`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

