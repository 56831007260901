import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import dayjs from 'dayjs'
const langKey = Const["salaryRevisions"].lngKey
const formName = "updateSalaryRevision";
const UpdateSalaryRevision = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [type, setType] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    setRelatedData()
    // eslint-disable-next-line
  }, []);

  const setRelatedData = () => {

    form.setFieldsValue({
      ...props.record,
      employment_id: props.record.employment?props.record.employment.full_name_code:'',
      date: dayjs(props.record.date)
    })
    setType(props.record.type)
  }

  const onChangePercentage = (percentage) => {
    const old = form.getFieldValue('last_salary')
    const result = old + (percentage / 100) * old
setType(props.record.type)
    form.setFieldsValue(
      {
        new_salary: result,
      }
    )
  }
  const onSubmit = (data) => {
    const payload = {
      id: props.record.id,
      sale_salaryRevision: {
        id: props.record.id,
        branch_id: data.branch_id,
        payitem_id: data.payitem_id,
        month: data.month.format('YYYY-MM-DD'),
        total_amount: data.total_amount,
        is_flat: data.is_flat,
        applicable_amount: data.applicable_amount
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.sale_salaryRevisions.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment' value={ props.record.employment_id } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="last_salary" rules={ rules.last_salary } label={ trans(`${langKey}.last_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_salary']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.last_salary`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
                className="da-mb-16"
                { ...getErrorProps(errors['type']) } size="small"

              >
                <SelectWithSearch

                  options={ [
                    {
                      label: "Percentage",
                      value: true
                    },
                    {
                      label: "Flat",
                      value: false
                    }
                  ] }
                  placeholder={ trans(`${langKey}.type`) }
                  onChange={ (value) => setType(value) }
                />
              </Form.Item>
            </Col>
            { type ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="percentage_salary" rules={ rules.percentage_salary } label={ trans(`${langKey}.percentage_salary`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['percentage_salary']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.percentage_salary`) } type="number" onWheel={(e) => e.target.blur()} onChange={ (e) => onChangePercentage(e.target.value) } />
                </Form.Item>
              </Col>
              : '' }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="new_salary" rules={ rules.new_salary } label={ trans(`${langKey}.new_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['new_salary']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.new_salary`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateSalaryRevision

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  last_salary: [
    { required: true, message: trans(`${langKey}.last_salary`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  new_salary: [
    { required: true, message: trans(`${langKey}.new_salary`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
  percentage_salary: [
    { required: true, message: trans(`${langKey}.percentage_salary`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

