
import React, { useEffect, useState } from "react";
import { DatePicker, Divider, Form, Input, message, Modal, Skeleton, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, UploadButton, InputDate ,SelectResourceList,InputText} from "@comps/components"
import { makeRequestStateless, makeRequest, getErrorProps } from "@utils/helpers";
import { getAllEmploymentsReq, deallocateAssetReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';

import dayjs from "dayjs";
const { TextArea } = Input;
const langKey = Const["asset"].lngKey
const formName = "updateAsset";

const UpdateAsset = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assets, setAssets] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {
    getAndSetRecord();
    makeRequestStateless(getAllEmploymentsReq, false, ongetAllEmployees, false)
    //makeRequestStateless(getAssetTypesReq, false, ongetAssetTypes, false)
    // eslint-disable-next-line
  }, []);
  const getAndSetRecord = () => {
    if (!props.record) {
      return;
    }

    setFileList(props.record.attachment ? [props.record.attachment] : [])
    //onChangeAssetType(props.record.asset_type_name)
    form.setFieldsValue({
      employment_id: `${props.record.full_name}- (${props.record.emp_code})`,
      asset_id: props.record.asset_name,
      asset_type_id: props.record.asset_type_name,
      issue_date: props.record.issue_date ? dayjs(props.record.issue_date) : null,
      description: props.record.description,
      attachment: props.record.attachment ? [props.record.attachment] : [],
      handed_over_to: props.record.handed_over_to,
      deallocation_date: props.record.deallocation_date ? dayjs(props.record.deallocation_date) : null,
      handed_over_to_description: props.record.handed_over_to_description,
    })
  }
  const ongetAllEmployees = (response) => {
    setEmployees(createSelectList(response.resources.data))
    setloading(false)
  }

  const onSubmit = (data) => {
    const obj = props.record;
    obj.handed_over_to = data.handed_over_to;
    obj.handed_over_to_description = data.handed_over_to_description;
    obj.deallocation_date = data.deallocation_date.format('YYYY-MM-DD');
    const payload = { employment_asset: obj, filters: props.filters }
    payload.id = props.record.id;
    makeRequest(setLoader, deallocateAssetReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Asset Saved successfully");
    props.onCompleted(data.employment_assets.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.asset`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ true } scrollToFirstError>
          <Row gutter={ 20 }>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" label={ trans(`${langKey}.employment_id`) } className="da-mb-16"
                initialValue={`${props.record.full_name}- (${props.record.emp_code})`}
              >
                <InputText disabled={ true }/>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="asset_type_id" rules={ rules.asset_type_id } label={ trans(`${langKey}.asset_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['asset_type_id']) } size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.asset_type_id`) }
                  options={ assetTypes }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="asset_id" rules={ rules.asset_id } label={ trans(`${langKey}.asset_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['asset_id']) } size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.asset_id`) }
                  options={ assets }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="issue_date" rules={ rules.issue_date } label={ trans(`${langKey}.issue_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['issue_date']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg,.pdf" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    //const reader = new FileReader();
                    // reader.onload = e => {
                    //   console.log(e.target.result);
                    // };
                    // reader.readAsText(file);
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Divider>Deallocation</Divider>
              <Form.Item name="deallocation_date" rules={ rules.deallocation_date } label={ trans(`${langKey}.deallocation_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['deallocation_date']) } size="small"
              >
                <DatePicker format='YYYY-MM-DD' disabled={ false } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="handed_over_to" label={ trans(`${langKey}.handed_over_to`) } rules={ rules.handed_over_to } className="da-mb-16"
                { ...getErrorProps(errors['employment_id']) }
              >
               <SelectResourceList type='employment' disabled={ props.filters !== undefined } value={ props.record.handed_over_to }/>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="handed_over_to_description" label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <TextArea placeholder="Description" disabled={ false } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>


      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>
  )
}

export default UpdateAsset

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id.req`) },
  ],
  asset_type_id: [
    { required: true, message: trans(`${langKey}.asset_type_id.req`) },
  ],
  asset_id: [
    { required: false, message: trans(`${langKey}.asset_id.req`) },
  ],
  issue_date: [
    { required: true, message: trans(`${langKey}.issue_date.req`) },
  ],
  deallocation_description: [
    { required: true, message: trans(`${langKey}.deallocation_description.req`) },
  ],
  deallocation_date: [
    { required: true, message: trans(`${langKey}.deallocation_date.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}


