import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Skeleton, message, Row, Col, Popconfirm } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, TableComponent, ActionComponent, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../constants";
import moment from "moment/moment";
import { approveLeaveRequestReq, getLeaveRequestReq } from "../leave/approvals/requests";
import RoasterView from "@mods/common/RoasterView";
import CreateConsent from "./CreateConsent";
import CreateConsentChat from "./CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
const langKey = Const["leaveApproval"].lngKey
const formName = "updateLeaveApproval";

const UpdateLeaveApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [preRequestData, setPreData] = useState({})
  const [roasterView, setRosterView] = useState(null)
  const [preRoster, setPreRoster] = useState({})
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('')
  const [form] = Form.useForm();
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [childComponent, setChildComponent] = useState(null)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getLeaveRequest()
  }, []);

  const getLeaveRequest = () => {
    setConsents(props.record.consents)
    let payload = props.record.id
    if (props.record.hasOwnProperty('requestable')) {

      payload = props.record.requestable_id
    }

    makeRequest(setLoader, getLeaveRequestReq, payload, onGetRequestDataSuccess, onGetRequestDataSuccess);
  }
  const onGetRequestDataSuccess = (data, response) => {
    setLoading(false)
    setPreData(response)
    response.leave_request.start = moment(response.leave_request.start)
    response.leave_request.end = moment(response.leave_request.end)
    response.leave_request.created_at = moment(new Date(response.leave_request.created_at)).format('YYYY-MM-DD')
    form.setFieldsValue(response.leave_request)
    form.setFieldsValue({ leave_type_available: response.leave_type_available, leave_type_total: response.leave_type_total, leave_type_availed: response.leave_type_availed, last_remarks: props.record.last_remarks })
    showRoster(response.roster)
    setPreRoster(response.roster)
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="leaveConsent/create" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.leave_requests.data);
    }

    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="leaveConsent/message" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    setSaveLoader(true)
    //data.id = props.record.id;
    const last_remarks = form.getFieldValue('last_remarks');
    let shiftIds = getShiftIds(preRoster);
    preRequestData.leave_request.shiftIds = shiftIds
    preRequestData.leave_request.request_as = props.requestAsId
    preRequestData.delegator_id = null
    preRequestData.leave_request.is_approval = isApproval
    preRequestData.leave_request.last_remarks = last_remarks
    preRequestData.leave_request.remarks = last_remarks
    preRequestData.leave_request.leave_type_available = preRequestData.leave_type_available
    preRequestData.leave_request.leave_type_total = preRequestData.leave_type_total
    preRequestData.leave_request.leave_type_availed = preRequestData.leave_type_availed

    const payload = { request: { ...preRequestData.leave_request }, delegator_id: props.delegatorId, api: props.api, filters: props.filters }
    makeRequest(setLoader, approveLeaveRequestReq, payload, onSuccess, onError);

  }

  const onSuccess = (data, res) => {
    message.success("Record Updated");
    props.onCompleted(data.leave_requests.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const showRoster = (data) => {
    setRosterView(<RoasterView record={ data } />)
  }

  const getShiftIds = (data) => {
    let shiftIds = [];
    let date_total = [];
    let start = moment(new Date(data.from_date))
    let end = moment(new Date(data.to_date))
    if (start === end) {
      date_total.push(start)
      //setDateTotalSource(date_total)
    }
    else {
      while (start <= end) {
        date_total.push(start.format('YYYYMMDD'));
        start = start.add(1, 'days');
      }
    }
    date_total.map((date) => (
      data.employments.map((employee) => (
        data.shifts.hasOwnProperty(date + '-' + employee.id) ?
          shiftIds.push(data.shifts[date + '-' + employee.id][0]['id'])
          : ''
      ))
    ))

    return shiftIds;
  }
  //-----------------------

  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,

    <Popconfirm
      description="Are You Sure To Perform This Action?"
      okText="Yes"
      cancelText="No"
      onConfirm={ () => onSubmit(true) }
    >
      <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" text='Approve' />
    </Popconfirm>,

    <Popconfirm
      description="Are You Sure To Perform This Action?"
      okText="Yes"
      cancelText="No"
      onConfirm={ () => onSubmit(false) }
    >
      <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } text='Reject' />
    </Popconfirm>,

    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>
          <h5 className="formlist-title">Leave Request</h5>

          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_name" rules={ rules.leave_type_id } label={ trans(`${langKey}.leave_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.leave_type_id`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>
          </Row>

          { roasterView }


          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="count" rules={ rules.count } label={ trans(`${langKey}.count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.count`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="created_at" rules={ rules.created_at } label={ trans(`${langKey}.created_at`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.created_at`) } />
              </Form.Item>
            </Col>
          </Row>


          { props.record.hasOwnProperty('requestable') ?
            <>
              <Divider>Cancel Request</Divider>
              <Form.Item name="reasons" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
              <Divider>Approval</Divider>
              <Form.Item name="description" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_remarks']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
              </Form.Item>
            </>
            :
            <>

              <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
                <h5 className="formlist-title">Approval</h5>
                <Row gutter={ 30 }>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="leave_type_total" rules={ rules.leave_type_total } label={ trans(`${langKey}.leave_type_total`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['leave_type_total']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.leave_type_total`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                    </Form.Item>
                  </Col>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="leave_type_available" rules={ rules.leave_type_available } label={ trans(`${langKey}.leave_type_available`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['leave_type_available']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.leave_type_available`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                    </Form.Item>
                  </Col>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="leave_type_availed" rules={ rules.leave_type_availed } label={ trans(`${langKey}.leave_type_availed`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['leave_type_availed']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.leave_type_availed`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                    </Form.Item>
                  </Col>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['last_remarks']) } size="small"
                    >
                      <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
          }
        </Form>

        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>

        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />

      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateLeaveApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  // disabled:{props.disabled}
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}