import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputTime, InputDate, SelectWithSearch } from "@comps/components"
import { getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import "../../style.css"
import CreateTicketBookings from "./CreateTicketBookings";
import UpdateTicketBookings from "./UpdateTicketBookings";
const langKey = Const["companyTravel"].lngKey
const CreateDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [travelModes, setTravelModes] = useState([]);
  const [travelTypes, setTravelTypes] = useState([]);
  const [stayTypes, setStayTypes] = useState([]);
  const [isStay, setIsStay] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    setTravelModes(createSelectList(props.requiredData.travel_modes))
    setTravelTypes(createSelectList(props.requiredData.travel_types))
    setStayTypes(createSelectList(props.requiredData.stay_types))
  }
  //functions

  const onSubmit = (data) => {
    props.onCompleted(data, 'Added')
  }

  //actions

  //create details
  const onCreateTicket = () => {
    setChildComponent(<CreateTicketBookings onCompleted={ onCompleted } disabled={ false } dataSource={ dataSource } />)
  }
  const onEditTicket = (key) => {
    const record = form.getFieldValue(`ticket_bookings`)[key];
    setChildComponent(<UpdateTicketBookings onCompleted={ onCompleted } disabled={ false } dataSource={ dataSource } record={ { ...record, key: key } } />)
  }


  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('ticket_bookings');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('ticket_bookings', listArray)
    }
    if (data && action === 'Updated') {
      listArray[data.key] = data
      form.setFieldValue('ticket_bookings', listArray)
    }
    setChildComponent(null)
  }

  const footer = [
    props.disabled ? '' :
      <SaveButton disabled={ saveLoader } form={ 'detailsForm' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.details`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ 'detailsForm' } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="travel_type_id" rules={ rules.travel_type_id } label={ trans(`${langKey}.travel_type_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ travelTypes }
                placeholder={ trans(`${langKey}.travel_type_id`) }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="from" rules={ rules.from } label={ trans(`${langKey}.from`) }
              className="da-mb-16"
              { ...getErrorProps(errors['from']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.from`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="to" rules={ rules.to } label={ trans(`${langKey}.to`) }
              className="da-mb-16"
              { ...getErrorProps(errors['to']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.to`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="travel_mode_id" rules={ rules.travel_mode_id } label={ trans(`${langKey}.travel_mode_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ travelModes }
                placeholder={ trans(`${langKey}.travel_mode_id`) }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="distance" rules={ rules.distance } label={ trans(`${langKey}.distance`) }
              className="da-mb-16"
              { ...getErrorProps(errors['distance']) } size="small"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.distance`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="departure_date" rules={ rules.departure_date } label={ trans(`${langKey}.departure_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['departure_date']) } size="small"
            >
              <InputDate />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="departure_time" rules={ rules.departure_time } label={ trans(`${langKey}.departure_time`) }
              className="da-mb-16"
              { ...getErrorProps(errors['departure_time']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.departure_time`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="arrival_date" rules={ rules.arrival_date } label={ trans(`${langKey}.arrival_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['arrival_date']) } size="small"
            >
              <InputDate />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="arrival_time" rules={ rules.arrival_time } label={ trans(`${langKey}.arrival_time`) }
              className="da-mb-16"
              { ...getErrorProps(errors['arrival_time']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.arrival_time`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_stay_required" rules={ rules.is_stay_required } label={ trans(`${langKey}.is_stay_required`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_stay_required']) } size="small"
              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => setIsStay(e.target.checked) } />
            </Form.Item>
          </Col>
        </Row>

        { isStay ?
          <>

            <div className="formlist-container">
              <h5 className="formlist-title">{ trans(`${langKey}.hotel_booking`) }</h5>
              <Form.List name="hotel_bookings">
                { (fields, { add, remove }) => (
                  <>
                    <Button onClick={ () => add() } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                    <div className="table-container">
                      <table className="table">
                        {
                          fields.length !== 0 && <thead>
                            <tr>
                              <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.stay_type_id`) }</th>
                              <th>{ trans(`${langKey}.address`) }</th>
                              <th>{ trans(`${langKey}.book_from`) }</th>
                              <th>{ trans(`${langKey}.book_to`) }</th>
                              <th>{ trans(`${langKey}.nights`) }</th>
                              <th>{ trans(`${langKey}.city`) }</th>
                              <th>{ trans(`${langKey}.phone_no`) }</th>
                              <th>{ trans(`${langKey}.email`) }</th>
                              <th>{ trans(`${langKey}.action`) }</th>

                            </tr>
                          </thead>
                        }
                        { fields.map(({ key, name, ...restField }) => (
                          <>
                            <tbody>
                              <tr>
                                <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'stay_type_id'] }
                                    rules={ rules.stay_type_id }
                                  >
                                    <SelectWithSearch placeholder={ trans(`${langKey}.stay_type_id`) } options={ stayTypes } />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'address'] }
                                    rules={ rules.address }
                                  >
                                    <InputTextArea placeholder={ trans(`${langKey}.address`) } />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '220px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'book_from'] }
                                    rules={ rules.book_from }
                                  >
                                    <InputDate />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '220px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'book_to'] }
                                    rules={ rules.book_to }
                                  >
                                    <InputDate />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'nights'] }
                                    rules={ rules.nights }
                                  >
                                    <InputText placeholder={ trans(`${langKey}.nights`) } type="number" onWheel={(e) => e.target.blur()} />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'city'] }
                                    rules={ rules.city }
                                  >
                                    <InputText placeholder={ trans(`${langKey}.city`) } />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'phone_no'] }
                                    rules={ rules.phone_no }
                                  >
                                    <InputText placeholder={ trans(`${langKey}.phone_no`) } type="number" onWheel={(e) => e.target.blur()} />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'email'] }
                                    rules={ rules.email }
                                  >
                                    <InputText placeholder={ trans(`${langKey}.email`) } />
                                  </Form.Item>
                                </td>
                                <td>
                                  <DeleteOutlined className="formlist-remove" onClick={ () => remove(name) } />
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )) }
                      </table>
                    </div>

                  </>
                ) }
              </Form.List>
            </div>
          </>
          : '' }


        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.ticket_bookings`) }</h5>
          <Form.List name="ticket_bookings" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  onCreateTicket()
                  //setRows(fields.length, 'Added')
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.ticket_no`) }</th>
                          <th>{ trans(`${langKey}.from`) }</th>
                          <th>{ trans(`${langKey}.to`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'ticket_no'] }
                                rules={ rules.name }
                              >
                                <InputText placeholder={ trans(`${langKey}.ticket_no`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'from'] }
                                rules={ rules.name }
                              >
                                <InputText placeholder={ trans(`${langKey}.from`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'to'] }
                                rules={ rules.name }
                              >
                                <InputText placeholder={ trans(`${langKey}.to`) } />
                              </Form.Item>
                            </td>

                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                //setRows(key, 'Deleted')
                              } } />
                              <EditOutlined className="ms-2" onClick={ () => {
                                onEditTicket(key)
                                //setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>
      </Form>
      { childComponent }



    </ModalComponent>
  )
}
export default CreateDetails

const rules = {
  departure_date: [
    { required: true, message: trans(`${langKey}.departure_date`) },
  ],
  departure_time: [
    { required: true, message: trans(`${langKey}.departure_time`) },
  ],
  arrival_time: [
    { required: true, message: trans(`${langKey}.arrival_time`) },
  ],
  arrival_date: [
    { required: true, message: trans(`${langKey}.arrival_date`) },
  ],
  travel_type_id: [
    { required: true, message: trans(`${langKey}.travel_type_id`) },
  ],
  travel_mode_id: [
    { required: true, message: trans(`${langKey}.travel_mode_id`) },
  ],
  from: [
    { required: true, message: trans(`${langKey}.from`) },
  ],
  to: [
    { required: true, message: trans(`${langKey}.to`) },
  ],
  pupose: [
    { required: true, message: trans(`${langKey}.pupose`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
