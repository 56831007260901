import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllAssetsReq, deleteAssetReq, getFilterReq } from "./requests";
import { Commons, Const, colors } from "../constants";
import CreateAsset from './components/CreateAsset';
import { Badge, message } from "antd";
import UpdateAsset from "./components/UpdateAsset";
import { FilterComponent, FilterButton } from "@comps/components";
import moment from "moment";


// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["asset"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["asset"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexAssets = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.employee`),
      render: (record) => {
        return <div>{ record.full_name }<br /><small>{ record.emp_code }</small></div>
      },
    },
    {
      key: "asset_name",
      title: trans(`${langKey}.asset_id`),
      dataIndex: "asset_name"
    },
    {
      key: "asset_type_name",
      title: trans(`${langKey}.asset_type_id`),
      dataIndex: "asset_type_name",
    },

    {
      key: "issue_date",
      title: trans(`${langKey}.issue_date`),
      render: (record) => {
        return record.issue_date !== "0000-00-00 00:00:00" && record.issue_date !== undefined ? moment(record.issue_date).format('YYYY-MM-DD') : ''
      }
    },
    {
      key: "deallocation_date",
      title: trans(`${langKey}.deallocation_date`),
      render: (record) => {
        return record.deallocation_date ? moment(record.deallocation_date).format('YYYY-MM-DD') : ''
      }
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ record.status !== "Deallocated" ? onDelete : '' }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters,currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllAssetsReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setDataSource(response.employment_assets.data);
    setPagination(response.employment_assets.meta.pagination)
  }

  const onEdit = (record) => {
    setChildComponent(
      <UpdateAsset onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateAsset onCompleted={ onCompleted } record={ record } disabled={ true } filters={ props.filters }/>
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteAssetReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    message.success("Asset Deleted successfully");
    setDataSource(data.employment_assets.data);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateAsset onCompleted={ onCompleted } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  const onOpenFilter = () => {

    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage}  onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="employment_assets"
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }


  //----end filter--------------------
  return (
    <>
      { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          {!props.filters && <FilterButton onClick={ onOpenFilter } />  }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />
    </>
  );
};

export default IndexAssets;

const filterFields = [
  {
    type: "select",
    multiple: true,
    placeholder: trans(Commons.employee),
    key: "employments",
    data_key: "supporting_data.employees",
    resource_type: 'employee'
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.asset_type_id`),
    key: "asset_type",
    data_key: "supporting_data.asset_type",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(Commons.status),
    key: "allocation_status_",
    data_key: "supporting_data.allocation_status",
    //is_resource: true
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },

]