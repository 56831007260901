import React, { useEffect, useState } from "react";
import { Form, Switch, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["deductionType"].lngKey
const formName = "createDeductionType";

const CreateDeductionType = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formulas, setFormulas] = useState([])
  const [fromSalary, setFromSalary] = useState(false);
  const [types, setTypes] = useState([])
  const [type, setType] = useState('flat')
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    const payload = {
      page: 1
    }
    makeRequestStateless(getRelatedDataReq, payload, onGetFormulas, false)
  }

  const onGetFormulas = (response) => {
    setTypes(createSelectList(response.types))
    setFormulas(createSelectList(response.formulas))
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { deduction_type: { ...data, from_salary: fromSalary } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.deduction_types.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="from_salary" rules={ rules.from_salary } label={ trans(`${langKey}.from_salary`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <label>Balance</label>
              <Switch style={ { margin: 10 } } checked={ fromSalary } onChange={ (e) => setFromSalary(e) } />
              <label>Salary</label>
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
              className="da-mb-16"

            >
              <SelectWithSearch
                value={ type }
                options={ types }
                placeholder={
                  trans(`${langKey}.type`)
                } onChange={ (value) => setType(value) } />
            </Form.Item>
          </Col>

          {
            type !== 'flat' && fromSalary &&
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="formula_id" rules={ rules.formula_id } label={ trans(`${langKey}.formula_id`) }
                className="da-mb-16"
              >
                <SelectWithSearch
                  options={ formulas }
                  placeholder={
                    trans(`${langKey}.formula_id`)
                  } />
              </Form.Item>
            </Col>
          }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="value" rules={ rules.value } label={ trans(`${langKey}.value`) }
              className="da-mb-16"

            >
              <InputText placeholder={ trans(`${langKey}.value`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default CreateDeductionType

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type.required`) },
  ],
  value: [
    { required: true, message: trans(`${langKey}.value.required`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id.required`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}