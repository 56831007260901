// DroppableBox.js
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, SplitCellsOutlined } from '@ant-design/icons';
import React from 'react';
import { useDrop } from 'react-dnd';

const DroppableBox = ({ id,onRemove,onMoveUp,onMoveDown, children,splitColumn,disabled }) => {
  // const [{ isOver }, drop] = useDrop(() => ({
  //   accept: 'WIDGET',
  //   drop: (item) => onDrop(item.id, id),
  //   collect: (monitor) => ({
  //     isOver: !!monitor.isOver(),
  //   }),
  // }));

  return (
    <div style={{display:'flex'}}>
     
<div
 // ref={drop}
  style={{
    height: '120px',
    width: '100%',
    //border: '2px dashed gray',
    //backgroundColor: isOver ? 'lightgreen' : 'white',
    //margin: '1px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    
  }}
>
  {children}
  
</div>

{!disabled &&  <div style={{ marginTop:'16px' }}>
      <li style={{listStyle:'none',margin:'5px'}}>{onMoveUp && <ArrowUpOutlined onClick={onMoveUp} />}</li>
        <li style={{listStyle:'none',margin:'5px'}}>{onMoveDown && <ArrowDownOutlined onClick={onMoveDown} />}</li>
        <li style={{listStyle:'none',margin:'5px'}}> {onRemove && <CloseOutlined onClick={onRemove} />}</li>
       
      </div>}
     
    </div>
  
  );
};

export default DroppableBox;
