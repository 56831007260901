import React, { useEffect, useState } from "react";
import { Button, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, TableComponent, ActionComponent, SelectWithSearch, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import moment from "moment/moment";
import { approveRequest, generateRepaymentReq, getDataByIdReq } from "../requests";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs';
import ViewRepayments from "./viewRepayments";
const langKey = Const["lendApproval"].lngKey
const formName = "updateLendApproval";

const UpdateLendApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('')
  const [form] = Form.useForm();
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [loading, setLoading] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [processed, setProcessed] = useState(false);
  const [paymentmethodId, setPaymentMethodId] = useState(null);
  const [approvedAmount, setApprovedAmount] = useState()
  const [requestById, setRequestById] = useState({})
  const [repayDate, setRepayDate] = useState(null)
  const [repayments, setRepayments] = useState([])
  const [repaymentModal, setRepaymentModal] = useState(null)
  const [childComponent, setChildComponent] = useState(null)

  useEffect(() => {
    //setData()
    getRequestById()
  }, []);

  const getRequestById = () => {
    makeRequest(setLoader, getDataByIdReq, props.record.id, setData, setData);
  }
  const getPaidAmount = (lend) =>{
    var total = 0;
    if(lend.repayments && lend.repayments.length!==undefined){
      for (var i in lend.repayments) {
        if (lend.repayments[i] && lend.repayments[i].processed) {
          total += lend.repayments[i].amount;
        }
      }  
    }
   
    return total;
  }
  const setData = (response, data) => {

    if (response.lend) {
      response.lend.effective_date = response.lend.effective_date ? dayjs(response.lend.effective_date) : null
      response.lend.repayment_date = response.lend.repayment_date ? dayjs(response.lend.repayment_date) : null
      
      response.lend.all_paid_amount=getPaidAmount(response.lend)
      form.setFieldsValue(response.lend)
      form.setFieldsValue(
        {
          employment_id: response.lend.requestor.full_name_code,
          lend_category_id: response.lend.lend_category.name
        }
      )
      setConsents(props.record.consents)
      setLoading(false)
    }
    setRequestById(response.lend)
    setPaymentMethods(createSelectList(props.allData.payment_methods));
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="lendApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.lends.data);
    }
    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="lendApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    if (repayments.length === 0) {
      message.info("Please Generate Re-Payment Plan");
      return;
    }
    const last_remarks = form.getFieldValue('last_remarks');
    let obj = requestById;
    obj.request_as = props.requestAsId
    obj.is_approval = isApproval
    obj.last_remarks = last_remarks
    obj.remarks = last_remarks
    obj.paymentmethod_id = paymentmethodId
    obj.processed = processed
    obj.approved_amount = approvedAmount
    obj.repayment_date = repayDate ? repayDate.format('YYYY-MM-DD') : null
    let newList = []
    repayments.forEach((each) => {
      each.company_id = 1
      each.currency_id = 160
      each.employment_id = requestById.employment_id
      each.repayment_date = dayjs(each.repayment_date).format('YYYY-MM-DD')
      newList.push(each)
    })
    const payload = { request: { ...props.record, ...obj, repayments: newList }, delegator_id: props.delegatorId, api: props.api, filters: props.filters }

    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    message.success("Record Updated");
    props.onCompleted(data.lends.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------

  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]


  const onChangeApprovedAmount = (e) => {
    if (e.target.value > requestById.amount) {
      message.info('Please Enter Approved Amount less than or equal to ' + requestById.amount)
      form.setFieldsValue({
        approved_amount: null
      })
      return
    }
    setApprovedAmount(Number(e.target.value))
  }

  const onChangeRepaymentDate = (date) => {
    setRepayDate(date)
  }

  const generateRepayment = () => {


    requestById.effective_date = requestById.effective_date ? dayjs(requestById.effective_date).format('YYYY-MM-DD') : null
    requestById.current_history = {
      approved_amount: approvedAmount
    }
    requestById.payment_method_id = paymentmethodId
    requestById.repayment_date = repayDate ? dayjs(repayDate).format('YYYY-MM-DD') : null

    const payload = {
      lend: requestById
    }
    makeRequest(setLoader, generateRepaymentReq, payload, onGetRepayments, onErrorGettingRepayments);
  }
  const onGetRepayments = (response) => {

    response.repayments.forEach((repayment) => (
      repayment.repayment_date = dayjs(repayment.repayment_date)
    ))
    //set repay modal
    setRepaymentModal(<ViewRepayments onSaveRepayments={ onSaveRepayments } approvedAmount={ approvedAmount } record={ response.repayments } />)

    setRepayments(response.repayments)
  }
  const onErrorGettingRepayments = (res, err) => {
    message.error(err.response.data.message)
  }

  const onSaveRepayments = (data) => {
    if (!data) {
      setRepaymentModal(null)
      return
    }

    setRepayments(data)
    setRepaymentModal(null)
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    props.disabled ? '' :
      <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    props.disabled ? '' :
      <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>
          <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
            <h5 className="formlist-title">Loan Request</h5>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="lend_type_name" rules={ rules.lend_type_id } label={ trans(`${langKey}.lend_type_id`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.lend_type_id`) }
                  />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="lend_category_id" rules={ rules.lend_category_id } label={ trans(`${langKey}.lend_category_id`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.lend_category_id`) }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="amount" rules={ rules.amount } label={ trans(`${langKey}.amount`) }
                  className="da-mb-16"
                >
                  <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onWheel={(e) => e.target.blur()} />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="effective_date" rules={ rules.effective_date } label={ trans(`${langKey}.effective_date`) }
                  className="da-mb-16"
                >
                  <InputDate />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
                  className="da-mb-16"
                >
                  <InputText placeholder={ trans(`${langKey}.extension_no`) } type="number" onWheel={(e) => e.target.blur()} />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                  className="da-mb-16"
                >
                  <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="created_at" rules={ rules.created_at } label={ trans(`${langKey}.created_at`) }
                  className="da-mb-16"
                >
                  <InputText placeholder={ trans(`${langKey}.name`) } />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
            <h5 className="formlist-title">Approval</h5>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="all_approved_amount" rules={ rules.all_approved_amount } label={ trans(`${langKey}.all_approved_amount`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['all_approved_amount']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.all_approved_amount`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="all_paid_amount" rules={ rules.all_paid_amount } label={ trans(`${langKey}.all_paid_amount`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['all_paid_amount']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.all_paid_amount`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="remaining_amount" rules={ rules.remaining_amount } label={ trans(`${langKey}.remaining_amount`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['remaining_amount']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.remaining_amount`) } type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="currency" rules={ rules.currency_id } label={ trans(`${langKey}.currency_id`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['currency_id']) } size="small"
                  initialValue={ 'Rupee-PKR(Pakistan)' }
                >
                  <InputText placeholder={ trans(`${langKey}.currency_id`) } disabled={ true } />
                </Form.Item>
              </Col>
              {/*    */ }
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="approved_amount" rules={ rules.approved_amount } label={ trans(`${langKey}.approved_amount`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['approved_amount']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.approved_amount`) } type="number" onWheel={(e) => e.target.blur()} disabled={ false } onChange={ onChangeApprovedAmount } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item
                  name='payment_method_id'
                  rules={ rules.paymentmethod_id }
                  label={ trans(`${langKey}.paymentmethod_id`) }
                >
                  <SelectWithSearch options={ paymentMethods } placeholder={ trans(`${langKey}.paymentmethod_id`) } disabled={ false } onChange={ (id) => setPaymentMethodId(id) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['last_remarks']) } size="small"
                >
                  <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="repayment_date" rules={ rules.repayment_date } label={ trans(`${langKey}.repayment_date`) }
                  className="da-mb-16"
                >
                  <InputDate disabled={ props.disabled } onChange={ onChangeRepaymentDate } />
                </Form.Item>
              </Col>
            </Row>
          </div>
          {
            props.disabled || !approvedAmount || !repayDate? '' : <Button className="mt-4" onClick={ generateRepayment } type="primary" disabled={ false }>Generate Re-Payment Plan</Button>
          }

        </Form>
        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>

        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />

      </Skeleton>
      { repaymentModal }
    </ModalComponent>
  )
}

export default UpdateLendApproval

const rules = {
  approved_amount: [
    { required: true, message: trans(`${langKey}.approved_amount`) },
  ],
  paymentmethod_id: [
    { required: true, message: trans(`${langKey}.paymentmethod_id`) },
  ],
  repayment_date: [
    { required: true, message: trans(`${langKey}.repayment_date`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

