import React, { useEffect, useState } from "react";
import { Form, message, Button, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, createFormattedCurrencies } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import '../style.css'
const langKey = Const["myExpense"].lngKey
const formName = "createExpenseType";

const CreateMyExpense = (props) => {

  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [childComponent, setChildComponent] = useState(false)
  const [employments, setEmployments] = useState([])
  const [isLinkedRequest, setIsLinkedRequest] = useState(false)
  const [linkedRequestTypes, setlinkedRequestTypes] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {

    setData()
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    setExpenseTypes(createSelectList(props.record.expense_types));
    setlinkedRequestTypes(createSelectList(props.record.linked_request_types));
    setCurrencies(createFormattedCurrencies(props.record.currencies));
  }


  const onSubmit = (data) => {
    if (data.details === undefined || data.details.length === 0) {
      message.error('Please Add Details');
      return
    }
    if (data.details.attachment !== undefined && data.details.attachment !== null && data.details.attachment.url === undefined) {
      data.details.attachment.file = data.details.attachment.fileList.length > 0 ? data.details.attachment.file : null
    }
    data.is_linked_request = isLinkedRequest
    data.is_on_behalf = true;
    data.behalf_employment_id = props.authUser.employment_id;

    let newDetails = []
    data.details.map((each) => {
      each.date = dayjs(each.date).format('YYYY-MM-DD')
      newDetails.push(each)
    }
    )
    data.details = newDetails
    data.employment_id = props.authUser.employment_id
    const payload = { expense: data, filters: `employee_|${props.authUser.employment_id}`, page: props.page };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.expenses.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //==============add details =================

  //-----------------------
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const onRemove = () => {
    //setFileList([null])
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }

      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>


        {/* <Form.Item name="is_linked_request" rules={ rules.is_linked_request } label={ trans(`${langKey}.is_linked_request`) }
          className="da-mb-16"

          valuePropName="checked"

        >
          <Checkbox onChange={ (e) => setIsLinkedRequest(e.target.checked) } />
        </Form.Item> */}
        { isLinkedRequest ?
          <>
            <Form.Item name="linked_request_type" rules={ rules.linked_request_type } label={ trans(`${langKey}.linked_request_type`) }
              className="da-mb-16"

            >
              <SelectWithSearch
                options={ linkedRequestTypes }
                placeholder={ trans(`${langKey}.linked_request_type`) }

              />
            </Form.Item>
            <Form.Item name="linked_request_id" rules={ rules.linked_request_id } label={ trans(`${langKey}.linked_request_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ employments }
                placeholder={ trans(`${langKey}.linked_request_id`) }

              />
            </Form.Item>
          </> : ''
        }

        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.extension_no`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
            </Form.Item>
          </Col>
        </Row>


        <div className="formlist-container">
          <h5 className="formlist-title">Details</h5>

          <Form.List name="details">
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => add() } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.date`) }</th>
                          <th>{ trans(`${langKey}.expensetype_id`) }</th>
                          <th>{ trans(`${langKey}.amount`) }</th>
                          <th>{ trans(`${langKey}.description`) }</th>
                          <th>{ trans(`${langKey}.action`) }</th>
                        </tr>
                      </thead>
                    }
                    { fields.map(({ key, name, ...restField }) => (
                      <>
                        <tbody>
                          <tr>
                            <td style={ { paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'date'] }
                                rules={ rules.date }
                              >
                                <InputDate />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'expensetype_id'] }
                                rules={ rules.expensetype_id }
                              >
                                <SelectWithSearch placeholder={ trans(`${langKey}.expensetype_id`) } options={ expenseTypes } />
                              </Form.Item>
                            </td>

                            <td >
                              <Form.Item
                                { ...restField }
                                name={ [name, 'amount'] }
                                rules={ rules.amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onWheel={(e) => e.target.blur()} />
                              </Form.Item>
                            </td>
                            <td >
                              <Form.Item
                                { ...restField }
                                name={ [name, 'description'] }
                                rules={ rules.description }
                              >
                                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
                              </Form.Item>
                            </td>

                            {/* <td width={ 100 }>
                            <Form.Item name="attachment" >
                              <UploadFile accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                fileList={ fileList }
                                onChange={ onChange }
                                onPreview={ onPreview }
                                onRemove={ onRemove }
                                multiple={ false }
                                beforeUpload={ (file) => {
                                  return false;
                                } }
                              >
                                { fileList.length === 1 ? null : 'Upload' }
                              </UploadFile>
                            </Form.Item>
                          </td> */}
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => remove(name) } />
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )) }
                  </table>
                </div>

              </>
            ) }
          </Form.List>
        </div>

      </Form>
      {/* <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal> */}
    </ModalComponent>
  )
}

export default CreateMyExpense

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  currency_id: [
    { required: true, message: trans(`${langKey}.currency_id`) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  expensetype_id: [
    { required: true, message: trans(`${langKey}.expensetype_id`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}