import React, { useEffect, useState } from "react";
import { Button, Form, Skeleton, Space, message } from "antd";
import { SaveButton, InputText, InputTextArea, InputCheck, CancelButton, ModalComponent, trans, SelectWithSearch, TableComponent, ActionComponent, InputDate, FlowHistoryButton, SelectResourceList ,ButtonComponent} from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { getRelatedDatareq, createConsentReq, createMessageConsentReq, approveRequest } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from "@comps/commonFunctions";
import moment from "moment/moment";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import '@assets/style.css'
import ViewProbationHistory from "../../probation/components/ViewProbationHistory";

const langKey = Const["evaluation"].lngKey
const formName = "updateEvaluation";

const UpdateEvaluation = (props) => {
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [scales, setScales] = useState([]);
  const [durations, setDurations] = useState({})
  const [isExtended, setIsExtended] = useState(false);
  const [openConsent, setOpenConsent] = useState(false);
  const [concentDataSource, setConsentDataSource] = useState([]);
  const [consentMesssages, setConsentMessages] = useState([]);
  const [consentRecordId, setConsentRecordId] = useState([]);
  const [openConsentChat, setOpenConsentChat] = useState(false)
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm();
  const [currentHistory,setCurrentHistory]=useState();
  const [histories,setHistories]=useState();
  
  useEffect(() => {
    getRemoteData()
    setFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRemoteData = () => {
    makeRequestStateless(getRelatedDatareq, false, onGetRealated, false)
  }
  const onGetRealated = (response) => {
    setLoader(false)
    setAttributes(response.professional_attributes)
    setScales(createSelectList(response.evaluation_scales))
  }
  const setFormData = () => {
    if (!props) {
      return;
    }
    setDurations(props.record.durations ? props.record.durations : {})
    form.setFieldsValue({
      employment_id: props.record.requestor.full_name_code,
      duration: props.record.duration,
      end_date: props.record.end_date ? moment(props.record.end_date) : null,
      month_wise_evaluation:props.record.month_wise_evaluation
    });
    setConsentDataSource(props.record.consents)
    setCurrentHistory(props.record.current_history)
    setHistories(props.record.histories);
    if(props.record.histories.length>0 && typeof props.record.histories[0].evaluation && props.record.histories[0].evaluation.length===undefined){
      props.record.histories[0].evaluation=formatEvaluation( props.record.histories[0].evaluation)
      setHistories(props.record.histories)
    }
  }

  function formatEvaluation(data) {
    // Create an array to store the converted data
    let convertedData = [];
    // Loop through the original data object
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            // Create a new object for each key-value pair and push it to the converted data array
            let newObj = {};
            newObj[key] = [value];
            convertedData.push(newObj);
        }
    }

    return convertedData;
}
  //===============evaluation==================================
  const onSubmitEvaluation = (data) => {
    setSaveLoader(true)
    let evaluation = []
    Object.entries(data.obj_attributes[0]).forEach(([key, value]) => {
      let [, firstPart, secondPart] = key.match(/\.(\d+)\.(\d+)/);
      firstPart = parseInt(firstPart);
      secondPart = parseInt(secondPart);
      let existingEntry = evaluation.find(item => item[firstPart]);
      if (existingEntry) {
        let durationObj = existingEntry[firstPart].find(obj => obj[secondPart]);
        if (durationObj) {
          durationObj[secondPart] = value;
        } else {
          existingEntry[firstPart].push({
            [secondPart]: value
          });
        }
      } else {
        evaluation.push({
          [firstPart]: [
            {
              [secondPart]: value
            }
          ]
        });
      }
    });
    const propData = props.record;
    propData.request_as = props.requestAsId
    propData.evaluation = evaluation;
    propData.is_extended = isExtended
    propData.extended_duration = data.extended_duration
    propData.last_remarks = data.last_remarks;

    propData.is_probation_completed = isExtended ? false : true
    propData.is_approval = data.is_approval
    propData.job_objective = data.job_objective
    propData.is_approval = true
    const payload = { request: propData }
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
    setSaveLoader(true)
  }


  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Request Approved");
    props.onCompleted(data.probations.data);
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  //================end evaluation=============================

  //==================Consent===============================
  const onCreateConsent = () => {
    setOpenConsent(true)
  }

  const onSubmitConsent = (data) => {
    const payload = { consent: { employment_id: data.employment_id, message: data.message, request_id: props.record.id, request_as: props.record.current_flow.request_as_id } }
    makeRequest(setLoader, createConsentReq, payload, onCreatedConsent, onError);
    setSaveLoader(true)
  }
  const onCreatedConsent = (data) => {
    setSaveLoader(false)
    message.success("Consent Created");
    props.onCompleted(data.probations.data);
    setOpenConsent(false)
  }

  const onConsentChat = (record) => {
    setConsentRecordId(record.id)
    setConsentMessages(record.messages)
    setOpenConsentChat(true)
  }
  const onSubmitConsentChat = (data) => {
    const payload = { consent: { request_as: props.requestAsId, consent_id: consentRecordId, message: data.message } }

    makeRequest(setLoader, createMessageConsentReq, payload, onCreatedMessage, onError);
    setSaveLoader(true)
  }
  const onCreatedMessage = (data) => {
    setSaveLoader(false)
    message.success("Chat has been Sent");
    props.onCompleted(data.probations.data);
    setOpenConsentChat(false)
  }
  const consentChatColumns = [

    {
      key: 'message',
      title: trans(`${langKey}.message`),
      render: (record) => {
        return record.message
      }
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
  ]

  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewProbationHistory onCompleted={ ()=>setChildComponent(null) } record={ record } disabled={ false } />
    );
  };
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
        <ButtonComponent onClick={ () => onViewHistory(props.record) } type="primary" { ...props } disabled={false}>{ props.text ? props.text : "History" }</ButtonComponent>
    </div>,
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" text="Evaluate"/>,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled } loader={ true }>
      <Skeleton loading={ loader }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />

        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmitEvaluation } scrollToFirstError>
          <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
            <h5 className="formlist-title">Request</h5>

            <Form.Item name="duration" label={ trans(`${langKey}.duration`) }
              className="da-mb-16"
              { ...getErrorProps(errors['duration']) } size="small"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.duration`) } disabled={ true } />
            </Form.Item>
            <Form.Item name="end_date" rules={ rules.end_date } label={ trans(`${langKey}.end_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['end_date']) } size="small"
            >
              <InputDate format='YYYY-MM-DD' disabled={ true } />
            </Form.Item>
            <Form.Item name="month_wise_evaluation" rules={ rules.month_wise_evaluation } label={ trans(`${langKey}.month_wise_evaluation`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month_wise_evaluation']) } size="small"
              >
                <InputCheck checked={props.record.month_wise_evaluation} disabled/>
              </Form.Item>
          </div>

{!props.record.is_completed && <>
  <div className="formlist-container">
            <h5 className="formlist-title">Major Responsibilities Assigned During Probation Period</h5>

            <Form.List name="job_objective" initialValue={ [] }>
              { (fields, { add, remove }) => (
                <>
                  <Button onClick={ () => add() } icon={ <PlusOutlined /> } className="add-node-button" type="primary">Add</Button>
                  <div className="table-container">
                    <table className="table">
                      <tbody>
                        {
                          fields.map(({ key, name, ...restField }) => (
                            <>
                              <tr>
                                <td style={ { paddingLeft: 20 } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'objective'] }
                                    rules={ rules.name }
                                  >
                                    <InputText placeholder="Job Objectives/Assignments" />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'evaluation'] }
                                    rules={ rules.name }
                                  >
                                    <SelectWithSearch
                                      options={ scales }
                                      placeholder="Evaluation Rating" />
                                  </Form.Item>
                                </td>

                                <td>
                                  <DeleteOutlined className="formlist-remove" onClick={ () => remove(name) } />
                                </td>

                              </tr>
                            </>
                          )) }
                      </tbody>
                    </table>
                  </div>
                </>
              ) }

            </Form.List>
          </div>
          <div className="formlist-container">
            <h5 className="formlist-title">Evaluation</h5>
            <div className="evaluation-container">
              <table className="w-100 table-bordered">
                <tr>
                  <th className="p-2">Attributes</th>
                  { Object.values(durations).map((each, key) => (
                    <th className="p-2 ps-5" span={ 2 } key={ each }>{ each } Days ({each/30} m )</th>
                  )) }
                </tr>
                <Form.List
                  name="obj_attributes"
                  initialValue={ [
                    {},
                  ] }
                >
                  { (fields, { add, remove }) => (
                    <>
                      {
                        attributes.map((attribute, atributekey) => (
                          <tr>
                            <td className="p-2">
                              <p style={ { width: 250 } }>{ attribute.name }</p>
                            </td>
                            { Object.values(durations).map((duration, durationKey) => (
                              <td className="p-2 ">
                                <>
                                  { fields.map((field, index) => (
                                    <Form.Item
                                      { ...field }
                                      name={ [field.name, "evaluation." + (attribute.id) + "." + duration] }
                                      rules={ histories.length !== 0 ? histories[0].evaluation[atributekey][attribute.id].hasOwnProperty(durationKey) ? '' : rules.evaluation_scale_id : rules.evaluation_scale_id }
                                    >
                                      <SelectWithSearch
                                        disabled={ histories.length !== 0 ? histories[0].evaluation[atributekey][attribute.id].hasOwnProperty(durationKey) ? true : false : '' }
                                        style={ { width: 250 } }
                                        //onChange={ (e) => onChangeEachScale(attribute.id, duration, e) }
                                        placeholder={ trans(`${langKey}.evaluation_scale_id`) }
                                        defaultValue={ histories.length !== 0 ? histories[0].evaluation[atributekey][attribute.id].hasOwnProperty(durationKey) ?histories[0].evaluation[atributekey][attribute.id][durationKey].hasOwnProperty(duration)? histories[0].evaluation[atributekey][attribute.id][durationKey][duration]: '': '' : '' }
                                        options={ scales }
                                      />
                                    </Form.Item>
                                  )) }
                                </>
                              </td>
                            )) }
                          </tr>
                        ))
                      }
                    </>
                  ) }
                </Form.List>
              </table>
            </div>
          </div>
          <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
            <h5 className="formlist-title">Approval</h5>
            <Form.Item name="is_extended" rules={ []} label={ trans(`${langKey}.is_extended`) }
              className="da-mb-16"
              size="small"
              valuePropName="checked"
            >
              <InputCheck onChange={ (e) => setIsExtended(e.target.checked) } />
            </Form.Item>
            {
              isExtended ?
                <Form.Item name="extended_duration" rules={ rules.duration } label={ trans(`${langKey}.extended_duration`) }
                  className="da-mb-16"
                  size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.extended_duration`) } type="number" onWheel={(e) => e.target.blur()} />
                </Form.Item>
                :
                <Form.Item name="is_probation_completed" rules={ rules.is_completed } label={ trans(`${langKey}.complete_probation`) }
                  className="da-mb-16"
                  size="small"
                  valuePropName="checked"
                >
                  <InputCheck />
                </Form.Item>
            }
            <Form.Item name="last_remarks" rules={ []} label={ trans(`${langKey}.remarks`) }
              className="da-mb-16"
              size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.remarks`) } />
            </Form.Item>
          </div>
</>}
         
         
        </Form>
        <h5 className="formlist-title">Consents 
        {!props.disabled && <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button>}</h5>
      </Skeleton>
      <ModalComponent open={ openConsent } mainTitle={ trans(Commons.create) } subTitle={ "Consents" } onCancel={ () => setOpenConsent(false) } footer=''>
        <Form layout="vertical" onFinish={ onSubmitConsent }>
          <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
            className="da-mb-16"
            { ...getErrorProps(errors['employment_id']) } size="small"
          >
            <SelectResourceList type='employment' value={ props.record.employment_id } />
          </Form.Item>

          <Form.Item name="message" rules={ rules.consent_message } label={ trans(`${langKey}.consent_message`) }
            className="da-mb-16"
            { ...getErrorProps(errors['consent_message']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.consent_message`) } />
          </Form.Item>
          <Space>
            <SaveButton loading={ saveLoader } htmlType='submit' />
            <CancelButton onClick={ () => setOpenConsent(false) } />
          </Space>
        </Form>
      </ModalComponent>

      {/*  Chat Consent   */ }
      <ModalComponent top={ 20 } maskClosable={ false }
        mainTitle={ trans(Commons.view) } footer='' onCancel={ () => setOpenConsentChat(false) } subTitle={ "Consents" }
        open={ openConsentChat }>
        <Form name="consent" layout="vertical" onFinish={ onSubmitConsentChat }>
          <TableComponent
            columns={ consentChatColumns }
            dataSource={ consentMesssages }
          />
          <Form.Item name="message" rules={ rules.consent_message } label={ trans(`${langKey}.consent_message`) }
            className="da-mb-16"
            { ...getErrorProps(errors['consent_message']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.consent_message`) } />
          </Form.Item>
          <Space>
            <SaveButton htmlType='submit' />
            <CancelButton onClick={ () => setOpenConsentChat(false) } />
          </Space>
        </Form>
      </ModalComponent>
      <TableComponent
        columns={ consentColumns }
        dataSource={ concentDataSource }
      />
    </ModalComponent>
  )
}

export default UpdateEvaluation

const rules = {
  employment_id: [
    {
      required: true, message: trans(`${langKey}.employment_id.req`)
    },
  ],
  consent_message: [
    {
      required: true, message: trans(`${langKey}.consent_message.req`)
    },
  ],
  duration: [
    {
      required: true, message: trans(`${langKey}.duration`)
    },
  ],
  is_completed: [
    {
      required: true, message: trans(`${langKey}.is_completed`)
    },
  ],
  evaluation_scale_id: [
    {
      required: true, message: trans(`${langKey}.evaluation_scale_id.req`)
    },
  ],

};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}