import React, { useEffect, useState } from "react";
import { Form, message, Row, Col, Button } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { getEmploymentsReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, createFormattedCurrencies } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import EmployeeInfo from '@mods/common/EmployeeInfo';

import dayjs from 'dayjs';
import '../style.css'

const langKey = Const["companyExpense"].lngKey
const formName = "updateExpenseType";

const UpdateCompanyExpense = (props) => {

  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [childComponent, setChildComponent] = useState(false)
  const [employments, setEmployments] = useState([])
  const [isLinkedRequest, setIsLinkedRequest] = useState(false)
  const [linkedRequestTypes, setlinkedRequestTypes] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [details, setDetails] = useState([])
  const [deletedDetails, setDeletedDetails] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    getEmployments()
    getExpenseTypes()
    setData()
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    setExpenseTypes(createSelectList(props.response.expense_types));
    setlinkedRequestTypes(createSelectList(props.response.linked_request_types));
    setCurrencies(createFormattedCurrencies(props.response.currencies));
    setDetails(props.record.details)

    let newDetails = []
    props.record.details.forEach((each) => {
      each.date = each.date === "0000-00-00 00:00:00" ? null : dayjs(each.date)
      newDetails.push(each)
    }
    )
    props.record.details = newDetails;
    form.setFieldsValue(props.record)
  }


  const getEmployments = () => {
    const payload = { filters: '' }
    makeRequestStateless(getEmploymentsReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setEmployments(createSelectList(response.resources.data))
    setLoading(false)
  }
  const getExpenseTypes = () => {

  }

  //set deleted rows
  const onDeleteRow = (key, state) => {
    if (details[key] !== undefined && details[key].id !== undefined && state === 'Deleted') {
      details[key].row_state = 'Deleted'
      deletedDetails.push(details[key])
    }
  }

  const onSubmit = (data) => {
    // if (data.details.attachment !== undefined && data.details.attachment !== null && data.details.attachment.url === undefined) {
    //   data.details.attachment.file = data.details.attachment.fileList.length > 0 ? data.details.attachment.file : null
    // }
    if (data.details === undefined || data.details.length === 0) {
      message.error('Please Add Details');
      return
    }
    const newDetails = []
    data.details.forEach((each, key) => {
      if (each.id === undefined) {
        each.row_state = 'Added'
      }
      if (each.id !== undefined) {
        each.row_state = 'Updated'
      }
      each.date = dayjs(each.date).format('YYYY-MM-DD')
      newDetails.push(each)
    })

    data.details = newDetails.concat(deletedDetails)
    data.is_on_behalf = true;
    data.behalf_employment_id = props.record.behalf_employment_id;
    data.id = props.record.id;
    const payload = { expense: { ...props.record, ...data } };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    setsaving(true)
    message.success("Saved successfully");
    props.onCompleted(data.expenses.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    let errorList = [];
    message.error(res.response.data.message);
  }

  //==============add details =================

  //-----------------------
  //============uploading file============
  // const [fileList, setFileList] = useState([]);
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  // const handleCancel = () => setPreviewOpen(false);
  // const getBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };
  // const onPreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };
  // const onRemove = () => {
  //   //setFileList([null])
  // }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>

        {/* <Form.Item name="is_linked_request" rules={ rules.is_linked_request } label={ trans(`${langKey}.is_linked_request`) }
          className="da-mb-16"

          valuePropName="checked"

        >
          <Checkbox onChange={ (e) => setIsLinkedRequest(e.target.checked) } disabled={ true } />
        </Form.Item> */}
        { isLinkedRequest ?
          <>
            <Form.Item name="linked_request_type" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
              className="da-mb-16"

            >
              <SelectWithSearch
                options={ linkedRequestTypes }
                placeholder={ trans(`${langKey}.linked_request_type`) }

              />
            </Form.Item>
            <Form.Item name="linked_request_id" rules={ rules.linked_request_id } label={ trans(`${langKey}.linked_request_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ employments }
                placeholder={ trans(`${langKey}.linked_request_id`) }

              />
            </Form.Item>
          </> : ''
        }

        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.extension_no`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
            </Form.Item>
          </Col>
        </Row>

        <div className="formlist-container">
          <h5 className="formlist-title">Details</h5>

          <Form.List name="details" initialValue={ details }>
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => add() } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.date`) }</th>
                          <th>{ trans(`${langKey}.expensetype_id`) }</th>
                          <th>{ trans(`${langKey}.amount`) }</th>
                          <th>{ trans(`${langKey}.description`) }</th>
                          <th>{ trans(`${langKey}.action`) }</th>
                        </tr>
                      </thead>
                    }
                    { fields.map(({ key, name, ...restField }) => (
                      <>
                        <tbody>
                          <tr>
                            <td width={ 300 } style={ { paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'date'] }
                                rules={ rules.date }
                              >
                                <InputDate />
                              </Form.Item>
                            </td>
                            <td width={ 300 }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'expensetype_id'] }
                                rules={ rules.expensetype_id }
                              >
                                <SelectWithSearch placeholder={ trans(`${langKey}.expensetype_id`) } options={ expenseTypes } />
                              </Form.Item>
                            </td>

                            <td width={ 300 }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'amount'] }
                                rules={ rules.amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onWheel={(e) => e.target.blur()} />
                              </Form.Item>
                            </td>
                            <td width={ 300 }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'description'] }
                                rules={ rules.description }
                              >
                                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
                              </Form.Item>
                            </td>

                            {/* <td width={ 300 }>
                          <Form.Item name="attachment" >
                            <UploadFile accept=".jpg,.png,.jpeg"
                              listType="picture-card"
                              fileList={ fileList }
                              onChange={ onChange }
                              onPreview={ onPreview }
                              onRemove={ onRemove }
                              multiple={ false }
                              beforeUpload={ (file) => {
                                return false;
                              } }
                            >
                              { fileList.length === 1 ? null : 'Upload' }
                            </UploadFile>
                          </Form.Item>
                        </td> */}
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                onDeleteRow(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )) }
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>

      </Form>
      {/* <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal> */}
    </ModalComponent>
  )
}

export default UpdateCompanyExpense

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  currency_id: [
    { required: true, message: trans(`${langKey}.currency_id`) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  expensetype_id: [
    { required: true, message: trans(`${langKey}.expensetype_id`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}