import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col, Spin } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch ,TableWithFilter} from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { getDataByIdReq, getRelatedDataReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs'
const langKey = Const["saleIncentives"].lngKey
const formName = "createAttendanceRequest";
const ViewIncentiveEmployees = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [branches, setBranches] = useState([])
  const [incentiveTypes, setIncentiveTypes] = useState([])
  const [designations, setDesignations] = useState([]);
  const [isFlat, setIsFlat] = useState(false);
  const [employees,setEmployees] = useState([]);

  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    makeRequest(setLoader, getRelatedDataReq, null, setRelatedData, null);
    makeRequest(setLoader, getDataByIdReq, props.record, onGetData, null);
  }

  const onGetData = (res) => {
    if(res.incentive){
      let emp=res.incentive.incentive_employments;
      emp.map(each => (
        each.full_name_code = each.employment.full_name_code
      ));
      setEmployees(emp);
    }
  }

  const setRelatedData = (res) => {

    form.setFieldsValue({
      branch_id: props.record.branch_id,
      payitem_id: props.record.payitem_id,
      designation_id: props.record.designation_id,
      month: dayjs(props.record.month),
      total_amount: props.record.total_amount,
      is_flat: props.record.is_flat,
      applicable_amount: props.record.applicable_amount
    })
    setBranches(createSelectList(res.branches))
    setDesignations(createSelectList(res.designations))
    setIncentiveTypes(createSelectList(res.payitems))
  }

  const onSubmit = (data) => {
    data.month=data.month.date(1);
    const payload = {
      id: props.record.id,
      incentive: {
        id: props.record.id,
        branch_id: data.branch_id,
        designation_id: data.designation_id,
        payitem_id: data.payitem_id,
        month: data.month.format('YYYY-MM-DD'),
        total_amount: data.total_amount,
        is_flat: data.is_flat,
        applicable_amount: data.applicable_amount
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.incentives.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton disabled={ loader } loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Spin spinning={ loader }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ branches }
                  placeholder={ trans(`${langKey}.branch_id`) }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="designation_id" rules={ rules.designation_id } label={ trans(`${langKey}.designation_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ designations }
                  placeholder={ trans(`${langKey}.designation_id`) }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="month" rules={ rules.month } label={ trans(`${langKey}.month`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month']) } size="small"
              >
                <InputDate picker="month" format='YYYY-MM' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ incentiveTypes }
                  placeholder={ trans(`${langKey}.payitem_id`) }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="total_amount" rules={ rules.total_amount } label={ trans(`${langKey}.total_amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['total_amount']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.total_amount`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_flat" rules={ rules.is_flat } label={ trans(`${langKey}.is_flat`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_flat']) } size="small"
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Percentage",
                      value: false
                    },
                    {
                      label: "Flat",
                      value: true
                    }
                  ] }
                  placeholder={ trans(`${langKey}.payitem_id`) }

                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="applicable_amount" rules={ rules.applicable_amount } label={ trans(`${langKey}.applicable_amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['applicable_amount']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.applicable_amount`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>
          </Row>

        </Form>
        {
            employees.length > 0 && <>
              <h5 className="formlist-title">Employees</h5>
              <div style={ {
                overflowY: 'scroll',
                maxHeight: '50vh'
              } }>
                <TableWithFilter loader={loader} columns={ [{
                  title: 'Employee',
                  
                  key: 'full_name_code',
                  dataIndex: 'full_name_code',
                },
                {
                  title: 'Designation',
                  hideFilter:true,
                  render: (text,record)=>{
                    return record.employment.designation_name
                },
                },
                {
                  title: 'Amount',
                  key: 'amount',
                  dataIndex: 'amount',
                  
                },
              ] }

                  data={ employees }
                />
               
              </div>
            </>
          }
      </Spin>
    </ModalComponent>
  )
}

export default ViewIncentiveEmployees

const rules = {
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  month: [
    { required: true, message: trans(`${langKey}.month`) },
  ],
  applicable_amount: [
    { required: true, message: trans(`${langKey}.applicable_amount`) },
  ],
  total_amount: [
    { required: true, message: trans(`${langKey}.total_amount`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

