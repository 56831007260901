import React, { useEffect, useState } from "react";
import { Form, Button, message, Row, Col } from "antd";
import { SaveButton, SelectWithSearch, SelectMultiWithSearch, InputText, InputTextArea, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const langKey = Const["travelPolicy"].lngKey
const formName = "travelPolicy";

const CreateTravelPolicy = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [travelTypes, setTravelTypes] = useState([])
  const [travelModes, setTravelModes] = useState([])
  const [details, setDetails] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    setTravelTypes(createSelectList(props.allData.travel_types))
    setTravelModes(createSelectList(props.allData.travel_modes))
  }

  const onSubmit = (data) => {
    if (data.details === undefined || data.details.length < 1) {
      message.info('Please Add Details For Policy!')
      return;
    }

    const payload = {
      travel_policy: data

    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.travel_policys.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  // ==================================slabs actions =================================
  //set deleted rows
  const setRows = (key, state) => {

  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.details`) }</h5>
          <Form.List name="details" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">{ trans(Commons.create) } </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th>{ trans(`${langKey}.traveling_type_id`) }</th>
                          <th>{ trans(`${langKey}.traveling_mode_id`) }</th>
                          <th>{ trans(`${langKey}.company_stay_amount`) }</th>
                          <th>{ trans(`${langKey}.self_stay_amount`) }</th>
                          <th>{ trans(`${langKey}.daily_allowance`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'traveling_type_id'] }
                                rules={ rules.traveling_type_id }
                              >
                                <SelectWithSearch
                                  options={ travelTypes }
                                  placeholder={ trans(`${langKey}.traveling_type_id`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'traveling_mode_id'] }
                                rules={ rules.traveling_mode_id }
                              >
                                <SelectMultiWithSearch
                                  options={ travelModes }
                                  placeholder={ trans(`${langKey}.traveling_mode_id`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'company_stay_amount'] }
                                rules={ rules.company_stay_amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.company_stay_amount`) } type="number" onWheel={(e) => e.target.blur()} />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'self_stay_amount'] }
                                rules={ rules.self_stay_amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.self_stay_amount`) } type="number" onWheel={(e) => e.target.blur()} />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'daily_allowance'] }
                                rules={ rules.daily_allowance }
                              >
                                <InputText placeholder={ trans(`${langKey}.daily_allowance`) } type="number" onWheel={(e) => e.target.blur()} />
                              </Form.Item>
                            </td>

                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>
      </Form>

    </ModalComponent>
  )
}

export default CreateTravelPolicy

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  traveling_type_id: [
    { required: true, message: trans(`${langKey}.traveling_type_id`) },
  ],
  traveling_mode_id: [
    { required: true, message: trans(`${langKey}.traveling_mode_id`) },
  ],
  company_stay_amount: [
    { required: true, message: trans(`${langKey}.company_stay_amount`) },
  ],
  self_stay_amount: [
    { required: true, message: trans(`${langKey}.self_stay_amount`) },
  ],
  daily_allowance: [
    { required: true, message: trans(`${langKey}.daily_allowance`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}