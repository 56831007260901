import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Skeleton } from 'antd'
import { makeRequestStateless } from "@utils/helpers";
import { generateReportReq } from "../requests";
import { useParams } from 'react-router-dom';
import { BodyComponent, HeaderComponent, CreateButton } from '@comps/components'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import * as XLSX from "xlsx";

var columnsToDownload = []
const GenerateReport = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()
  //use states start

  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState(null)

  const [hiddenColumns, setHiddenColumns] = useState([])
  const [visibleColumns, setVisibleColumns] = useState([])
  const [filterList,setFilterList] = useState([])
  const [selectedColumns, setSelectedColumns] = useState([]);
  //var visibleColumns = []

  //render
  useEffect(() => {
    getInitalData();
    // eslint-disable-next-line
  }, []);

  const getInitalData = () => {
    const payload = {
      id: id
    }
    makeRequestStateless(generateReportReq, payload, onSuccessReportDataReq, onErrorReportData);
  }
  const onSuccessReportDataReq = (response) => {
    
    setColumns(response.columns);
    
    setDataSource(response.report_data.data === undefined ? response.report_data : response.report_data.data)
    setReport(response.report)
    const newColumns = []
    response.columns.forEach(column => {
      newColumns.push(
        {
          name: column.column_key,
          label: column.report_display_name,
          options: {
            filter: true,
            sort: true,
          }
        },
      )

      selectedColumns.push(column.column_key);
    })

    columnsToDownload = newColumns
    setVisibleColumns(newColumns)
    setColumns(newColumns)
    setLoading(false)
  }
  const onErrorReportData = (response) => {

  }


  const options = {
    draggable:true,
    draggableColumns:{enabled:true},
    //resizableColumns:true,
    //sort:true,
    responsive: 'standard',
    searchAlwaysOpen:true,
    filterType:'multiselect',
    //selectableRows: 'none',
    filter: true,
    download: true,
    // callback that gets executed when filters are confirmed
    onFilterChange: (column, filterList, type) => {
        var newFilters = () => (filterList);
        setFilterList(newFilters);
        //this.handleFilterSubmit(newFilters);
    },
    // customExport: (data, options) => {
    //   return data.map(row =>
    //     row.data.map(cell => `'${cell}`) // Prefixes each cell with a single quote to ensure Excel treats it as text
    //   );
    // },
   
    onColumnViewChange: (element, action) => {
      if (action === 'add') {
        if (!selectedColumns.includes(element)) {
            setSelectedColumns(prevArray => [...prevArray, element]); // Spread operator to create a new array
        } 
    } else if (action === 'remove') {
        if (selectedColumns.includes(element)) {
            setSelectedColumns(prevArray => prevArray.filter(item => item !== element)); // Filter out the element
        } 
    }
    },
    
    downloadOptions: {
      filename: `${report && report.title}-${dayjs().format('YYYY-MM-DD')}.xlsx`, // Set your custom file name here
      separator: ','
      //columns: columnsToDownload,
    },
    onDownload: (buildHead, buildBody, columns, allData) => {
      // let newColumns=[];
      // // only add new columns 
      //  columns.forEach(column => {
      //   if(selectedColumns.includes(column.name)){
      //     newColumns.push(column);
      //   }
      //  }
      //  )
       // Filter the data manually based on tableState.filterList
       var filteredData = allData.filter(row => {
        var ret = true;
        for (var i = 0; i <= columns.length; i++) {
          if (filterList[i] && filterList[i].length) {
            ret = ret && filterList[i].filter(fil => {
              return row.data[i] === fil;
            }).length > 0;
          }
        }
        return ret;
      });

      // Prepare data array with headers and data
      const dataForExport = [
        columns.map(col => col.label),
        ...filteredData.map(row => row.data.map(cell =>cell)), // Convert all cells to strings to preserve formatting
      ];


      // Create a new workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(dataForExport);

      // Apply styling for headers
      const headerStyle = {
        font: { bold: true },
        alignment: { horizontal: "center", vertical: "center" },
        fill: { fgColor: { rgb: "4F81BD" } },
      };

      // Loop through headers to apply styling
      dataForExport[0].forEach((_, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
        ws[cellAddress].s = headerStyle;
      });

      // Auto-width calculation based on header and cell content
      const columnWidths = dataForExport[0].map((colHeader, colIndex) => {
        const maxContentWidth = Math.max(
          colHeader.length, // Header length
          ...dataForExport.slice(1).map(row => row[colIndex]?.length || 0) // Max length in each column
        );
        return { wch: maxContentWidth + 2 }; // Add padding
      });
      ws["!cols"] = columnWidths;

       // Freeze the first row using !panes
       ws["!panes"] = [
        {
          state: "frozen",
          ySplit: 1, // Freeze the first row
          topLeftCell: "A2",
          activePane: "bottomLeft",
        },
      ];

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws,`Sheet1`);

      // Export the workbook
      XLSX.writeFile(wb, `${report && report.title}.xlsx`);

      // Return false to prevent default CSV download
      return false;
    },
    // other options...
  };

  return (
    <BodyComponent>
      <HeaderComponent modkey={props.modkey}>
        <CreateButton onClick={ () => navigate('/reports/all-reports') } text='Back' />

      </HeaderComponent>
      <Skeleton loading={ loading }>
        <MUIDataTable
          title={ report && report.title !== undefined && report.title }
          data={ dataSource }
          columns={ columns }
          options={ options }
        />
      </Skeleton>
    </BodyComponent>
  );
}

export default GenerateReport

