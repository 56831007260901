import React, { useEffect, useState } from "react";
import { Form, message ,Select} from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans,InputCheck } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["questions"].lngKey
const formName = "createReason";

const UpdateQuestion = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [type,setType]=useState('text');
  const [form]=Form.useForm();

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData=()=>{
    setType(props.record.type);
    form.setFieldsValue(props.record);
  }

  const types=[
    {
      label:"Text",
      value:'text'
    },
    {
      label:"Checkbox",
      value:'checkbox'
    },
    {
      label:"Radio",
      value:'radio'
    },
    {
      label:"Date",
      value:'date'
    },

  ]
  const onSubmit = (data) => {
    if(type==='text' || type==='date'){
      data.data=null
    }
    const payload = { question: data,id:props.record.id };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled?Commons.view:Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={form} name={ formName } onFinish={ onSubmit } disabled={props.disabled} scrollToFirstError>
      <Form.Item name="question_no" rules={ rules.question_no } label={ trans(`${langKey}.question_no`)   }
          className="da-mb-16"
         
        >
          <InputText placeholder={ trans(`${langKey}.question_no`) } type="number" onWheel={(e) => e.target.blur()}/>
        </Form.Item>
        <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`)  }
          className="da-mb-16"
          initialValue={type}
        >
          <Select options={types} placeholder={ trans(`${langKey}.type`) } onChange={(value)=>setType(value)}/>
        </Form.Item>
        
{/* <Form.Item name="category" rules={ rules.category } label={ trans(`${langKey}.category`)   }
          className="da-mb-16"
         
        >
          <InputText placeholder={ trans(`${langKey}.category`) } />
        </Form.Item> */}

        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
         
        >
          <InputTextArea placeholder={ "Question" } />
        </Form.Item>

        {type==='radio' || type==='checkbox' ? 
<Form.Item name="data" rules={ rules.data } label={ trans(`${langKey}.data`)  }
          className="da-mb-16"
          
        >
          <InputText placeholder={ "op1,op2,op3,op4 etc" } />
        </Form.Item>
        :''}


        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>
        <Form.Item name="required" rules={ rules.required } label={ trans(`${langKey}.required`)  }
          className="da-mb-16"
          valuePropName="checked"
        >
          <InputCheck />
        </Form.Item>
        <Form.Item name="is_active" rules={ rules.is_active } label={ trans(`${langKey}.is_active`)  }
          className="da-mb-16"
          valuePropName="checked"
        >
          <InputCheck />
        </Form.Item>
      </Form>
    </ModalComponent>
  )
}

export default UpdateQuestion

const rules = {
  name: [
    { required: true, message:trans(`${langKey}.name`)},
  ],
  type: [
    { required: true, message:trans(`${langKey}.type`)},
  ],
  data: [
    { required: true, message:trans(`${langKey}.data`)},
  ],
  category: [
    { required: true, message:trans(`${langKey}.category`)},
  ],
  question_no: [
    { required: true, message:trans(`${langKey}.question_no`)},
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}