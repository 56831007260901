import React, { useEffect, useState } from "react";
import { Form, message, Modal, Checkbox, Skeleton, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, UploadFile, UploadButton, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import moment from "moment/moment";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["asset"].lngKey
const formName = "updateAsset";

const UpdateAsset = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [assetTypes, setAssetTypes] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    makeRequestStateless(getRelatedDataReq, null, onGetRelatedData, null);
    setFormData();
    // eslint-disable-next-line
  }, []);

  const onGetRelatedData = (response) => {
    setAssetTypes(createSelectList(response.asset_types));
    setLoading(false)
  };
  const setFormData = () => {
    if (!props) {
      return;
    }

    props.record.acquired_date = props.record.acquired_date ? moment(props.record.acquired_date) : null;
    setFileList(props.record.attachment ? [props.record.attachment] : []);
    form.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    setSaveLoader(true)
    if (data.file_attachment !== undefined && data.file_attachment !== null && data.file_attachment.url === undefined) {
      data.file_attachment.file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    data.id = props.record.id;
    const payload = { asset: data };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.assets.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  //=========================================
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ "Asset" }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="asset_type_id" rules={ rules.asset_type_id } label={ trans(`${langKey}.asset_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['asset_type_id']) } size="small"
              >
                <SelectWithSearch
                  //onChange={ onChangeAssetType }
                  placeholder={ trans(`${langKey}.asset_type_id`) }
                  options={ assetTypes }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="identification_number" rules={ rules.identification_number } label={ trans(`${langKey}.identification_number`) }
                className="da-mb-16"
                { ...getErrorProps(errors['identification_number']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.identification_number`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_taxable" rules={ rules.is_taxable } label={ trans(`${langKey}.is_taxable`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_taxable']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_new" rules={ rules.is_new } label={ trans(`${langKey}.is_new`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_new']) } size="small"
              >
                <SelectWithSearch
                  defaultValue={ true }
                  // style={ {
                  //   width: 120,
                  // } }

                  options={ [
                    {
                      value: true,
                      label: 'New',
                    },
                    {
                      value: false,
                      label: 'Old',
                    },
                  ] }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="manufacturer" rules={ rules.manufacturer } label={ trans(`${langKey}.manufacturer`) }
                className="da-mb-16"
                { ...getErrorProps(errors['manufacturer']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.manufacturer`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="model" rules={ rules.model } label={ trans(`${langKey}.model`) }
                className="da-mb-16"
                { ...getErrorProps(errors['model']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.model`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="current_value" rules={ rules.current_value } label={ trans(`${langKey}.current_value`) }
                className="da-mb-16"
                { ...getErrorProps(errors['current_value']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.current_value`) } type="number" onWheel={(e) => e.target.blur()} />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="acquired_date" rules={ rules.acquired_date } label={ trans(`${langKey}.acquired_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['acquired_date']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } rules={ rules.attachment } name="file_attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>
  )
}

export default UpdateAsset

const rules = {
  name: [
    { required: true, message: trans(Validations.name) },
  ],
  asset_type_id: [
    { required: true, message: trans(`${langKey}.asset_type_id.req`) },
  ],
  current_value: [
    { required: true, message: trans(`${langKey}.current_value.req`) },
  ],
  acquired_date: [
    { required: true, message: trans(`${langKey}.acquired_date.req`) },
  ],
  identification_number: [
    { required: true, message: trans(`${langKey}.identification_number`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}