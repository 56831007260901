import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, UploadFile } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import dayjs from 'dayjs';
const langKey = Const["newsLetter"].lngKey
const formName = "createNewsLetter";

const CreateNewsLetter = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [coverFileList, setCoverFileList] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setFormData();
    // eslint-disable-next-line
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }

    setFileList(props.record.attachment ? [props.record.attachment
    ] : []);
    setCoverFileList(props.record.cover ? [props.record.cover
    ] : []);

    const obj={
      title: props.record.title,
      date: props.record.date? dayjs(props.record.date) : null,
      expiry_date: props.record.expiry_date? dayjs(props.record.expiry_date) : null,
      file_attachment: props.record.attachment,
      file_cover: props.record.cover,
      description: props.record.description
    }

    
    form.setFieldsValue(obj);
  }

  const onSubmit = (data) => {
    setSaveLoader(true)
    data.file_attachment = fileList.length > 0 && fileList[0].id === undefined ? fileList[0].originFileObj : null
    data.file_cover = fileList.length > 0 && fileList[0].id === undefined ? fileList[0].originFileObj : null

    data.id = props.record.id;
    const payload = { news_letter: data };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.news_letters.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //=========================================
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

        <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>

        <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['date']) } size="small"
        >
          <InputDate format='YYYY-MM-DD' />
        </Form.Item>

        <Form.Item name="expiry_date" rules={ rules.expiry_date } label={ trans(`${langKey}.expiry_date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['expiry_date']) } size="small"
        >
          <InputDate format='YYYY-MM-DD' />
        </Form.Item>


        <Form.Item label={ trans(`${langKey}.attachment`) } rules={ rules.attachment } name="file_attachment" >
        <UploadFile fileList={ fileList } setFileList={ setFileList } accept='.pdf,.jpg,.png,.jpeg,.pdf'/>
        </Form.Item>
        

        <Form.Item label={ trans(`${langKey}.cover`) } rules={ rules.cover } name="file_cover" >
        <UploadFile fileList={ coverFileList } setFileList={ setCoverFileList } accept='.pdf,.jpg,.png,.jpeg,.pdf'/>
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>

      </Form>

    </ModalComponent>
  )
}

export default CreateNewsLetter

const rules = {
  title: [
    { required: true, message: trans(Validations.title) },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date.req`) },
  ],
  expiry_date: [
    { required: true, message: trans(`${langKey}.expiry_date.req`) },
  ],


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}