import React, { useEffect, useState } from "react";
import { Checkbox, Form, message, Row, Col, Skeleton } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, SelectResourceList, AddNewButton } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps ,removeById} from "@utils/helpers";
import { createSalaryReq, getBankByEmploymentIdReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import CreateBank from '../../bank/components/CreateBank';

const langKey = Const["salary"].lngKey

const formName = "createSalary";

const CreateSalary = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [payrolls, setPayrolls] = useState([]);
  const [paymentmethods, setPaymentmethods] = useState([]);
  const [currencies, setcurrencies] = useState([]);
  const [banks, setbanks] = useState([]);
  const [wages, setWages] = useState([]);
  const [istype, setIstype] = useState('');
  const [paymentMethodIds, setpaymentMethodIds] = useState([2]);
  const [childComponent, setChildComponent] = useState(null)
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [splitable,setSplitable] = useState(false);

  //const [salaryTypes, setsalaryTypes] = useState([]);

  useEffect(() => {
    makeRequestStateless(getRelatedDataReq, false, ongetRelatedDataReq, false)
    // makeRequestStateless(getSalaryTypesReq, false, ongetSalaryTypesReq, false)
    form.setFieldValue('employment_id', props.filters !== undefined ? props.filters.replace('employees_|', '') : null)
    // eslint-disable-next-line
  }, []);

  const ongetRelatedDataReq = (response) => {
    setcurrencies(createSelectList(response.currencies))
    setPaymentmethods(createSelectList(removeById(response.payment_methods,3)))
    setPayrolls(createSelectList(response.payrolls))
    setWages(createSelectList(response.employee_wages))
    setLoading(false)
  }

  const salaryTypes = [
    {
      value: 'monthly',
      label: 'Monthly',
    },
    {
      value: 'daily',
      label: 'Daily Wages',
    },
    {
      value: 'hourly',
      label: 'Hourly',
    },
  ]
  const onSubmit = (data) => {
    data.payment_method_id=2;
    if(paymentMethodIds.length>1){
      let splitSum=Number(data.bank_salary)+Number(data.cash_salary);
      if(splitSum!=data.gross_salary){
        message.error('Bank Salary and Cash Salary must be equal to Gross Salary');
        return;
      }
    }else{
      data.payment_method_id=paymentMethodIds[0];
    }
    const payload = { salary: data, filters: props.filters }
    makeRequest(setLoader, createSalaryReq, payload, onSuccess, onError);
   setsaving(true)

  }

  const onSuccess = (data, res) => {
    setsaving(false)
    message.success("Salary Saved successfully");
    setLoader(true)
    props.onCompleted(data.salaries.data);
    props.onSuccess(data)

  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  //when bank created
  const onCompleted = (data) => {
    if (data.length) {
      setbanks(createSelectList(data));
      onChangeEmployment(form.getFieldValue('employment_id'));
    }
    setChildComponent(null);
  };
  //-----------on changes------------
  const onChangeEmployment = (id) => {
    makeRequestStateless(getBankByEmploymentIdReq, id, ongetBankByEmploymentIdReq, false)
  }
  const ongetBankByEmploymentIdReq = (response) => {
    
    setbanks(createSelectList(response.banks))
    if(response.banks.length>0){
      form.setFieldValue('bank_id',response.banks[0].id)
    }else{
      form.setFieldValue('bank_id',null)
    }
  }
  const onChangeType = (value) => {
    setIstype(value);
  }
  const onChangePaymentMethod = (value) => {
    
    setpaymentMethodIds(value);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.salary`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" label={ trans(`${langKey}.employment_id`) } rules={ rules.employment_id } className="da-mb-16"
                { ...getErrorProps(errors['employment_id']) }
              >
                <SelectResourceList type='employment' onChange={onChangeEmployment}/>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payroll_id" label={ trans(`${langKey}.payroll_id`) } rules={ rules.payroll_id } className="da-mb-16"
                { ...getErrorProps(errors['payroll_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.payroll_id`) }
                  options={ payrolls }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="type" label={ trans(`${langKey}.type`) } rules={ rules.type } className="da-mb-16"
                { ...getErrorProps(errors['type']) }
                initialValue={'monthly'}
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.type`) }
                  options={ salaryTypes }
                  onChange={ onChangeType }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payment_method_id" label={ trans(`${langKey}.payment_method_id`) } rules={ rules.payment_method } className="da-mb-16"
                { ...getErrorProps(errors['payment_method_id']) }
               
              >
                <SelectWithSearch
                  
                  placeholder={ trans(`${langKey}.payment_method_id`) }
                  options={ paymentmethods }
                  onChange={ onChangePaymentMethod }
                  mode='multiple'
                />
              </Form.Item>
            </Col>
            

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="currency_id" label={ trans(`${langKey}.currency_id`) } rules={ rules.currency_id } className="da-mb-16"
                { ...getErrorProps(errors['currency_id']) }
                initialValue={160}
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.currency_id`) }
                  options={ currencies }
                />
              </Form.Item>
            </Col>

            { paymentMethodIds[0] === 1 || paymentMethodIds.length>1 ? <Col lg={ 12 } sm={ 24 } xs={ 24 }>
             {banks.length===0 && <AddNewButton onClick={ () => setChildComponent(<CreateBank onCompleted={ onCompleted }/>) } />} 
              <Form.Item name="bank_id" label={ trans(`${langKey}.bank_id`) } rules={ rules.bank_id } className="da-mb-16"
                { ...getErrorProps(errors['bank_id']) }
              >
                <SelectWithSearch options={ banks } placeholder={ trans(`${langKey}.bank_id`) } disabled ={banks.length>0}/>
              </Form.Item>
            </Col>:'' }
            
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="ntn_no" rules={ rules.ntn_no } label={ trans(`${langKey}.ntn_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['ntn_no']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.ntn_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="gross_salary" rules={ rules.gross_salary } label={ trans(`${langKey}.gross_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['gross_salary']) } size="small"
               
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.gross_salary`) } />
              </Form.Item>
            </Col>
           
           {
            paymentMethodIds.length>1 && <>
             <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="cash_salary" rules={ rules.cash_salary } label={ trans(`${langKey}.cash_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['cash_salary']) } size="small"
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.cash_salary`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_salary" rules={ rules.bank_salary } label={ trans(`${langKey}.bank_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_salary']) } size="small"
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.bank_salary`) } />
              </Form.Item>
            </Col>
            </>
           }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_tax_exempted" valuePropName="checked" rules={ rules.is_tax_exempted } label={ trans(`${langKey}.is_tax_exempted`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_tax_exempted']) } size="small"
              >
                <Checkbox placeholder="" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>

          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateSalary

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  payroll_id: [
    { required: true, message: trans(`${langKey}.payroll_id`) },
  ],
  currency_id: [
    { required: true, message: trans(`${langKey}.currency_id`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  payment_method: [
    { required: true, message: trans(`${langKey}.payment_method`) },
  ],
  bank_id: [
    { required: true, message: trans(`${langKey}.bank_id`) },
  ],
  gross_salary: [
    { required: true, message: trans(`${langKey}.gross_salary`) },
  ],
  bank_salary: [
    { required: true, message: trans(`${langKey}.bank_salary`) },
  ],
  cash_salary: [
    { required: true, message: trans(`${langKey}.cash_salary`) },
  ],
  amount_per_hour: [
    { required: true, message: trans(`${langKey}.amount_per_hour`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}