import React, { useEffect, useState } from "react";
import { Form, message, Modal, Button, Divider, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate, UploadFile, UploadButton, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmploymentsReq, validateLendTypeReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';

import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import AddGrauntor from "./AddGrauntor";

const langKey = Const["companyLends"].lngKey
const formName = "createLendType";

const CreateCompanyLend = (props) => {

  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [childComponent, setChildComponent] = useState(false)
  const [employments, setEmployments] = useState([])
  const [lendTypes, setLendTypes] = useState([]);
  const [lendCategories, setLendCategories] = useState([]);
  const [relationShips, setRelationShips] = useState([])
  const [gaurantorTypes, setGaurantorTypes] = useState({})
  const [employmentId, setEmploymentId] = useState(null)
  const [gaurantorRequired, setGraunterRequired] = useState(false)
  const [gaurantors, setGaurantors] = useState([]);
  const [deltedGaurantors, setDeletedGaurantors] = useState([]);
  const [form] = Form.useForm()

  useEffect(() => {
    getEmployments()
    setRelatedData()
    // eslint-disable-next-line
  }, []);

  const setRelatedData = () => {
    setLendTypes(createSelectList(props.allData.lend_types));
    setLendCategories(createSelectList(props.allData.lend_categories));
    setGaurantorTypes(props.allData.guarantor_types)
    setRelationShips(createSelectList(props.allData.relationships))
  }

  const getEmployments = () => {
    const payload = { filters: '' }
    makeRequestStateless(getEmploymentsReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setEmployments(createSelectList(response.resources.data))
    setLoading(false)
  }

  const onChangeLendType = (id) => {

    const payload = {
      lend: {
        currency_id: 239,
        behalf_employment_id: authUser.employment_id,
        is_on_behalf: true,
        employment_id:props.source === 'my-requests' ? props.authUser.employment_id : employmentId,
        lend_type_id: id
      }
    }

    if (id) {
      makeRequest(setLoader, validateLendTypeReq, payload, onValidateLendTypeSuccess, onValidateLendTypeError);
    }

  }
  const onValidateLendTypeSuccess = (response) => {
    setGraunterRequired(response.lend_type.is_guarantor_required)
  }
  const onValidateLendTypeError = (err, res) => {
    message.error(res.response.data.message)
  }

  const onSubmit = (data) => {
    if (gaurantorRequired && data.guarantors === undefined) {
      message.info('Please Add Guarantors')
      return
    }
    let newGuarantors = []
    data.guarantors !== undefined && data.guarantors.forEach((each) => {
      each.known_since = dayjs(each.known_since).format('YYYY-MM-DD')
      newGuarantors.push(each)
    }
    )
    data.guarantors = newGuarantors

    if (data.attachment !== undefined && data.attachment !== null && data.attachment.url === undefined) {
      data.attachment.file = data.attachment.fileList.length > 0 ? data.attachment.file : null
    }

    data.employment_id = props.source === 'my-requests' ? props.authUser.employment_id : data.employment_id;

    data.effective_date = data.effective_date.format('YYYY-MM-DD');
    data.is_on_behalf = true;
    data.behalf_employment_id = props.authUser.employment_id;

    const payload = { lend: data  ,
      filters: props.filters};
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    setsaving(false)
    message.success("Saved successfully");
    props.onCompleted(data.lends.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }


  //==============add grauntor =================
  const onAddGrauntor = (key) => {
    let record = null

    if (key !== null) {
      record = { ...form.getFieldValue(`guarantors`)[key], key: key }
    }
    setChildComponent(<AddGrauntor relatedData={ { relationShips: relationShips, types: gaurantorTypes } } onCompleted={ onCompleted } record={ record } />)

  }
  //on submit gaurantors
  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('guarantors');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('guarantors', listArray)

    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('guarantors', listArray)

    }
    setChildComponent(null)
  }
  //-----------------------

  //set deleted rows
  const onDeleteRow = (key, state) => {
    if (gaurantors[key] !== undefined && gaurantors[key].id !== undefined && state === 'Deleted') {
      gaurantors[key].row_state = 'Deleted'
      setDeletedGaurantors.push(gaurantors[key])
    }
  }
  //-----------------------
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
        <Row gutter={ 30 }>
          {props.source!=='my-requests' ?  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectResourceList type='employment'
                onChange={ (id) => setEmploymentId(id) }
              />
            </Form.Item>
          </Col>:''}
        
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="lend_type_id" rules={ rules.lend_type_id } label={ trans(`${langKey}.lend_type_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                onChange={ onChangeLendType }
                options={ lendTypes }
                placeholder={ trans(`${langKey}.lend_type_id`) }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="lend_category_id" rules={ rules.lend_category_id } label={ trans(`${langKey}.lend_category_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ lendCategories }
                placeholder={ trans(`${langKey}.lend_category_id`) }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="amount" rules={ rules.amount } label={ trans(`${langKey}.amount`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="effective_date" rules={ rules.effective_date } label={ trans(`${langKey}.effective_date`) }
              className="da-mb-16"
            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.extension_no`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.attachment`) } name="experience_letter" >
              <UploadFile
                accept=".jpg,.png,.jpeg"
                // listType="picture-card"
                fileList={ fileList }
                onChange={ onChange }
                onPreview={ onPreview }
                multiple={ false }
                beforeUpload={ (file) => {
                  return false;
                } }
              >
                { fileList.length === 1 ? null : <UploadButton /> }
              </UploadFile>
            </Form.Item>
          </Col>

        </Row>

        { gaurantorRequired &&
          <>
            <Divider>Gaurantor</Divider>
            <Button onClick={ () => onAddGrauntor(null) } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add Node</Button>
          </>
        }

        <Form.List name="guarantors">
          { (fields, { add, remove }) => (
            <>

              <div className="table-container">
                <table className="table">
                  {
                    fields.length !== 0 && <thead>
                      <tr>
                        <th>{ trans(`${langKey}.name`) }</th>
                        <th>{ trans(`${langKey}.cnic`) }</th>

                      </tr>
                    </thead>
                  }
                  { fields.map(({ key, name, ...restField }) => (
                    <>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'name'] }
                              rules={ rules.name }
                              initialValue={ key + 1 }
                            >
                              <InputText placeholder={ trans(`${langKey}.name`) } disabled={ true } />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'cnic'] }
                              rules={ rules.name }

                            >
                              <InputText placeholder={ trans(`${langKey}.cnic`) } disabled={ true } />
                            </Form.Item>
                          </td>
                          <td>
                            <DeleteOutlined onClick={ () => {
                              remove(name)
                              //onDeleteRow(key, 'Deleted')
                            } } />
                            <EditOutlined onClick={ () => onAddGrauntor(key) } />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )) }
                </table>
              </div>
            </>
          ) }
        </Form.List>



      </Form>
      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>
  )
}

export default CreateCompanyLend

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  effective_date: [
    { required: true, message: trans(`${langKey}.effective_date`) },
  ],
  lend_category_id: [
    { required: true, message: trans(`${langKey}.lend_category_id`) },
  ],
  lend_type_id: [
    { required: true, message: trans(`${langKey}.lend_type_id`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

//onchange lend type
/*companyLends/validate?
lend[currency_id]: 239
lend[behalf_employment_id]: 112e0764-2d34-4a4b-b73e-b76c8bb0b52c
lend[is_on_behalf]: true
lend[employment_id]: b449cdc0-0f11-4821-8ac1-7905f3a24c7d
lend[lend_type_id]: 2

//on submit create

attachment: (binary)
lend: {"currency_id":239,"behalf_employment_id":"112e0764-2d34-4a4b-b73e-b76c8bb0b52c","is_on_behalf":true,"employment_id":"b449cdc0-0f11-4821-8ac1-7905f3a24c7d","lend_type_id":1,"non_payable":false,"amount":10000,"effective_date":"2023-10-13T00:00:00.000Z","reason":"for testing","extension_no":"123","attachment":{"removed":false,"org_name":"mycirclephoto.png","key":"attachment"},"lend_category_id":1,"guarantors":[{"row_state":"","index":0,"name":"test","relationship_id":5,"guarantor_type":"primary","known_since":"2023-10-13T00:00:00.000Z","cnic":"3457234523225"}]}
*/

//with grauntor
// lend: { "currency_id": 160, "behalf_employment_id": "d46dfabc-2966-42ca-a990-c7bfa0ef4343", "is_on_behalf": true, "employment_id": "f11a22fa-1cae-40f3-aa3a-ce9e6508ee24", "lend_type_id": 2, "non_payable": false, "amount": 4000, "effective_date": "2023-11-24T00:00:00.000Z", "reason": "rgs", "lend_category_id": 1, "guarantors": [{ "row_state": "", "index": 0, "name": "gr1", "cnic": "7894674624343", "known_since": "2023-09-11T00:00:00.000Z", "relationship_id": 5, "guarantor_type": "primary" }] }