import { useState, useEffect, useCallback } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent
} from "@comps/components";
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { Button, Form, Input, message ,Tooltip} from 'antd';
import { FilterComponent, PaginationComponent } from "@comps/components";
import { deleteDataReq, downloadReportReq, getAllDataReq, getFilterReq, getInitialDataReq, getReportsFromJobsReq } from "./requests";
import { useSelector } from "react-redux";
import dayjs from 'dayjs'
import CreateRawReport from "./components/CreateRawReport";
import UpdateRawReport from "./components/UpdateRawReport";
import CreateCustomReport from "./components/CreateCustomReport";
import { CopyOutlined, FilterTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ViewReports from "./components/ViewReports";
import {  Download } from "@mui/icons-material";
import { encryptLocalData } from '@comps/commonFunctions';
import _ from "lodash";
import CopyReportToSaas from "./components/CopyReportToSaas";
import CreatePredefinedReport from "./components/CreatePredefinedReport";

const langKey = Const["allReports"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["allReports"].route
      }
    ]
  }
}

var currentReport = null;
var filters='';

const IndexAllReports = (props) => {
  const navigate = useNavigate()
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [allData, setAllData] = useState({});
  const [reportJobs,setReportjobs]=useState([]);
  const [downloadsComponent,setDownloadsComponent]=useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchString,setSearchString]=useState();
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [

    {
      key: 'title',
      title: trans(`${langKey}.title`),
      render: (record) => {
        return (
          <Tooltip title="Generate Report">
          <span className="da-px-10 da-my-0 mt-2" onClick={() => generateReport(record, record.is_pre_defined)} style={{cursor:'pointer',textDecoration:'none'}} ><u>{record.title}</u> </span>
          </Tooltip>
        )
      }
    },
    // {
    //   key: 'class_name',
    //   title: trans(`${langKey}.class_name`),
    //   dataIndex: 'class_name'
    // },
    {
      key: 'type',
      title: trans(`${langKey}.report_type_name`),
      render: (record) => (
        record.report_type.name
      )
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => {
        return (
          <>
          
              <ActionComponent
                each={ record }
                onEdit={ record.is_raw_query ? onEdit : '' }
                //onView={ () => generateReport(record, record.is_pre_defined) }
                onDelete={ !record.is_pre_defined && onDelete }
              >
                <Tooltip title="Generate or Download">
                <Download className="da-px-10 da-my-0 mt-2 text-primary" onClick={() => generateReport(record, record.is_pre_defined)} style={{cursor:'pointer'}} />
                </Tooltip>
                
                {
                  authUser.super_admin && <Tooltip title="Clone Report">
                  <CopyOutlined className="da-px-10 da-my-0 mt-2 text-primary ms-2" style={{cursor:'pointer'}} onClick={()=>onCopyReport(record)}/>
                  </Tooltip>
                }
             
              </ActionComponent>
            
          </>
        )
      },
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    getDownloads();
    // eslint-disable-next-line
  }, [props, currentPage]);

  const getAllData = () => {
    setChildComponent(null)
    const payload = {
      page: currentPage,
      filters: searchString
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setAllData(response);
    setPagination(response.reports)
    setDataSource(response.reports.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  //update raw report
  const onEdit = (record) => {
    setChildComponent(<UpdateRawReport onCompleted={ onCompleted } record={ record } disabled={ false } />);
  };
  const onCopyReport = (record) => {
    setChildComponent(<CopyReportToSaas onCompleted={ onCompleted } record={ record } disabled={ false } />);
  };
  const onCreatePredefinedReport = () => {
    setChildComponent(<CreatePredefinedReport onCompleted={ onCompleted } disabled={ false } />);
  };
  //view 
  // const onViewRawReport = (record) => {
  //   setChildComponent(<UpdateRawReport onCompleted={ onCompleted } record={ record } disabled={ true } />);
  // };

  // const onView = (record) => {
  //   // setChildComponent(<UpdateAttendanceLogs onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  // };

  const onDelete = (record) => {
    const payload = {
      filters: `employee_|${record.employment_id}`, id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.reports.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreateCustomReport = () => {
    setChildComponent(<CreateCustomReport onCompleted={ onCompleted } />);
  };
  const onCreateRawReport = () => {
    setChildComponent(<CreateRawReport onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    // if (data && data.length) {
    //   setDataSource(data);
    // }
    if(data){
      getAllData();
    }

    setChildComponent(null);
  };


  function isValidJSON(str) {
    if (typeof str !== "string") return false;

    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

  //generate report 
  const generateReport = (record, isPredefined) => {
    
    if(!currentReport){
      filters='';
      encryptLocalData('currentFilterOptions',null)
    }
    if(currentReport && currentReport.id!==record.id){
      filters='';
      encryptLocalData('currentFilterOptions',null)
    }

    currentReport = record
    const payload = {
      id: record.id
    }
    if (isPredefined) {
      makeRequestStateless(getInitialDataReq, payload, onGetInitialData, null);
    }
    else {
      navigate('generate/' + record.id);
    }
  }
  const onGetInitialData = (data) => {

    let filterFields = []
    let directiveFilters = ''
    let tempGroup = ''

    data.report.directive_filters.forEach((each, key) => {
      let urlDataKey = each.name
      let optionDataKey = each.name

      if (each.name === 'employees' || each.name === 'employee') {
        urlDataKey = 'employments'
        optionDataKey = urlDataKey
      }

      if (each.group !== tempGroup) {
        directiveFilters = directiveFilters + `${each.group}_|${urlDataKey};`
      }
      else {
        let lastIndex = directiveFilters.lastIndexOf(";");
        if (lastIndex !== -1) {
          // Check if the last character is a semicolon
          if (lastIndex === directiveFilters.length - 1) {
            // Remove the last semicolon
            directiveFilters = directiveFilters.slice(0, lastIndex);
          }
        }

        directiveFilters = directiveFilters + `,${urlDataKey};`
      }
      tempGroup = each.group;

      let fieldType = "select";
      let dataKey = each.group.replace('fields', 'data')
      if (each.group === "date_time_fields") {
        fieldType = 'date'
      }

     
      if(each.config && isValidJSON(each.config)){
        each.config=JSON.parse(each.config);
      }
      console.log(each.config.required);
      filterFields.push(
        {
          type: fieldType,
          placeholder: capitalizeString(each.name),
          key: each.name,
          data_key: `${dataKey}.${optionDataKey}`,
          required: each.config.required,
          resource_type: 'employment'
        },
      )
    })

    const apiPayload = {
      page: 1,
      filters: arrangeItems(directiveFilters)
    }

    onOpenFilter(filterFields, apiPayload)

  }
  function capitalizeString(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  const onOpenFilter = (filterFields, apiPayload) => {

    setFilterComponent(<FilterComponent identity={ currentReport.id } filters={ filterFields } selected={ filters } api={ () => getFilterReq(apiPayload) } onFilter={ onApplyFilter } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const setFilters=(fill)=>{
    filters=fill;
  }
  const onApplyFilter = (filters) => {
    setFilters(filters)
    const payload = {
      page: 1,
      filters: `${filters}report_id_|${currentReport.id},user|${authUser.employment_id}`,
      employment_id:authUser.employment_id
    }
    setChildComponent(null)
      makeRequest(setLoader, downloadReportReq, payload, onDownloadSuccess, onDownloadSuccess);
    
  }

  const onDownloadSuccess = (err, res) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `${currentReport.title}_${dayjs().format('YYYY-MM-DD')}.${fileExtension}`
      //handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  const onCloseFilter = () => {
    setFilterComponent(null);
  }

  //arrange directive filters to get data 
  function arrangeItems(input) {

    // Check if the input is not an empty string
    if (!input.trim()) {
      return "Input string is empty.";
    }

    // Split the input string into groups
    const groups = input.split(';');

    // Create an object to store items based on their field type
    const groupedItems = {};
    // Process each group
    groups.forEach(group => {
      // Split the group into field type and items
      const [fieldType, items] = group.split('_|');
      // If the field type or items are undefined, skip this iteration
      if (!fieldType || !items) {
        return;
      }
      // If the field type is not in the groupedItems object, create an array for it
      if (!groupedItems[fieldType]) {
        groupedItems[fieldType] = [];
      }
      // Add items to the corresponding field type array
      groupedItems[fieldType] = groupedItems[fieldType].concat(items.split(','));
    });
    // Create an array to store the final result
    const result = [];
    // Arrange items within each group based on your specific order
    Object.keys(groupedItems).forEach(fieldType => {
      // Sort items based on the desired order
      const sortedItems = groupedItems[fieldType].sort();
      // Add the formatted string to the result array
      result.push(`${fieldType}_|${sortedItems.join(',')}`);
    });
    // Join the result array into a string
    const finalResult = result.join(';');
    return finalResult;
  }
  //----end filter--------------------
  const getDownloads=()=>{
    makeRequest(setLoader, getReportsFromJobsReq, null, onSuccessReports, onErrorReports);
  }
  
  const onSuccessReports=(data,res)=>{
  
  setReportjobs(data)
  }
  const onErrorReports=(err)=>{
    
  }
  // const viewDownloads=()=>{
  //   setDownloadsComponent(<ViewReports data={reportJobs} onCompleted={ onCompleted }/>)
  // }

  const onSearchReport=(value)=>{
    debouncedGetReports(value)
  }
  
  const debouncedGetReports = useCallback(
    _.debounce((value) => {
      let fil = `title_|${value}`;
      onSearchString(fil);
    }, 500),
    [] // Empty array ensures the function is created only once
  );

  const onSearchString = (fil) => {
    setChildComponent(null)
    const payload = {
      page: 1,
      filters: fil
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent pagination={ pagination } setCurrentPage={ setCurrentPage } setTotalRecords={ setTotalRecords } currentPage={ currentPage } filters={ filters }>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

          <RefreshButton onClick={ getAllData } />
          <CreateButton onClick={ onCreateCustomReport } text='Create Custom Report' />
          <CreateButton onClick={ onCreateRawReport } text='Create Raw Report' />
          {authUser.super_admin || process.env.REACT_APP_ENV === "local"? <CreateButton onClick={ onCreatePredefinedReport } text='Create Predefined Report' />:''}

          {/* <Popover content={ downloadsComponent } title="Downloads" trigger="click" placement="right" onClick={()=>viewDownloads()}>
          <ButtonComponent text={`Downloads(${reportJobs.length})`} />
        </Popover> */}
          {/* <FilterButton onClick={ onOpenFilter } /> */ }
        </HeaderComponent>
       <Form { ...formLayout } onFinish={ onSearchReport }>
        <Form.Item name="name"
              className="da-mb-16" style={{marginRight:'60%'}}>
        <Input placeholder="Search By name" onChange={(e)=>onSearchReport(e.target.value)}/>
        </Form.Item>
       
       </Form>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
        //pagination={ { ...pagination, total: totalRecords } }
        //onChange={ handleTableChange }
        />

      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexAllReports;


const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}