import { get, del, post, put } from "@utils/axios";

const apiMy = "attendances";
export const getAllDataReq = (payload) => {
  return get(`${apiMy}?page=${payload.page}&filters=${payload.filters}`)
};

export const getDataById = (payload) => {
  return get(`${apiMy}/${payload.id}`)
};

export const createDataReq = (payload) => {
  return post(`${apiMy}?page=1`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${apiMy}/${payload.id}&filters=${payload.filters}`, payload);
};
export const updateBulkDataReq = (payload) => {
  return post(`${apiMy}&filters=${payload.filters}`, payload);
};

export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${apiMy}/${payload.id}?page=1&filters=${payload.filters}`);
};
export const deleteShiftAttendanceReq = (payload) => {
  return post(`${apiMy}/delete-shift-attenance`,payload);
};
export const getRosterReq = (payload) => {
  return post(`${apiMy}/getRoster`, payload);
};
export const getExpectedTimeReq = (payload) => {
  return get(`${apiMy}/getExpectedTime?&attendance_date=${payload.date}&employment_id=${payload.employment_id}`);
};


export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|attendance_status,employments,request_status;organization_structure_fields_|location,branch,department;`)
}
export const onDateChange = (payload) => {
  return post(`${apiMy}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${apiMy}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${apiMy}/${payload}`)
};

//get department 

export const getDepartmentHierarchyReq = (payload) => {
  return get(`directives/departmentHierarchy?`)
};
