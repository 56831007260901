import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Popconfirm, Skeleton, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../constants";
import moment from "moment/moment";
import { approveCancelLeaveRequestReq, getLeaveRequestReq } from "../leave/approvals/requests";
import RoasterView from "@mods/common/RoasterView";

const { TextArea } = Input;
const langKey = Const["leaveApproval"].lngKey
const formName = "updateLeaveApproval";

const ApproveCancelRequests = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [preRequestData, setPreData] = useState({})
  const [roasterView, setRosterView] = useState(null)
  const [preRoster, setPreRoster] = useState({})
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('')
  const [form] = Form.useForm();
  const [consents, setConsents] = useState([]);

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getLeaveRequest()
  }, []);

  const getLeaveRequest = () => {
    setConsents(props.record.consents)
    let payload = props.record.id
    if (props.record.hasOwnProperty('requestable')) {

      payload = props.record.requestable_id
    }

    makeRequest(setLoader, getLeaveRequestReq, payload, onGetRequestDataSuccess, onGetRequestDataSuccess);
  }
  const onGetRequestDataSuccess = (data, response) => {
    setLoading(false)
    setPreData(response)
    response.leave_request.start = moment(response.leave_request.start)
    response.leave_request.end = moment(response.leave_request.end)
    response.leave_request.created_at = moment(new Date(response.leave_request.created_at)).format('YYYY-MM-DD')
    form.setFieldsValue(response.leave_request)
    form.setFieldsValue({ leave_type_available: response.leave_type_available, leave_type_total: response.leave_type_total, leave_type_availed: response.leave_type_availed })
    showRoster(response.roster)
    setPreRoster(response.roster)
  }
  const onGetRequestDataError = (data, response) => {

  }

  const onSubmit = (isApproval) => {
    //setSaveLoader(true)
    let obj = props.record;
    obj.is_approval = isApproval;
    obj.delegator_id = null;
    obj.request_as = props.requestAsId;
    obj.remarks = remarks;
    const payload = { request: obj, filters: "cancel_request_type_|leave_request;" }
    makeRequest(setLoader, approveCancelLeaveRequestReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Record Updated");
    props.onCompleted(data.cancel_requests.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const showRoster = (data) => {
    setRosterView(<RoasterView record={ data } />)
  }


  //-----------------------

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <Popconfirm onConfirm={ () => onSubmit(true) }>
      <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" text='Approve' />
    </Popconfirm>
    ,
    <Popconfirm onConfirm={ () => onSubmit(true) }>
      <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } /> </Popconfirm>,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>
          <Divider>Leave Request</Divider>
          <Form.Item name="leave_type_name" rules={ rules.leave_type_id } label={ trans(`${langKey}.leave_type_id`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputText />
          </Form.Item>

          <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputDate />
          </Form.Item>

          <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputDate />
          </Form.Item>
          { roasterView }
          <Form.Item name="count" rules={ rules.count } label={ trans(`${langKey}.count`) }
            className="da-mb-16"
            { ...getErrorProps(errors['count']) } size="small"
          >
            <InputText type="number" onWheel={(e) => e.target.blur()} disabled={ true } />
          </Form.Item>

          <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
            className="da-mb-16"
            { ...getErrorProps(errors['reason']) } size="small"
          >
            <TextArea />
          </Form.Item>

          <Form.Item name="created_at" rules={ rules.created_at } label={ trans(`${langKey}.created_at`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputText />
          </Form.Item>


          <Divider>Cancel Request</Divider>
          <Form.Item name="reasons" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
            className="da-mb-16"
            { ...getErrorProps(errors['reason']) } size="small"
            initialValue={ props.record.description }
          >
            <TextArea />
          </Form.Item>
          <Divider>Approval</Divider>
          <Form.Item name="description" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
            className="da-mb-16"
            { ...getErrorProps(errors['last_remarks']) } size="small"
          >
            <TextArea disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
          </Form.Item>



        </Form>


      </Skeleton>

    </ModalComponent>
  )
}

export default ApproveCancelRequests

const rules = {
  name: [
    { required: true, message: '' },
  ],
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}