import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getNewsLettersReq } from '../requests';
import "../dashboard.css"
import moment from 'moment/moment';
import useInViewport from '../useInViewport';
import { UploadFile } from '../../../wrappers/components';
import { DownloadOutlined } from '@ant-design/icons';

const NewsLetters = () => {
  const [loader, setLoader] = useState(false);
  const [news, setNews] = useState([]);

  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && news.length === 0) {
      makeRequest(setLoader, getNewsLettersReq, null, onSuccess, onError)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    
    setNews(data.news_letters);
  }

  const onError = (error) => {
  }

  return (
    <div className='widgetbox' ref={viewRef}>
      <Row >
        <Col span={ 24 } >
          <h3><span>News Letter</span></h3>
        </Col>
      </Row>
      <div className='newsLetterContent'>
        <div className='birthdaymain'>
          <Spin spinning={ loader } tip="Loading...">
            { news && news.map((letter, index) => (
              <Row className='' key={ index }>
                <Col span={ 12 }>
                  <div className=''>
                    <h6 className='newsTitle'>{ letter.title }</h6>
                    <p className='text-primary'><i>{ moment(letter.date).format('DD MMMM YYYY') }</i></p>
                    <p className=''>{ letter.description }</p>
                    {letter.attachment &&  <dv><DownloadOutlined className='text-success me-1'/><a href={letter.attachment.url} target='_blank' rel='noopener noreferrer'>Attachment</a></dv>}
                   
                    {/* <p className='text-danger'><i>Will be expired at {moment(letter.expiry_date).format('DD MMMM YYYY')}</i></p> */}
                   <hr/>
                   
                  </div>
                </Col>
                <Col span={ 12 } style={ { textAlign: 'right' } }>
                  {/* <h4 className='birthdaydate'>{ moment(birthday.d_o_b).format('MMMM DD') }</h4> */}
                </Col>
              </Row>
            )) }
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default NewsLetters;