import { useEffect, useState } from "react";
import { Form, Divider, Row, Col, Collapse, Checkbox, Skeleton, Button, message,Popconfirm } from "antd";
import { ModalComponent, SaveButton, CancelButton, trans, InputText, InputTextArea, InputDate,TableWithFilter,TableComponent,SelectResourceList} from "@comps/components"
import { Commons, Const } from "../../constants"
import { getPreviewReq } from "@mods/common/requests";
import { makeRequest } from "@utils/helpers";
import dayjs from 'dayjs'
import ViewPolicySlabList from "@mods/common/ViewPolicySlabList";
import { addPolicyEmployeesReq, deletePolicyEmployeeReq } from "../requests";
import { DeleteOutline } from "@mui/icons-material";
const ViewAttendancePolicyPlan = (props) => {
  const langKey = Const["attendancePolicyPlanner"].lngKey
  const langKeyTravelPolicy = Const["travelPolicy"].lngKey
  const formName = "createMyRoaster";
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [record, setRecord] = useState()
  const [employees, setEmployees] = useState([])
  const [activeItem, setActiveItem] = useState(null); // State to track the active accordion item
  const [items, setItems] = useState([])
  const [form] = Form.useForm()
  const [employmentForm] = Form.useForm()
  const [employmentIds,setEmploymentIds]=useState([]);
  const [policyId,setPolicyId] = useState([]);

  useEffect(() => {
    getPreview()
  }, [props])


  const handleItemClick = (item) => {

    setActiveItem(item);
  };

  const getPreview = () => {
    let payload = { moduleName: 'policyPlaners', policy_planer: props.record };
    makeRequest(setLoader, getPreviewReq, payload, setData, null);
  }

  const setData = (response) => {
    setLoading(false)
    setEmployees(response.policy_planer.employments)
    setRecord(response.policy_planer)
    setPolicyId(response.policy_planer.id)
    form.setFieldsValue({
      name: response.policy_planer.name,
      date: response.policy_planer.date ? dayjs(response.policy_planer.date) : null,
      for_all: response.policy_planer.for_all
    })

    let data = response.policy_planer;
    let tempItems=[];
    Object.keys(data).forEach((each, key) => {
      if (typeof data[each] === 'object' && data[each] && data[each].id !== undefined && data[each].name !== undefined) {

        tempItems.push(
          {
            key: key,
            label: toTitleCase(each),
            children: getChildren(data, each)
          }
        )
      }
    });
    setItems(tempItems)
  }
  const getChildren = (data, each) => {
    const slabFields = [

    ]

    if (data[each].slabs !== undefined && data[each].slabs.length > 0) {
      slabFields.push(
        {
          name: 'start_mins',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'start_mins',
        },
        {
          name: 'end_mins',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'end_mins',
        },
        {
          name: 'min_times',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'min_times',
        },
        {
          name: 'max_times',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'max_times',
        },
        {
          name: 'repeat_name',
          label: 'Text Field',
          type: 'text',
          rules: '',
          langkey: 'repeat_name',
        },
      )
    }

    return (
      <>
        <Form { ...formLayout } name={ formName } disabled={ props.disabled } initialValues={ data[each] }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"

              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"

              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

          <Divider> { trans(Commons.slabs) }</Divider>

          { data[each].slabs !== undefined && data[each].slabs.length > 0 &&
            <ViewPolicySlabList fields={ slabFields } disabled={ props.disabled } type='planner' />
          }


        </Form>
      </>
    )

  }
  function toTitleCase(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const onChangeEmployee=(ids)=>{
    
    setEmploymentIds(ids);
  }

  const handleDeleteEmployee=(empId)=>{
    const payload={
        policy_id:policyId,employment_id:empId
    }
    makeRequest(setLoader, deletePolicyEmployeeReq, payload, onSuccessDeleted, onError);
  }
  const onAddSelectedEmployees=()=>{

    if(employmentIds.length<1){
      message.warning("Please select at least one employee");
      return;
    }
   const payload={
    policy_planer:{
    policy_id:policyId,employment_ids:employmentIds
  }
  }
  makeRequest(setLoader, addPolicyEmployeesReq, payload, onSuccessAdded, onError);
  }
  const onSuccessAdded = (response) => {
  
    message.success("Employees added successfully");
    employmentForm.setFieldValue("employment_ids",null);
    getPreview()
  };
  const onSuccessDeleted = (response) => {
  
    message.success("Deleted successfully");
    employmentForm.setFieldValue("employment_ids",null);
    getPreview()
  };

  const onError = (err, res) => {
    
    message.error(res.response.data.message);
    //setErrors(errorList);
  }



  const footer = [
    props.disabled ? '' :
      <SaveButton form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  //=============
  return (
    <>
      <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
        mainTitle={ props.addEmployees?"Add Employees":Commons.view } subTitle={ trans(`${langKey}.moduleName`) }
        visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
        <Skeleton loading={ loading }>
          <Form { ...formLayout } name={ formName } form={ form } disabled={ true }>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                  className="da-mb-16"

                >
                  <InputText placeholder={ trans(`${langKey}.name`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                  className="da-mb-16"

                >
                  <InputDate />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="for_all" rules={ rules.for_all } label={ trans(`${langKey}.for_all`) }
                  className="da-mb-10"

                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

          </Form>

          < Collapse accordion items={ items } activeItem={ activeItem } onItemClick={ handleItemClick } />

{props.addEmployees &&  
<div className="mt-5">
  <div className="mt-5">Select Add Employees To Policy</div>
  <div>
    <Form form={employmentForm} name="empform">
      <Form.Item  name="employment_ids" >
      <SelectResourceList
               onChange={onChangeEmployee}
                type="employment"
                mode="multiple"
              />
      </Form.Item>
    </Form>

  </div>
<div className="mt-4 text-center"><Button type="primary" onClick={onAddSelectedEmployees}>Add</Button></div>
</div>
}
          { employees.length > 0 && <>
            <h5 className="formlist-title">Employees</h5>
         
            <div style={ {
              overflowY: 'scroll',
              maxHeight: '50vh'
            } }>
             
              <TableWithFilter
              loader={loader}
              columns={ [{
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Designation',
                dataIndex: 'designation_name',
                key: 'designation',
              },
               {
                title: 'Action',
                hideFilter:true,
                render: (text, record) => (
                  // confirm delte 
                  <Popconfirm title="Are you sure to delete this employee from this policy?" onConfirm={() => handleDeleteEmployee(record.id)}>
                  {props.addEmployees && <DeleteOutline className="text-danger" style={{cursor:"pointer"}}/>} 
                  </Popconfirm>
                )
              }

            ] }

                data={ employees }
              />
            </div>
          </> }

        </Skeleton>
      </ModalComponent>

    </>

  )
}

export default ViewAttendancePolicyPlan


const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
