import React, { useEffect, useState } from "react";
import { Form, message, Row, Col, Skeleton } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectResourceList,InputCheck } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmploymentsReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["probation"].lngKey
const formName = "createProbation";

const CreateProbation = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true)
  const [montWiseEvaluation,setMonthWiseEvaluation]=useState(true)
  useEffect(() => {
    getRemoteData();
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getEmploymentsReq, false, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
  }
  const onSubmit = (data) => {
    setSaveLoader(true)
    const payload = { probation: {...data,month_wise_evaluation:montWiseEvaluation} };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.probations.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
          <Row gutter={ 20 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['employment_id']) } size="small"
              >
                <SelectResourceList type='employment' />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="duration" rules={ rules.duration } label={ trans(`${langKey}.duration`) }
                className="da-mb-16"
                { ...getErrorProps(errors['duration']) } size="small"
              >
                <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.duration`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="month_wise_evaluation" rules={ rules.month_wise_evaluation } label={ trans(`${langKey}.month_wise_evaluation`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month_wise_evaluation']) } size="small"
              >
                <InputCheck checked={montWiseEvaluation} onChange={(e)=>setMonthWiseEvaluation(e.target.checked)}/>
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateProbation

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id.req`) },
  ],
  duration: [
    { required: true, message: trans(`${langKey}.duration.req`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}