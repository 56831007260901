import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputDate,InputCheck, SelectWithSearch, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs';
const langKey = Const["gratuity"].lngKey
const formName = "UpdateGratuity";

const UpdateGratuity = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [childComponent, setChildComponent] = useState(false);
  const [form] = Form.useForm()
  const [formulas,setFormulas]=useState([]);
  const [afterProbation,setAfterProbation]=useState(false)
  const [forAllEmp,setForAllEmp]=useState(false)
  const [payitems,setPayitems]=useState([]);
  const [type,setType]=useState('last_salary');
  const types=[
    {
      label:"Fixed Amount",
      value:"fixed"
    },
    {
      label:"Formula",
      value:"formula"
    },
    {
      label:"Before Revised Salary",
      value:"before_revised_salary"
    },
  ]
  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line
  }, []);
const setInitialData=()=>{
  setPayitems(createSelectList(props.allData?props.allData.payitems:[]));
  setFormulas(createSelectList(props.allData.formulas));
  setType(props.record.type)
  setForAllEmp(props.record.for_all_employees)
  props.record.start_date=dayjs(props.record.start_date)
  form.setFieldsValue(props.record);
}
  const onSubmit = (data) => {
    const payload = {
      id:props.record.id,
      gratuity: {...props.record,...data}
    };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    setsaving(false)
    props.onCompleted(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
!props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={props.disabled} >
      <Row gutter={ 30 }>
      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
              className="da-mb-16"
            >
              <SelectWithSearch options={payitems} type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.payitem_id`) } />
            </Form.Item>
          </Col>
       
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
              className="da-mb-16"
            >
              <InputDate placeholder={ trans(`${langKey}.start_date`) } />
            </Form.Item>
          </Col>
          {!afterProbation && <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="calc_month_after_doj" rules={ rules.month_of_calculation } label={ trans(`${langKey}.month_of_calculation`) }
              className="da-mb-16"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.month_of_calculation`) } />
            </Form.Item>
          </Col>}
        
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
              className="da-mb-16"
              initialValue={type}
            >
              <SelectWithSearch  options={types} placeholder={ trans(`${langKey}.type`) } onChange={setType} />
            </Form.Item>
          </Col>
          {
            type==='formula' &&    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="formula_id" rules={ rules.formula_id } label={ trans(`${langKey}.formula_id`) }
              className="da-mb-16"
            >
              <SelectWithSearch options={formulas} type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.formula_id`) } />
            </Form.Item>
          </Col>
          }
          {
          type==='fixed' &&  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fixed_amount" rules={ rules.fixed_amount } label={ trans(`${langKey}.fixed_amount`) }
              className="da-mb-16"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.fixed_amount`) } />
            </Form.Item>
          </Col>
          }
     
        <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="disburse_month_after_doj" rules={ rules.month_of_reimbursement } label={ trans(`${langKey}.month_of_reimbursement`) }
              className="da-mb-16"
            >
              <InputText type="number" onWheel={(e) => e.target.blur()} placeholder={ trans(`${langKey}.month_of_reimbursement`) } />
            </Form.Item>
          </Col>
        
{/* 
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col> */}
           <Col lg={ 12 } sm={ 24 } xs={ 24 }>

<Form.Item name="after_probation" rules={ rules.after_probation } label={ trans(`${langKey}.after_probation`) }
  className="da-mb-16"
  valuePropName="checked"
>
  <InputCheck checked={afterProbation} onChange={(e)=>setAfterProbation(e.target.checked)}/>
</Form.Item>
</Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all_employees" rules={ rules.for_all_employees } label={ trans(`${langKey}.for_all_employees`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck checked={forAllEmp} onChange={(e)=>setForAllEmp(e.target.checked)}/>
            </Form.Item>
          </Col>
        </Row>
      
      </Form>
    </ModalComponent>
  )
}

export default UpdateGratuity

const rules = {
  month_of_calculation: [
    { required: true, message: trans(`${langKey}.month_of_calculation`) },
  ],
  month_of_reimbursement: [
    { required: true, message: trans(`${langKey}.month_of_reimbursement`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id`) },
  ],
  // description: [
  //   { required: true, message: trans(`${langKey}.description`) },
  // ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}