import React, { useEffect, useState } from "react";
import { Checkbox, Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getPayItems } from "@mods/common/requests";
const langKey = Const["lendType"].lngKey
const formName = "createLendType";

const CreateLendType = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formulas, setFormulas] = useState([])
  const [payItems, setPayItems] = useState([]);
  const [nonPayable, setNonPyable] = useState(false);
  const [lendApplicableTypes, setApplicableType] = useState([])
  useEffect(() => {
    getRemoteData()
    // eslint-disable-next-line
  }, []);

  const getRemoteData = () => {
    setFormulas(createSelectList(props.allData.formulas))
    setApplicableType(createSelectList(props.allData.lend_applicable_types))
  }
  const onFocusPayitem = () => {
    if (payItems.length === 0) {
      makeRequest(setLoader, getPayItems, null, onGetPayitems, null);
    }
  }
  const onGetPayitems = (response) => {
    setPayItems(createSelectList(response))
  }
  const onSubmit = (data) => {
    setsaving(true)
    const payload = { lend_type: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.lend_types.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_active" valuePropName="checked" rules={ rules.is_active } label={ trans(Commons.is_active) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_active']) } size="small"
            >
              <Checkbox defaultChecked={ true } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_guarantor_required" valuePropName="checked" rules={ rules.is_guarantor_required } label={ trans(`${langKey}.is_guarantor_required`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_guarantor_required']) } size="small"
            >
              <Checkbox defaultChecked={ true } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="employee_experience" rules={ rules.employee_experience } label={ trans(`${langKey}.employee_experience`) }
              className="da-mb-16"
              { ...getErrorProps(errors['employee_experience']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.employee_experience`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="gap_between_lends" rules={ rules.gap_between_lends } label={ trans(`${langKey}.gap_between_lends`) }
              className="da-mb-16"
              { ...getErrorProps(errors['gap_between_lends']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.gap_between_lends`) } type="number" onWheel={(e) => e.target.blur()} />
            </Form.Item>
          </Col>

          {
            !nonPayable ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="no_of_installments" rules={ rules.no_of_installments } label={ trans(`${langKey}.no_of_installments`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['no_of_installments']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.no_of_installments`) } type="number" onWheel={(e) => e.target.blur()} />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="gap_between_installments" rules={ rules.gap_between_installments } label={ trans(`${langKey}.gap_between_installments`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['gap_between_installments']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.gap_between_installments`) } type="number" onWheel={(e) => e.target.blur()} />
                  </Form.Item>
                </Col>
              </>
              : ''
          }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="formula_id" rules={ rules.formula_id } label={ trans(`${langKey}.formula_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['formula_id']) } size="small"
            >
              <SelectWithSearch options={ formulas } placeholder={ trans(`${langKey}.formula_id`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="non_payable" valuePropName="checked" rules={ rules.non_payable } label={ trans(`${langKey}.non_payable`) }
              className="da-mb-16"
              { ...getErrorProps(errors['non_payable']) } size="small"
            >
              <Checkbox onChange={ (e) => setNonPyable(e.target.checked) } />
            </Form.Item>
          </Col>

          {
            nonPayable ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['payitem_id']) } size="small"
                >
                  <SelectWithSearch placeholder={ trans(`${langKey}.payitem_id`) } options={ payItems } onFocus={ onFocusPayitem } />
                </Form.Item>
              </Col>
              : ''
          }
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
              className="da-mb-16"
              { ...getErrorProps(errors['description']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default CreateLendType

const rules = {
  name: [
    { required: true, message: '' },
  ],
  no_of_installments: [
    { required: true, message: trans(`${langKey}.no_of_installments`) },
  ],
  employee_experience: [
    { required: true, message: trans(`${langKey}.employee_experience`) },
  ],
  gap_between_installments: [
    { required: true, message: trans(`${langKey}.gap_between_installments`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}