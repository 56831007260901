import React, { useEffect, useState } from "react";
import { Form, message, Space, Divider, Row, Col, Skeleton ,Tooltip, Button} from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, TableComponent, ButtonComponent, SelectWithSearch, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { cloneReportInSaasReq, createPredefinedReportReq, getPreviewQueryReq, getReportTypesAndTables, getTableInfoByIdReq, getTenantsReq, updateDataReq } from "../requests";
import { createSelectList } from '@comps/commonFunctions';
import { DeleteOutline, PlayArrowOutlined } from "@mui/icons-material";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const langKey = Const["allReports"].lngKey
const formName = "createRawReport";

const CreatePredefinedReport = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportTypes, setReportTypes] = useState([]);

  const [tables, setTables] = useState([])
const [errors,setErrors]=useState([]);

  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    form.setFieldsValue(props.record)
  }
  //get report types and tables
  const getRelatedData = () => {
    makeRequestStateless(getReportTypesAndTables, null, onSuccessRelatedData, null);
    makeRequestStateless(getTenantsReq, null, onGetTenants, null);
  }
  const onGetTenants = (data, res) => {
    const newList = []
    data.tenants.forEach(tenant => (
      newList.push(
        {
          label: tenant.id,
          value: tenant.id
        }
      )
    ))
    setTables(newList)
  }
  const onSuccessRelatedData = (data) => {
    setReportTypes(createSelectList(data.report_types))
    setLoading(false)
  }

  const onSubmit = (data) => {
    const payload =  data;
    makeRequest(setLoader, createPredefinedReportReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    
    setsaving(false);
    if(data.errors && data.errors.length>0){
      setErrors(data.errors);
      return;
    }
    else{
      setErrors(false)
      message.success("Saved Successfully");
      props.onCompleted([]);
    }
  }

  const onError = (err, res) => {
    
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1400 } maskClosable={ false }
      mainTitle={ "Copy" } subTitle={ "Report" }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
        <Row gutter={ 30 }>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="title" rules={ rules.name } label={ "Report Title" }
          
          >
            <InputText placeholder="Title"/>
          </Form.Item>
      </Col>

      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="report_type_id" label={ trans(`${langKey}.report_type_id`) } rules={ rules.report_type_id }
         
          >
            <SelectWithSearch
            
              placeholder={ trans(`${langKey}.report_type_id`) }
              options={ reportTypes }/>
          </Form.Item>
       
      </Col>

      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="class_name" rules={ rules.class_name } label="Class Name"
          
          >
            <InputText placeholder="Class Name"/>
          </Form.Item>
      </Col>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="model_name" rules={ rules.model_name } label="Class Path"
          
          >
            <InputText placeholder="Class Path"/>
          </Form.Item>
      </Col>

      </Row>
          <Col span={20}>
          <Form.Item name="tenant_ids" label={ trans(`${langKey}.tenant_ids`) } rules={ rules.tenant_ids }>

<SelectWithSearch

  placeholder={ trans(`${langKey}.tenant_ids`) }
  options={ tables }
  mode="multiple"
  showSearch

/>
</Form.Item>
          </Col>
        
          <div className="formlist-container">
              <h5 className="formlist-title">{ "Directive Filters" }</h5>
              <Form.List name="report_directive_filters" >
                { (fields, { add, remove }) => (
                  <>
                    <Button onClick={ () => {
                      add()

                    } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add Filter</Button>
                    <div className="table-container">
                      <table className="table">
                        {
                          fields.length !== 0 && <thead>
                            <tr>
                              <th>Group</th>
                              <th>Name</th>
                              <th>Config</th>
                  
                              <th>{ trans(Commons.actions) }</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                          { fields.map(({ key, name, ...restField }) => (
                            <>
                              <tr>

                                <td >
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'group'] }
                                    rules={ rules.group }
                                  >
                                    <InputText
                                    
                                      placeholder={ "Group" } />
                                  </Form.Item>
                                </td>
                                <td >
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'name'] }
                                    rules={ rules.name }
                                  >
                                    <InputText
                                    
                                      placeholder={ "Name" } />
                                  </Form.Item>
                                </td>
                                <td >
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'config'] }
                                    rules={ rules.config }
                                  >
                                    <InputText
                                    
                                      placeholder={ "Config" } />
                                  </Form.Item>
                                </td>
                        
                                <td>
                                  <DeleteOutlined className="formlist-remove" onClick={ () => {
                                    remove(name)
                                  } } />
                                </td>
                              </tr>
                            </>
                          )) }
                        </tbody>
                      </table>
                    </div>
                  </>
                ) }
              </Form.List>
            </div>
        </Form>
       
      {
        errors.length>0 && <>
        <table className="table border text-danger">
          <thead>
            <tr>
              <th className="text-danger text-center">Errors</th>
            </tr>
          </thead>
          <thead>
           
          </thead>
          <tbody className="text-danger">
          {
            errors.map((error, index) => (
              <tr key={index} >
                <td className="text-danger">{error}</td>
              </tr>
            ))  
           }
          </tbody>
        </table>
        </>
      }
      </Skeleton>

    </ModalComponent>
  )
}

export default CreatePredefinedReport

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  report_type_id: [
    { required: true, message: trans(`${langKey}.report_type_id`) },
  ],
  tenant_ids: [
    { required: true, message: trans(`${langKey}.tenant_ids`) },
  ],
  group: [
    { required: true, message: "Group" },
  ],
  config: [
    { required: true, message: "Config" },
  ],
  class_name: [
    { required: true, message: "Class Name" },
  ],
  model_name: [
    { required: true, message: "Class Path" },
  ],
};
