// Layout
// Buttons
import BaseButton from "./buttons/BaseButton";
import BaseCancel from "./buttons/BaseCancel";
import BaseCreate from "./buttons/BaseCreate";
import BaseImport from "./buttons/BaseImport";
import BaseSave from "./buttons/BaseSave";
import BaseHistory from "./buttons/BaseHistory";
import BaseFlow from "./buttons/BaseFlow";
import BaseUpload from "./buttons/BaseUpload";
import BaseFilterButton from "./buttons/BaseFilterButton";
import BaseViewButton from "./buttons/BaseView";
import BaseDownload from "./buttons/BaseDownload";
import BaseRefresh from "./buttons/BaseRefresh";
import BaseTableView from "./buttons/BaseTableView";
import BaseCardView from "./buttons/BaseCardView";
import BaseMessage from "./buttons/BaseMessage";
import BaseStop from "./buttons/BaseStop";
import BaseLeftButton from "./buttons/BaseLeftButton";
import BaseRightButton from "./buttons/BaseRightButton";
import BaseNext from "./buttons/BaseNextButton";
import BasePrevious from "./buttons/BasePreviousButton";
import BaseDelete from "./buttons/BaseDelete";
import BaseAddEmployee from "./buttons/BaseAddEmployee";
import BaseMultiselect from "./buttons/BaseMultiselectButton";
import BaseCreateRoster from "./buttons/BaseCreateRoster";
import BaseCommen from "./buttons/BaseCommon";
import BaseAddNew from "./buttons/BaseAddNew";
import BaseFlowHistory from "./buttons/BaseFlowHistory";
import BaseApprove from "./buttons/BaseApprove";
// Inputs
import BaseSelectWithSearch from "./inputs/BaseSelectWithSearch"
import BaseMultipleSelectWithSearch from "./inputs/BaseMultipleSelectWithSearch"
import BaseSelectWithButton from "./inputs/BaseSelectWithButton"

import BaseUploadFile from "./inputs/BaseUploadFile"
import BaseDatePicker from "./inputs/BaseDatePicker"
import BaseTimePicker from "./inputs/BaseTimePicker"
import BaseInputText from "./inputs/BaseInputText"
import BaseCheckBox from "./inputs/BaseCheckBox"
import BaseTextarea from "./inputs/BaseTextarea"

// Filters
import BaseFilter from "./filters/BaseFilter";
import BaseAction from "./layout/BaseActions";
import BaseBody from "./layout/BaseBody";
import BaseDropzone from "./layout/BaseDropzone";
import BaseEditor from "./layout/BaseEditor";
import BaseGoogleMap from "./layout/BaseGoogleMap";
import BaseHeader from "./layout/BaseHeader";
import BaseModal from "./layout/BaseModal";
import BaseTab from "./layout/BaseTab";
import BaseTable from "./layout/BaseTable";
import BaseTree from "./layout/BaseTree";
import EditTable from "./layout/EditTable";
import MultiSelectTable from "./layout/MultiSelectTable";
import { TranslateWithoutHook } from '@utils/IntlMessages';
import EmpShortInfo from "../modules/common/EmpShortInfo";
import BasePagination from "./layout/BasePagination";
import { BaseFilterTable } from "./layout/BaseTableFilter";
import BaseSelectResource from "./layout/BaseSelectResource";
import BaseFormItem from "./inputs/BaseFormItem";
import BaseShowErrors from "./layout/BaseShowErrors";
import BaseCustomButton from "./buttons/BaseCustomButton";
import BaseFilterHeader from "./filters/BaseFilterHeader";
// Layout
export const HeaderComponent = BaseHeader;
export const BodyComponent = BaseBody;
export const TableComponent = BaseTable;
export const SelectionTable = MultiSelectTable;
export const Dropzone = BaseDropzone;
export const Editor = BaseEditor;
export const ActionComponent = BaseAction;
export const ModalComponent = BaseModal;
export const TabComponent = BaseTab;
export const TreeComponent = BaseTree;
export const EditAbleTable = EditTable;
// Filters
export const FilterComponent = BaseFilter;
export const QuickFlterComponent = BaseFilterHeader;

// Inputs

export const SelectWithSearch = BaseSelectWithSearch;
export const SelectMultiWithSearch = BaseMultipleSelectWithSearch;
export const SelectWithButton = BaseSelectWithButton;
export const SelectResourceList = BaseSelectResource;

export const UploadFile = BaseUploadFile;
export const InputDate = BaseDatePicker;
export const InputTime = BaseTimePicker;
export const InputText = BaseInputText;
export const InputCheck = BaseCheckBox;
export const InputTextArea = BaseTextarea;
// Buttons
export const CustomButton = BaseCustomButton
export const ButtonComponent = BaseButton;
export const CreateButton = BaseCreate;
export const ImportButton = BaseImport;
export const UploadButton = BaseUpload;
export const CancelButton = BaseCancel;
export const SaveButton = BaseSave;
export const HistoryButton = BaseHistory;
export const FlowButton = BaseFlow;
export const FilterButton = BaseFilterButton;
export const ViewButton = BaseViewButton;
export const DownloadButton = BaseDownload;
export const RefreshButton = BaseRefresh;
export const TableViewButton = BaseTableView;
export const CardViewButton = BaseCardView;
export const MessageButton = BaseMessage;
export const StopsButton = BaseStop;
export const LeftButton = BaseLeftButton;
export const RightButton = BaseRightButton;
export const NextButton = BaseNext;
export const PreviousButton = BasePrevious;
export const DeleteButton = BaseDelete;
export const AddEmployeeButton = BaseAddEmployee;
export const MultiselectButton = BaseMultiselect;
export const CreateRoster = BaseCreateRoster;
export const CommonButton = BaseCommen;
export const AddNewButton = BaseAddNew;
export const FlowHistoryButton = BaseFlowHistory
export const TableWithFilter = BaseFilterTable;
export const ApproveButton = BaseApprove;
//Components
export const GoogleMap = BaseGoogleMap;

// common functions

export const trans = TranslateWithoutHook;

//get employee name,code ,image 

export const EmployeeWithProfile = EmpShortInfo;
export const PaginationComponent = BasePagination;
export const FormItem = BaseFormItem
export const ShowErrorModal = BaseShowErrors
