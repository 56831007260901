import React, { useEffect, useState } from "react";
import { Checkbox, Form, Modal, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, InputDate, UploadButton, InputTime, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmployeeRelatedDataReq, getEmploymentsReq, onDateChange, onShiftSelectionChangeReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList, convertTimeTotz } from '@comps/commonFunctions';
import RoasterView from "@mods/common/RoasterView";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
const langKey = Const["leaveRequest"].lngKey
const formName = "createLeaveRequest";
const moment = require('moment');

var changeShiftPayload={};
var selectedLeaveType={};
var selectedEmploymentId=null;
var startTime=null;
var endTime=null;

const CreateLeaveRequest = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([])
  const [responseLeaveTypes, setResponseLeaveTypes] = useState([])
  const [available, setAvailable] = useState(null)
  const [roasterData, setRoasterData] = useState({})
  const [selectedStart, setStartDateSelected] = useState(null)
  const [selectedEnd, setEndDateSelected] = useState(null)
  const [disabledSave, setDisableSave] = useState(true)
  const [roasterView, setRosterView] = useState(null)
  const [shift_ids, setShiftIds] = useState([]);
  const [isShort, setIsShort] = useState(false)
  const [shortLeaveTypes, setShortLeaveTypes] = useState([])
  const [shortLeaveSelected, setShortLeaveSelected] = useState(null)
  const [hasShort, setHasShort] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [form] = Form.useForm()
  useEffect(() => {
    getEmployments()
    
    if (props.source === 'timeSheet') {
      setStartDateSelected(dayjs(props.record.date))
      setEndDateSelected(dayjs(props.record.date))

      form.setFieldsValue({
        start: dayjs(props.record.date),
        end: dayjs(props.record.date),
      })
      selectedEmploymentId=props.employment_id;
      onChangeStartDate(dayjs(props.record.date), props.employment_id)
      getEmployeeRelated(props.employment_id)
    }
    if(props.module==="myLeaveRequest"){
      getEmployeeRelated(authUser.employment_id);
      selectedEmploymentId=authUser.employment_id;
    }
    // eslint-disable-next-line
  }, []);
  const getEmployments = () => {
    const payload = { filters: props.filters }
    makeRequestStateless(getEmploymentsReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
  }
  const getEmployeeRelated = (employment_id) => {
    const payload = { employment_id: employment_id }
    makeRequest(setLoader, getEmployeeRelatedDataReq, payload, onGetEmployeeRelated, onError);
  }
  const onGetEmployeeRelated = (data, response) => {
    setLeaveTypes(createSelectList(response.leave_types))
    setResponseLeaveTypes(response);
  }
  const onSubmit = (data) => {
    
    if (isShort) {
      data.time_start = dayjs(data.time_start);
      data.time_end = dayjs(data.time_end);
    }
    data.start = data.start.format('YYYY-MM-DD');
    data.end = data.end.format('YYYY-MM-DD');
    data.shiftIds = shift_ids
    data.employment_id = selectedEmploymentId
    const payload = { file_attachment: undefined, leave_request: data ,filters:props.filters };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(true)
    message.success("Saved successfully");
    props.onCompleted(data.leave_requests.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }

  const onChangeEmployment = (id) => {
    getEmployeeRelated(id)
    selectedEmploymentId=id;
    setSaving(false)
  }

  const onChangeLeaveType = (e) => {

    responseLeaveTypes['leave_types'].map((each) => {
      if (each.id === e) {
        selectedLeaveType=each
        setShortLeaveTypes(createSelectList(each.short_leave_type_slabs))
        setAvailable(each.available);
        form.setFieldsValue({ leave_type_available: each.available })
        setHasShort(each.short_leave_type_slabs.length ? true : false)
      }
    })

    if(selectedStart){
      onChangeStartDate(selectedStart);
    }
  }
  const onChangeShortLeaveType = (id) => {
    setShortLeaveSelected(id)
    onChangeStartDate(selectedStart)
  }

  const onChangeTime=(start,type)=>{
    
    if(start){
     const end=convertTimeTotz(endTime);
     const differenceInHours = end.diff(start, 'hour')+1;
     const count=form.getFieldValue('shift_count');
     const duration = count * differenceInHours;
         form.setFieldsValue({
           time_start:start,
           time_end:start.add(duration,'hour'),
         })
    }else{
      form.setFieldsValue({
        time_start:null,
           time_end:null,
      })
    }
  }

  const onChangeStartDate = (date) => {
    setStartDateSelected(date)
    let end = selectedEnd;
    if (!selectedEnd || isShort) {
      form.setFieldsValue({ end: date })
      end = date;
    }
    if (date) {
      let payload = {
        leave_request: {
          is_short: selectedLeaveType.is_short,
          start: date.format('YYYY-MM-DD'),
          end: end ? end.format('YYYY-MM-DD') : date.format('YYYY-MM-DD'),
          time_start: null,
          time_end: null,
          employment_id: selectedEmploymentId,
          leave_type_id: selectedLeaveType.id,
          short_leave_type_slab_id: isShort ? shortLeaveSelected : null,
          leave_type_available: selectedLeaveType.available
        }
      }
      setAvailable(selectedLeaveType.available);
      changeShiftPayload=payload;
      makeRequest(setLoader, onDateChange, payload, onSuccessDateChange, onDatechangeError);
    }
  }

  const onChangeEndDate = (e) => {
    setEndDateSelected(e)
    if (!selectedStart) {
      form.setFieldsValue({ start: e })
    }
    if (e) {
      let payload = {
        leave_request: {
          is_short: selectedLeaveType.is_short,
          start: selectedStart ? selectedStart.format('YYYY-MM-DD') : e.format('YYYY-MM-DD'),
          end: e.format('YYYY-MM-DD'),
          start_time: null,
          time_end: null,
          employment_id: selectedEmploymentId,
          leave_type_id: selectedLeaveType.id,
          short_leave_type_slab_id: isShort ? shortLeaveSelected : null,
          leave_type_available: selectedLeaveType.available
        }
      }
      setAvailable(selectedLeaveType.available);
      changeShiftPayload=payload
      makeRequest(setLoader, onDateChange, payload, onSuccessDateChange, onDatechangeError);
    }
  }

  const onSuccessDateChange = (response, data) => {
    
  }
  const onDatechangeError = (response, data) => {
    if (data.response) {
      message.error(data.response.data.message)
      form.setFieldsValue({
        count: null,
        shift_count: null,
        sandwich_count: null
      })
      return
    }
    if (data.shifts) {
      setDisableSave(false)
    }
    setRosterView(<RoasterView record={ data } />)
    setRoasterData(data)
    onShiftChange(data)
  }

  const onShiftChange = (data) => {
    //get shiftIds
    let shiftIds = [];
    let date_total = [];
    let start = moment(new Date(data.from_date))
    let end = moment(new Date(data.to_date))

    if (start === end) {
      date_total.push(start)
      //setDateTotalSource(date_total)
    }
    else {
      while (start <= end) {
        date_total.push(start.format('YYYYMMDD'));
        start = start.add(1, 'days');
      }
    }

    date_total.map((date) => (
      data.employments.map((employee) => {
        if (data.shifts.hasOwnProperty(date + '-' + employee.id)) {
          shiftIds.push(data.shifts[date + '-' + employee.id][0]['id'])
          let start = data.shifts[date + '-' + employee.id][0].start
          let end = data.shifts[date + '-' + employee.id][0].end
          startTime=start;
          endTime=end;
        }
      })
    ))

    setShiftIds(shiftIds)
    changeShiftPayload.leave_request.shiftIds = shiftIds
    let payload = changeShiftPayload
    makeRequest(setLoader, onShiftSelectionChangeReq, payload, onSuccessShiftChange, onShiftchangeError);
  }

  
  const onSuccessShiftChange = (response, data) => {

  }
  const onShiftchangeError = (response, data) => {
    
    if (data.response) {
      message.error(data.response.data.message)
      form.setFieldsValue({
        count: null,
        shift_count: null,
        sandwich_count: null
      })
      return
    }

    let start,end,duration;
    start =startTime? convertTimeTotz(startTime):null;
   end =endTime? convertTimeTotz(endTime):null;
    if(start && end){
// Calculate the difference in hours
const differenceInHours = end.diff(start, 'hour');
      duration = data.shift_count * differenceInHours;
    }

    form.setFieldsValue({
      time_start: start,
      time_end:endTime? start.add(duration, 'hour'):null,
      shift_count: data.shift_count,
      sandwich_count: data.sandwich_count,
      count: data.count
    })
  }
  //-----------------------
  //============uploading file Attachment============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [

    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" disabled={ disabledSave } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
{props.module!=="myLeaveRequest" && props.source!=="timeSheet" &&     <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id"  rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment' onChange={ onChangeEmployment } subordinates={props.module==="subordinates"?true:false}/>
              </Form.Item>
            </Col>}
        

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_id" rules={ rules.leave_type_id } label={ trans(`${langKey}.leave_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ leaveTypes }
                  placeholder={ trans(`${langKey}.leave_type_id`) }
                  onChange={ onChangeLeaveType }
                 
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_available" rules={ rules.current_available } label={ trans(`${langKey}.current_available`) }
                className="da-mb-16"
                { ...getErrorProps(errors['current_available']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.current_available`) } type="number" onWheel={(e) => e.target.blur()} disabled />
              </Form.Item>
            </Col>
            { hasShort ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="is_short" rules={ rules.is_short } label={ trans(`${langKey}.is_short`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['is_short']) } size="small"
                  valuePropName="checked"
                >
                  <Checkbox onChange={ (e) => setIsShort(e.target.checked) } />
                </Form.Item>
              </Col>
              : '' }

            {
              isShort ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="short_leave_type_slab_id" rules={ rules.short_leave_type_slab_id } label={ trans(`${langKey}.short_leave_type_slab_id`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <SelectWithSearch
                      options={ shortLeaveTypes }
                      placeholder={ trans(`${langKey}.short_leave_type_slab_id`) }
                      onChange={ onChangeShortLeaveType }
                    />
                  </Form.Item>
                </Col>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate onChange={ (start) => onChangeStartDate(start) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"

              >
                <InputDate onChange={ (end) => onChangeEndDate(end) } disabled={ isShort } />
              </Form.Item>
            </Col>
          </Row>

          { roasterView }

          <Row gutter={ 30 }>
            { isShort ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="time_start" rules={ rules.time_start } label={ trans(`${langKey}.time_start`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <InputTime placeholder={ trans(`${langKey}.time_start`) } onChange={(time)=>onChangeTime(time,'time_start')}/>
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="time_end" rules={ rules.time_end } label={ trans(`${langKey}.time_end`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <InputTime placeholder={ trans(`${langKey}.time_end`) } disabled={ true } />
                  </Form.Item>
                </Col>
              </>
              : ''
            }
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="shift_count" rules={ rules.shift_count } label={ trans(`${langKey}.shift_count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['shift_count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.shift_count`) } type="number" onWheel={(e) => e.target.blur()} value={ available } disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="sandwich_count" rules={ rules.sandwich_count } label={ trans(`${langKey}.sandwich_count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['sandwich_count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.sandwich_count`) } type="number" onWheel={(e) => e.target.blur()} value={ available } disabled={ true } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="count" rules={ rules.count } label={ trans(`${langKey}.count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.count`) } type="number" onWheel={(e) => e.target.blur()} value={ available } disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="file_attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>
          </Row>

          <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
            <img
              alt="example"
              style={ {
                width: '100%',
              } }
              src={ previewImage }
            />
          </Modal>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateLeaveRequest

const rules = {
  leave_type_id: [
    { required: true, message: trans(`${langKey}.leave_type_id`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],
  time_start: [
    { required: true, message: trans(`${langKey}.time_start`) },
  ],
  time_end: [
    { required: true, message: trans(`${langKey}.time_end`) },
  ],
  count: [
    { required: true, message: trans(`${langKey}.count`) },
  ],
  shift_count: [
    { required: true, message: trans(`${langKey}.shift_count`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  short_leave_type_slab_id: [
    { required: true, message: trans(`${langKey}.short_leave_type_slab_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

