import { useState, useEffect } from "react";
import {
  SelectWithSearch,
  SaveButton,
  trans,
  CancelButton,
  ModalComponent,
  AddNewButton,
  InputText,
  InputTextArea
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { Button, Checkbox, Form, Skeleton, Switch, message, Row, Col } from 'antd';
import { getDataByIdReq, updateDataReq } from "./requests";
import { createSelectList } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import CreateTaxStrutcture from "../settings/taxStructures/components/CreateTaxStructure";

const langKey = Const["payroll"].lngKey;
const formName = "createAttendanceRequest";

const UpdatePayroll = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payitems, setPayitems] = useState([]);
  const [taxStructures, setTaxStructures] = useState([]);
  const [processAtMonthEnd, setProcessAtMonthEnd] = useState(false);
  const [isFixDays, setIsFixedDays] = useState(false);
  const [payrollData, setPayrollData] = useState([])
  const [payrollPayitems, setPayrollPayitems] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const [childComponent, setChildComponent] = useState(null)

  useEffect(() => {
    getRelatedData()
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    // if (!props.record) {
    //   return
    // }
    setProcessAtMonthEnd(props.record.process_at_month_end)
    setIsFixedDays(props.record.is_fixed_days)
    form.setFieldsValue(props.record)
  }

  const getRelatedData = () => {
    makeRequest(setLoader, getDataByIdReq, props.record.id, onGetRelatedDataSuccess, onGetRelatedDataError);
  }
  const onGetRelatedDataSuccess = (response) => {
    setPayrollData(response.payroll)
    setPayitems(createSelectList(response.payitems))
    setTaxStructures(createSelectList(response.tax_structures))
    //set form data
    setIsFixedDays(response.payroll.is_fixed_days)
    setProcessAtMonthEnd(response.payroll.process_at_month_end)
    form.setFieldsValue(response.payroll)
    setPayrollPayitems(response.payroll.payitems)
    setLoading(false)
  };

  const onGetRelatedDataError = (err, res) => {
    message.error(res.response.data.message);
  }

  const setRows = (key, state) => {
    if (payrollPayitems[key] !== undefined && payrollPayitems[key].id !== undefined && state === 'Deleted') {
      payrollPayitems[key].row_state = 'Deleted'
      deletedRows.push(payrollPayitems[key])
    }
    //setDeletedRows(deletedRows)
  }

  const onSubmit = (data) => {
    setSaving(true)
    //update payitems
    let newList = []
    data.payitems !== undefined && data.payitems.forEach((each) => {
      if (each.id === undefined) {
        each.row_state = 'Added'
      }
      if (each.id !== undefined) {
        each.row_state = 'Updated'
      }
      each.payroll_id = id
      newList.push(each)
    })

    deletedRows.forEach(row => {
      newList.push(row)
    })
    //======
    const payload = {
      payroll: {
        ...data,
        id: props.record.id,
        is_fixed_days: isFixDays,
        no_of_fixed_days: isFixDays ? data.no_of_fixed_days : null,
        processing_date: !processAtMonthEnd ? data.processing_date : null,
        process_at_month_end: processAtMonthEnd,
        payitems: newList
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    navigate('/payrollmanagement/payrolls')
    message.success("Updated successfully");
    props.onCompleted(data.payrolls.data);
  }

  const onError = (err, res) => {
    setSaving(false)

    message.error(res.response.data.message);
  }

  const onTaxStructureCreated = (data) => {
    if (data.length) {
      form.setFieldValue('tax_structure_id', data[0].id)
      setTaxStructures(createSelectList(data))
    }
    setChildComponent(null);
  };
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  // if (payrollData.length === 0) {
  //   return <></>;
  // }

  const footer = [
    !props.disabled &&
    <SaveButton key="create_button" htmlType="submit" form={ formName } loading={ saving } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (
    <>
      <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
        mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
        visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
        { childComponent }
        <Skeleton loading={ loading }>
          <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                  className="da-mb-16"
                >
                  <InputText placeholder={ trans(`${langKey}.name`) } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <AddNewButton onClick={ () => setChildComponent(<CreateTaxStrutcture onCompleted={ onTaxStructureCreated } />) } />
                <Form.Item name="tax_structure_id" rules={ rules.tax_structure_id } label={ trans(`${langKey}.tax_structure_id`) }
                  className="da-mb-16"
                >
                  <SelectWithSearch
                    options={ taxStructures }
                    placeholder={ trans(`${langKey}.tax_structure_id`) }
                  />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="is_tax_applicable" rules={ rules.is_tax_applicable } label={ trans(`${langKey}.is_tax_applicable`) }
                  className="da-mb-16"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="process_at_month_end" rules={ rules.process_at_month_end } label={ trans(`${langKey}.process_at_month_end`) }
                  className="da-mb-16"
                  valuePropName="checked"
                  initialValue={ true }
                >
                  <label>{ trans(`${langKey}.processing_date`) }</label>
                  <Switch style={ { margin: 10 } } onChange={ (value) => setProcessAtMonthEnd(value) } checked={ processAtMonthEnd } />
                  <label>{ trans(`${langKey}.at_end`) }</label>
                </Form.Item>
              </Col>

              {
                !processAtMonthEnd ?
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="processing_date" rules={ rules.processing_date } label={ trans(`${langKey}.processing_date`) }
                      className="da-mb-16"
                    >
                      <InputText placeholder={ trans(`${langKey}.processing_date`) } type="number" onWheel={(e) => e.target.blur()} />
                    </Form.Item>
                  </Col>
                  : ''
              }

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="is_fixed_days" rules={ rules.is_fixed_days } label={ trans(`${langKey}.is_fixed_days`) }
                  className="da-mb-16"
                  valuePropName="checked"
                >
                  <label>{ trans(`${langKey}.actual`) }</label>
                  <Switch style={ { margin: 10 } } onChange={ (value) => setIsFixedDays(value) } checked={ isFixDays } />
                  <label>{ trans(`${langKey}.fixed`) }</label>
                </Form.Item>
              </Col>

              { isFixDays ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="no_of_fixed_days" rules={ rules.no_of_fixed_days } label={ trans(`${langKey}.no_of_fixed_days`) }
                    className="da-mb-16"
                  >
                    <InputText placeholder={ trans(`${langKey}.no_of_fixed_days`) } type="number" onWheel={(e) => e.target.blur()} />
                  </Form.Item>
                </Col>
                : '' }

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                  className="da-mb-16"

                >
                  <InputTextArea placeholder={ trans(`${langKey}.description`) } />
                </Form.Item>
              </Col>
            </Row>

            <div className="formlist-container">
              <h5 className="formlist-title">{ trans(`${langKey}.payitems`) }</h5>
              <Form.List name="payitems" >
                { (fields, { add, remove }) => (
                  <>
                    <Button onClick={ () => {
                      add()
                      // setRows(fields.length, 'Added')
                    } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                    <div className="table-container">
                      <table className="table">
                        {
                          fields.length !== 0 && <thead>
                            <tr>
                              <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.payitem_id`) }</th>
                              <th>{ trans(`${langKey}.is_taxable`) }</th>
                              <th>{ trans(`${langKey}.arrear_applicable`) }</th>
                              <th>{ trans(`${langKey}.is_final_settlement`) }</th>
                              <th>{ trans(Commons.actions) }</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                          { fields.map(({ key, name, ...restField }) => (
                            <>
                              <tr>

                                <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'payitem_id'] }
                                    rules={ rules.payitem_id }
                                  >
                                    <SelectWithSearch
                                      options={ payitems }
                                      placeholder={ trans(`${langKey}.payitem_id`) } />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'is_taxable'] }
                                    rules={ rules.is_taxable }
                                    valuePropName="checked"
                                  >
                                    <Checkbox className="formlist-remove" />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'arrear_applicable'] }
                                    rules={ rules.arrear_applicable }
                                    valuePropName="checked"
                                  >
                                    <Checkbox className="formlist-remove" />
                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'is_final_settlement'] }
                                    rules={ rules.is_final_settlement }
                                    valuePropName="checked"
                                  >
                                    <Checkbox className="formlist-remove" />
                                  </Form.Item>
                                </td>
                                <td>
                                  <DeleteOutlined className="formlist-remove" onClick={ () => {
                                    remove(name)
                                    setRows(key, 'Deleted')
                                  } } />
                                </td>
                              </tr>
                            </>
                          )) }
                        </tbody>
                      </table>
                    </div>
                  </>
                ) }
              </Form.List>
            </div>
          </Form>
        </Skeleton>
      </ModalComponent>
    </>
  )
}

export default UpdatePayroll

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  tax_structure_id: [
    { required: true, message: trans(`${langKey}.tax_structure_id`) },
  ],
  processing_date: [
    { required: true, message: trans(`${langKey}.processing_date`) },
  ],
  no_of_fixed_days: [
    { required: true, message: trans(`${langKey}.no_of_fixed_days`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
