import React, { useEffect, useState } from "react";
import { Button, Col, Form, message, Modal, Row, Skeleton } from "antd";
import { trans, SaveButton, SelectWithSearch, UploadFile, InputText, InputDate } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList, checkPermissions } from '@comps/commonFunctions';
import { getEmployeeRelatedDataReq, matchCnicReq, getEmployeeByIdReq, updateEmployeeReq } from "../requests";
import { Const } from '../../constants';
import { EditOutlined } from '@ant-design/icons';
import "./Employee.css"
import dayjs from 'dayjs';

const langKey = Const["employee"].lngKey
const formName = "updateEmployee";
const UpdateEmployeeInfo = (props) => {
  const [personalInformationEditMode, setPersonalInformationEditMode] = useState(false);
  const [CNICEditMode, setCNICEditMode] = useState(false);
  const [drivingEditMode, setDrivingEditMode] = useState(false);
  const [passportEditMode, setPassportEditMode] = useState(false);

  const handleEditClickDriving = () => {
    setDrivingEditMode(!drivingEditMode);
  };

  const handleEditClickPersonalInformation = () => {
    setPersonalInformationEditMode(!personalInformationEditMode);
  }
  const handleEditClickCNIC = () => {
    setCNICEditMode(!CNICEditMode)
  }

  const handleEditClickPassport = () => {
    setPassportEditMode(!passportEditMode)
  }

  const handleSaveClick = () => {
    // Handle the save action here, e.g., submit the form data
    // You can access the edited data in formData
    // Implement your save logic here

    // After saving, you may want to exit edit mode
    setPersonalInformationEditMode(false);
  };


  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState(166)


  const [errors, setErrors] = useState([]);
  const [salutations, setSalutations] = useState([]);
  const [martialstatuses, setMartialstatuses] = useState([]);
  const [religions, setReligions] = useState([]);
  const [genders, setGenders] = useState([]);
  const [bloodgroups, setBloodgroups] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  //const [defaultProfile, setDefaultProfile] = useState();
  useEffect(() => {
    getEmployeeById(props.id);
    getRemoteData();
    //getCompanyRelatedData();
  }, [props]);

  //select
  const getEmployeeById = (id) => {
    makeRequest(setLoader, getEmployeeByIdReq, id, onGetEmployeeSuccess, null);
  }
  const getRemoteData = () => {
    makeRequestStateless(getEmployeeRelatedDataReq, false, onEmployeeRelatedDataSuccess, onEmployeeRelatedDataError)
  }
  const onGetEmployeeSuccess = (response) => {
    setLoading(false)
    setprofilePicFileList(response.employee.profile_pic_attachment ? [response.employee.profile_pic_attachment] : [])
    setcnicFileList(response.employee.cnic_attachment ? [response.employee.cnic_attachment] : [])
    setpassportFileList(response.employee.passport_attachment ? [response.employee.passport_attachment] : [])
    setdrivingLicenseFileList(response.employee.driving_license_attachment ? [response.employee.driving_license_attachment] : [])
    response.employee.d_o_b = dayjs(response.employee.d_o_b)
    response.employee.cnic_expiry_date = response.employee.cnic_expiry_date ? dayjs(response.employee.cnic_expiry_date) : null
    response.employee.passport_expiry_date = response.employee.passport_expiry_date ? dayjs(response.employee.passport_expiry_date) : null
    response.employee.driving_license_expiry_date = response.employee.driving_license_expiry_date ? dayjs(response.employee.driving_license_expiry_date) : null

    form.setFieldsValue(response.employee)
  }
  const onEmployeeRelatedDataSuccess = (data, res) => {
    setLoading(false)
    setSalutations(createSelectList(data.salutations))
    setNationalities(createSelectList(data.countries))
    setBloodgroups(createSelectList(data.bloodgroups))
    setGenders(createSelectList(data.genders))
    setReligions(createSelectList(data.religions))
    setBloodgroups(createSelectList(data.bloodgroups))
    setMartialstatuses(createSelectList(data.maritalstatuses
    ))
  }

  const onEmployeeRelatedDataError = (err) => {
    console.error(err)
  }

  //------------on submit forms----------------
  const onSubmitFirstStep = (data) => {
    setFirstStepData(data)
    data.cnic_attachment = cnicFileList.length > 0 && cnicFileList[0].id === undefined ? cnicFileList[0].originFileObj : null
    data.driving_license_attachment = drivingLicenseFileList.length > 0 && drivingLicenseFileList[0].id === undefined ? drivingLicenseFileList[0].originFileObj : null
    data.passport_attachment = passportFileList.length > 0 && passportFileList[0].id === undefined ? passportFileList[0].originFileObj : null
    setLoader(true)
    data.d_o_b = data.d_o_b.format('YYYY-MM-DD');
    let payload = { employee_info: data }

    payload.employee_info.id = props.id;
    makeRequest(setLoader, updateEmployeeReq, payload, onEmployeeUpdateSuccess, onEmployeeUpdateError);
  }

  const onEmployeeUpdateSuccess = (response) => {
    setLoader(false)
    message.success('Employee Updated Successfully!')
    props.setDefaultProfileCard(
      {
        'full_name': response.employee_info.full_name,
        'department_name': response.employee_info.department_name,
        'age': response.employee_info.age,
        'profile_pic_url': response.employee_info.profile_pic_url,
        'profile_score': response.employee_info.profile_score,
        'mobile_no': response.employee_info.mobile_no,
        // 'designation_name': response.employee_info.employment_info[0].designation_name,
        // 'official_email': response.employee_info.employment_info[0].official_email,
        // 'code': response.employee_info.employment_info[0].emp_code
      }
    )
  }
  const onEmployeeUpdateError = (error, response) => {
    message.error(response.response.data.message)
  }

  //-----------------onChange--------------------
  const onChangeNationality = (data) => {
    setSelectedNationality(data)
  }

  const onChangeCnic = (e) => {
    let count = e.target.value.toString().length;
    if (count > 12) {
      makeRequestStateless(matchCnicReq, e.target.value, onMatchCnicSuccess, onMatchCnicError);
    }
  }
  const onMatchCnicSuccess = (response) => {
    if (response.result) {
      message.error('Warning! Cnic matched with another employee in the system.!')
    }
  }
  const onMatchCnicError = (response) => {

  }

  //-------------------end--------------------
  // ------------------------------------
  //---------------------form layout------------
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  }
  //============uploading file============
  //const [fileList, setFileList] = useState([]);
  const [profilePicFileList, setprofilePicFileList] = useState([]);
  const [cnicFileList, setcnicFileList] = useState([]);
  const [passportFileList, setpassportFileList] = useState([]);
  const [drivingLicenseFileList, setdrivingLicenseFileList] = useState([]);

  //=========================================
  //------------------------------------
  //------------current step---------
  const [firstStepData, setFirstStepData] = useState();

  const firstStep = () => {

    return (
      <>
        <div style={ { border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
          <div className="employeeInfo" ><h5 className="employeeInfo-text">Personal Information</h5></div>
          <Skeleton loading={ loading }  >
            <Form
              { ...formLayout }
              form={ form }
              name={ formName }
              onFinish={ onSubmitFirstStep }
              className="custom-form"
              disabled={ !personalInformationEditMode }
              scrollToFirstError
            >
              <div style={ { marginLeft: 12, marginTop: 20, overflowY: 'auto' } }>
                <Row>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="salutation_id" label={ trans(`${langKey}.selectSalutation`) } rules={ rules.salutation_id } className="da-mb-16"
                      { ...getErrorProps(errors['salutation_id']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.selectSalutation`) }
                        options={ salutations }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="first_name" rules={ rules.first_name } label={ trans(`${langKey}.firstName`) } className="da-mb-16"
                      { ...getErrorProps(errors['first_name']) } size="small"
                    >
                      <InputText placeholder={ 'First Name' } />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="middle_name" rules={ rules.middle_name } label={ trans(`${langKey}.middleName`) } className="da-mb-16"
                      { ...getErrorProps(errors['middle_name']) } size="small"
                    >
                      <InputText placeholder="Middle Name" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="last_name" rules={ rules.last_name } label={ trans(`${langKey}.lastName`) } className="da-mb-16"
                      { ...getErrorProps(errors['last_name']) } size="small"
                    >
                      <InputText placeholder="Last Name" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="father_or_husband_name" rules={ rules.father_or_husband_name } label="Father/Husband Name" className="da-mb-16"
                      { ...getErrorProps(errors['name']) } size="small"
                    >
                      <InputText placeholder='Enter here' />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="gender_id" label={ trans(`${langKey}.selectGender`) } rules={ rules.gender_id } className="da-mb-16"
                      { ...getErrorProps(errors['gender_id']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.selectGender`) }
                        options={ genders }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="d_o_b" rules={ rules.d_o_b } label={ trans(`${langKey}.dob`) } className="da-mb-16"
                      { ...getErrorProps(errors['d_o_b']) } size="small"
                    >
                      <InputDate
                        format="DD-MMM-YYYY" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item
                      name="marital_status_id" label={ trans(`${langKey}.selectMartialStatus`) } rules={ rules.marital_status_id } className="da-mb-16"
                      { ...getErrorProps(errors['marital_status_id']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.selectMartialStatus`) }
                        options={ martialstatuses }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item style={ { paddingTop: 8 } } name="religion_id" label={ trans(`${langKey}.selectReligion`) } rules={ rules.religion_id } className="da-mb-16"
                      { ...getErrorProps(errors['religion']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.selectReligion`) }
                        options={ religions }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item style={ { paddingTop: 10 } }
                      name="nationality_id" label={ trans(`${langKey}.selectNationality`) } rules={ rules.nationality_id } className="da-mb-16"
                      { ...getErrorProps(errors['nationality_id']) }
                    >
                      <SelectWithSearch
                        onChange={ onChangeNationality }
                        placeholder={ trans(`${langKey}.selectNationality`) }
                        options={ nationalities }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item style={ { paddingTop: 8 } } name="blood_group_id" label={ trans(`${langKey}.selectBloodGroup`) } rules={ rules.blood_group } className="da-mb-16"
                      { ...getErrorProps(errors['blood_group_id']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.selectBloodGroup`) }
                        options={ bloodgroups }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item style={ { paddingTop: 5 } } name="mobile_no" label={ trans(`${langKey}.mobile`) } className="da-mb-16"
                      { ...getErrorProps(errors['mobile_no']) } size="small"
                    >
                      <InputText placeholder="XXXXXXXXXX" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline`) } className="da-mb-16"
                      { ...getErrorProps(errors['landline_no']) } size="small"
                    >
                      <InputText placeholder="XXXXXXXXXX" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="email" label={ trans(`${langKey}.email`) } className="da-mb-16"
                      { ...getErrorProps(errors['email']) } size="small"
                    >
                      <InputText placeholder="abc@xyz.com" />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item label="Upload photo" name="profile_pic" >
                      <UploadFile accept=".jpg,.png,.jpeg"
                        listType="picture-card"
                        fileList={ profilePicFileList }
                        onChange={ onChangeProfilePic }
                        onPreview={ onPreview }
                        multiple={ false }
                        beforeUpload={ (file) => {
                          return false;
                        } }
                      >
                        { profilePicFileList.length === 1 ? null : 'Upload' }
                      </UploadFile>
                    </Form.Item>
                  </Col> */}
                </Row>
              </div>

              {
                personalInformationEditMode &&
                <div className="employeeInfo-update">
                  <Form.Item style={ { float: 'right' } }>
                    <SaveButton className="employeeInfo-update-save" type="primary" htmlType="submit" loader={ loader } >Update</SaveButton>
                  </Form.Item>
                </div>
              }
            </Form>
          </Skeleton>
          { personalInformationEditMode ? (
            <Button className="employeeInfo-update-cancel" style={ { marginTop: -58 } } onClick={ handleSaveClick }>
              <span style={ { color: "#EA4335" } }>Cancel</span>
            </Button>
          ) : (
            <div>
              { checkPermissions('profile_employeeEmployment') &&
                <Button className="edit-button" style={ {} } type="primary" onClick={ handleEditClickPersonalInformation }
                >
                  <span className="edit-button-text"><EditOutlined /> Edit</span>
                </Button>
              }
            </div>
          ) }
        </div>


        <div style={ { background: "white", border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
          <div className="employeeInfo"><h5 className="employeeInfo-text">CNIC</h5></div>
          { CNICEditMode ? (
            <Form
              { ...formLayout }
              form={ form }
              name={ formName }
              onFinish={ onSubmitFirstStep }
              className="custom-form"
              scrollToFirstError
            >
              <div style={ { marginLeft: 12, marginTop: 20 } }>
                <Row>
                  <Col span={ 12 }>
                    <Form.Item name="cnic_no" rules={ selectedNationality === 166 ? rules.cnic_no : []} label={ trans(`${langKey}.cnic`) } className="da-mb-16 my-custom-form-item"
                      { ...getErrorProps(errors['cnic_no']) } size="small"
                    >
                      <InputText
                        placeholder="Enter Your CNIC Number" onChange={ onChangeCnic } />
                    </Form.Item>

                    <Form.Item name="cnic_expiry_date" rules={ rules.cnic_expiry_date } label={ trans(`${langKey}.cnicEpiry`) } className="da-mb-16"
                      { ...getErrorProps(errors['cnic_expiry_date']) } size="small"
                    >
                      <InputDate
                        format="DD-MMM-YYYY" />
                    </Form.Item>
                  </Col>

                  <Col span={ 12 }>
                    <Form.Item label={ trans(`${langKey}.cnicAttach`) } name="cnic_attachment" >
                      <UploadFile
                        fileList={ cnicFileList }
                        setFileList={ setcnicFileList } />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="employeeInfo-update">
                <Form.Item style={ { float: 'right' } }>
                  <SaveButton className="employeeInfo-update-save" type="primary" htmlType="submit" loading={ loader }>Update</SaveButton>
                </Form.Item>
                <Button className="employeeInfo-update-cancel" onClick={ () => setCNICEditMode(false) }>
                  <span style={ { color: "#EA4335" } }>Cancel</span>
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <Form
                { ...formItemLayout }
                layout="vertical"
                form={ form }
                name={ formName }
                onFinish={ onSubmitFirstStep }
                className="custom-form"
                disabled={ true }
                scrollToFirstError
              >
                <div style={ { marginLeft: 30, marginTop: 20 } }>
                  <Row>
                    <Col span={ 12 }>
                      <Form.Item name="cnic_no" rules={ selectedNationality === 166 ? rules.cnic_no : [] } label={ trans(`${langKey}.cnic`) } className="da-mb-16"
                        { ...getErrorProps(errors['cnic_no']) } size="small"
                      >
                        <InputText
                          bordered={ false }
                          placeholder="Enter Your CNIC Number" onChange={ onChangeCnic } />
                      </Form.Item>

                      <Form.Item name="cnic_expiry_date" rules={ rules.cnic_expiry_date } label={ trans(`${langKey}.cnicEpiry`) } className="da-mb-16"
                        { ...getErrorProps(errors['cnic_expiry_date']) } size="small"
                      >
                        <InputDate
                          bordered={ false }
                          format="DD-MMM-YYYY" />
                      </Form.Item>
                    </Col>

                    <Col span={ 12 }>
                      <Form.Item label={ trans(`${langKey}.cnicAttach`) } name="cnic_attachment" >
                        <UploadFile

                          fileList={ cnicFileList }
                          setFileList={ setcnicFileList } />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Form>
              { checkPermissions('profile_employeeEmployment') &&
                <Button className="edit-button-CNIC" type="primary" onClick={ handleEditClickCNIC }>
                  <span className="edit-button-CNIC-text"><EditOutlined /> Edit</span>
                </Button>
              }
            </div>
          ) }
        </div>


        <div style={ { background: "white", border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
          <div className="employeeInfo"><h5 className="employeeInfo-text">Driving</h5></div>
          { drivingEditMode ? (
            <Form
              { ...formLayout }
              form={ form }
              name={ formName }
              onFinish={ onSubmitFirstStep }
              className="custom-form"
              scrollToFirstError
            >
              <div style={ { marginLeft: 12, marginTop: 20 } }>
                <Row>
                  <Col span={ 12 }>
                    <Form.Item name="driving_license_no" rules={ rules.driving_license_no } label={ trans(`${langKey}.driving`) } className="da-mb-16" size="small">
                      <InputText
                        placeholder="Enter here"
                      />
                    </Form.Item>

                    <Form.Item name="driving_license_expiry_date" rules={ rules.driving_license_expiry_date } label={ trans(`${langKey}.drivingEpiry`) } className="da-mb-16" size="small">
                      <InputDate
                        format="DD-MMM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 12 }>
                    <Form.Item label={ trans(`${langKey}.drivingAttach`) } name="driving_license_attachment" >
                      <UploadFile
                        fileList={ drivingLicenseFileList }
                        setFileList={ setdrivingLicenseFileList } />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div
                style={ {
                  background: 'white',
                  borderTop: "1px solid #C9D6DF",
                  height: 70,
                  borderRadius: "0px 0px 50px 50px",
                } }
              >
                <Form.Item style={ { float: 'right' } }>
                  <SaveButton
                    style={ {
                      width: 257,
                      height: 44,
                      borderRadius: 3,
                      background: "#4079FC",
                      marginTop: 12,
                      marginRight: 12
                    } }
                    type="primary" htmlType="submit" loading={ loader }>
                    Update
                  </SaveButton>
                </Form.Item>
                <Button
                  style={ {
                    width: 257,
                    height: 44,
                    borderRadius: 5,
                    background: "#FFFFFF",
                    border: "1px solid #EA4335",
                    marginTop: 12,
                    float: 'right',
                    marginRight: 12
                  } }
                  onClick={ () => setDrivingEditMode(false) }
                >
                  <span style={ { color: "#EA4335" } }>Cancel</span>
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <Form
                { ...formItemLayout }
                layout="vertical"
                form={ form }
                name={ formName }
                onFinish={ onSubmitFirstStep }
                className="custom-form"
                disabled={ true }
                scrollToFirstError
              >
                <div style={ { marginLeft: 30, marginTop: 20 } }>
                  <Row>
                    <Col span={ 12 }>
                      <Form.Item name="driving_license_no" rules={ rules.driving_license_no } label={ trans(`${langKey}.driving`) } className="da-mb-16" size="small">
                        <InputText
                          bordered={ false }
                          placeholder="Enter here"
                        />
                      </Form.Item>

                      <Form.Item name="driving_license_expiry_date" rules={ rules.driving_license_expiry_date } label={ trans(`${langKey}.drivingEpiry`) } className="da-mb-16" size="small">
                        <InputDate
                          style={ { height: 41, width: "100%" } }
                          bordered={ false }
                          format="DD-MMM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={ 12 }>
                      <Form.Item label={ trans(`${langKey}.drivingAttach`) } name="driving_license_attachment" >
                        <UploadFile

                          fileList={ drivingLicenseFileList }
                          setFileList={ setdrivingLicenseFileList } />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Form>

              { checkPermissions('profile_employeeEmployment') &&
                <Button
                  style={ {
                    width: 72,
                    height: 28,
                    borderRadius: 20,
                    background: "#4079FC",
                    border: "1px solid #4079FC",
                    marginTop: -255,
                    float: 'right',
                    marginRight: 15
                  } }
                  type="primary"
                  onClick={ handleEditClickDriving }
                >
                  <span
                    style={ {
                      color: '#FFFFFF',
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      position: 'relative',
                      right: 3,
                      bottom: 2
                    } }
                  ><EditOutlined /> Edit</span>
                </Button>
              }
            </div>
          ) }
        </div>


        <div style={ { background: "white", border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
          <div className="employeeInfo"><h5 className="employeeInfo-text">Passport</h5></div>
          { passportEditMode ? (
            <Form
              { ...formLayout }
              form={ form }
              name={ formName }
              onFinish={ onSubmitFirstStep }
              className="custom-form"
              scrollToFirstError
            >
              <div style={ { marginLeft: 12, marginTop: 20 } }>
                <Row>
                  <Col span={ 12 }>
                    <Form.Item name="passport_no" rules={ selectedNationality !== 166 ? rules.passport_no : [] } label={ trans(`${langKey}.passport`) } className="da-mb-16"
                      { ...getErrorProps(errors['passport_no']) } size="small"
                    >
                      <InputText placeholder="XXXXXXXXXX" />
                    </Form.Item>

                    <Form.Item name="passport_expiry_date" rules={ rules.passport_expiry_date } label={ trans(`${langKey}.passportEpiry`) } className="da-mb-16"
                      { ...getErrorProps(errors['passport_expiry_date']) } size="small"
                    >
                      <InputDate format="DD-MMM-YYYY" />
                    </Form.Item>
                  </Col>

                  <Col span={ 12 }>
                    <Form.Item label={ trans(`${langKey}.passportAttach`) } name="passport_attachment" >
                      <UploadFile
                        fileList={ passportFileList }
                        setFileList={ setpassportFileList } />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div
                style={ {
                  background: 'white',
                  borderTop: "1px solid #C9D6DF",
                  height: 70,
                  borderRadius: "0px 0px 50px 50px",
                } }
              >
                <Form.Item style={ { float: 'right' } }>
                  <SaveButton
                    style={ {
                      width: 257,
                      height: 44,
                      borderRadius: 3,
                      background: "#4079FC",
                      marginTop: 12,
                      marginRight: 12
                    } }
                    type="primary" htmlType="submit" loading={ loader }>
                    Update
                  </SaveButton>
                </Form.Item>
                <Button
                  style={ {
                    width: 257,
                    height: 44,
                    borderRadius: 5,
                    background: "#FFFFFF",
                    border: "1px solid #EA4335",
                    marginTop: 12,
                    float: 'right',
                    marginRight: 12
                  } }
                  onClick={ () => setPassportEditMode(false) }
                >
                  <span style={ { color: "#EA4335" } }>Cancel</span>
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <Form
                { ...formItemLayout }
                layout="vertical"
                form={ form }
                name={ formName }
                onFinish={ onSubmitFirstStep }
                className="custom-form"
                disabled={ true }
                scrollToFirstError
              >
                <div style={ { marginLeft: 30, marginTop: 20 } }>
                  <Row>
                    <Col span={ 12 }>
                      <Form.Item name="passport_no" rules={ selectedNationality !== 166 ? rules.passport_no : [] } label={ trans(`${langKey}.passport`) } className="da-mb-16"
                        { ...getErrorProps(errors['passport_no']) } size="small"
                      >
                        <InputText bordered={ false } placeholder="XXXXXXXXXX" />
                      </Form.Item>

                      <Form.Item name="passport_expiry_date" rules={ rules.passport_expiry_date } label={ trans(`${langKey}.passportEpiry`) } className="da-mb-16"
                        { ...getErrorProps(errors['passport_expiry_date']) } size="small"
                      >
                        <InputDate bordered={ false } format="DD-MMM-YYYY" />
                      </Form.Item>

                    </Col>

                    <Col span={ 12 }>
                      <Form.Item label={ trans(`${langKey}.passportAttach`) } name="passport_attachment" >
                        <UploadFile
                          fileList={ passportFileList }
                          setFileList={ setpassportFileList } />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Form>

              { checkPermissions('profile_employeeEmployment') &&
                <Button
                  style={ {
                    width: 72,
                    height: 28,
                    borderRadius: 20,
                    background: "#4079FC",
                    border: "1px solid #4079FC",
                    marginTop: -255,
                    float: 'right',
                    marginRight: 15
                  } }
                  type="primary"
                  onClick={ handleEditClickPassport }
                >
                  <span
                    style={ {
                      color: '#FFFFFF',
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      position: 'relative',
                      right: 3,
                      bottom: 2
                    } }
                  ><EditOutlined /> Edit</span>
                </Button>
              }
            </div>
          ) }
        </div>
      </>
    )
  }

  return (
    <>
      { firstStep() }
    </>

  )

}

export default UpdateEmployeeInfo

const rules = {
  salutation_id: [
    { required: true, message: trans(`${langKey}.salutation.required`), },
  ],
  first_name: [
    { required: true, message: trans(`${langKey}.firstName.required`), },
  ],
  last_name: [
    { required: true, message: trans(`${langKey}.lastName.required`), },
  ],
  father_or_husband_name: [
    { required: true, message: trans(`${langKey}.fatherName.required`), },
  ],
  gender_id: [
    { required: true, message: trans(`${langKey}.gender.required`), },
  ],
  d_o_b: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  marital_status_id: [
    { required: false, message: trans(`${langKey}.dob.required`), },
  ],
  nationality_id: [
    { required: true, message: trans(`${langKey}.nationality.required`), },
  ],
  mobile_no: [
    { required: true, message: trans(`${langKey}.mobileNo.required`), },
  ],
  cnic_no: [
    { required: true, message: trans(`${langKey}.cnicNo.required`), }, {
      min: 13
    },
  ],
  passport_no: [
    { required: true, message: trans(`${langKey}.passportNo.required`), },
  ],
  
 


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 23, },
  labelAlign: "left"
}