import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants.js";
import { Space, message, Button, Tooltip, Badge } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq, deactivateMachineReq, getStatusReq } from "./requests.js";
import { Header } from "antd/es/layout/layout";
import CreateMachine from "./components/CreateMachine";
import UpdateMachine from "./components/UpdateMachine";
import { CheckOutlined, SyncOutlined, EnvironmentOutlined, UserAddOutlined } from '@ant-design/icons';
import { StopOutlined } from '@ant-design/icons';
import ViewMachineMap from "./components/ViewMachineMap.jsx";
import dayjs from 'dayjs';
import AddMachineUser from "./components/AddMachineUser.jsx";

const langKey = Const["machines"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexMachines = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  const [supportingData, setSupportingData] = useState({})
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(`${langKey}.code`),
      dataIndex: "code",
    },
    {
      key: 'name',
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: 'ip',
      title: trans(`${langKey}.ip`),
      dataIndex: "ip",
    },
    {
      key: 'udp_port',
      title: trans(`${langKey}.udp_port`),
      dataIndex: "udp_port",
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: 'last_activity_at',
      title: "Status Date",
      render: (record) => (
        record.last_activity_at?dayjs(record.last_activity_at).format("DD/MM/YYYY hh:mm a"):''
      )
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }

        >
          <Tooltip title={ record.status === 'Active' ? 'Deactivate Machine' : 'Activate Machine' } color={ record.status === 'Active' ? 'red' : 'green' }>
            <Button type="link" size="middle" onClick={ () => onChangeStatus(record) }>
              { record.status === 'Active' ?
                <StopOutlined className="icon-style da-text-color-info-1" /> :
                <CheckOutlined className="icon-style da-text-color-info-1" />
              }
            </Button>

          </Tooltip>
          <Space style={ { marginTop: 8 } }>
            <Tooltip title='Get Status'>
              <SyncOutlined className="icon-style da-text-color-warning-1 ms-3" style={ { color: "#1677ff" } } onClick={ () => getOnlineStatus(record) } />
            </Tooltip>
            <Tooltip title='Map User'>
              <EnvironmentOutlined className="icon-style da-text-color-info-1 ms-3" style={ { color: "#1677ff" } } onClick={ () => onViewMap(record) } />
            </Tooltip>
            <Tooltip title='Add Users/Employees To Machine'>
              <UserAddOutlined className="icon-style da-text-color-info-1 ms-3" style={ { color: "#1677ff" } } onClick={ () => onAddUsers(record) } />
            </Tooltip>
          </Space>
        </ActionComponent >
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setSupportingData(response);
    setPagination(response.machines);
    setDataSource(response.machines.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateMachine onCompleted={ onCompleted } record={ record } supportingData={ supportingData } disabled={ false } />
    );
  };
  const onView = (record) => {
    setChildComponent(
      <UpdateMachine onCompleted={ onCompleted } record={ record } supportingData={ supportingData } disabled={ true } />
    );
  };

  const onViewMap = (record) => {
    setChildComponent(
      <ViewMachineMap onCompleted={ onCompleted } record={ record } supportingData={ supportingData } disabled={ false } />
    );
  };
  const onAddUsers = (record) => {
    setChildComponent(
      <AddMachineUser onCompleted={ onCompleted } record={ record } supportingData={ supportingData } disabled={ false } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onChangeStatus = (record) => {
    makeRequest(setLoader, deactivateMachineReq, record.id, onChanged, onError);
  };

  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.machines.data);
    message.success("Record Deleted")
  };

  const getOnlineStatus = (record) => {
    makeRequest(setLoader, getStatusReq, record.id, (res) => onSuccessGetStatus(res, record.id), onError);
  }

  const onSuccessGetStatus = (res, id) => {
    message.info(res.online_status);
  };
  const onChanged = (res) => {
    setDataSource(res.machines.data);
    message.success("Status Updated")
  };

  const onError = (err, res) => {

    message.error(res.response.data.message);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateMachine onCompleted={ onCompleted } supportingData={ supportingData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    if(data){
      getAllData();
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
          <Space>
            <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
            <FilterButton onClick={ onOpenFilter } />
          </Space>

        </Header>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexMachines;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
]

